import React, { Fragment } from 'react'
import FlagIcon from "react-world-flags";
import localeWrapperFlagIcons from '../../../helpers/localeWrapperFlagIcons'
import Helmet from 'react-helmet'
import { Image } from 'react-bootstrap'
import ReactCountryFlag from "react-country-flag";

const Default = ({site_info, locales, getOnSelectFlag, embedded_mode}) => {
  return (
    <div
      className="container-fluid animated fadeIn zindexbooster"
      style={{
        minHeight: '40px',
        backgroundColor: site_info.header_background,
        marginBottom: '10px',
        padding: '5px'
      }}>
      <div className="col-xs-6 cleverq-logo-top">
        <img
          className="header-logo no_tab"
          src="/images/cleverq-headerlogo-210x66.png"
          alt="CleverQ Logo"
          style={{ height: '35px', marginTop: '0px'}}
        />
      </div>
      {site_info.show_locale_selector ? (
        <div className="pull-right" style={{ marginRight: '10px', marginTop: embedded_mode === true ? '50px' : '0px' }}>
          <div className="side-by-side">
            {locales.map(locale => (
              <div key={locale.code} className="side" style={{cursor: 'pointer'}} onClick={() => getOnSelectFlag(locale.code)}>
                <ReactCountryFlag countryCode={localeWrapperFlagIcons(locale.code)} style={{fontSize: '4rem'}}/>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}

const Adac = ({site_info, locales, getOnSelectFlag}) => {
  return (
    <div style={{background: 'white', marginBottom: '35px'}}>
      <div
        className="container-fluid animated fadeIn zindexbooster"
        style={{
          minHeight: '82px',
          backgroundColor: '#FFCC01',
          marginBottom: 0,
          padding: 0
        }}>
        <div className="col-xs-6">
          <img
            className="header-logo no_tab"
            src={site_info.logo}
            alt="ADAC Logo"
            style={{
              height: site_info.logo_height + 'px',
              marginTop: '25px',
              marginLeft: '10.4em'
            }}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: '1px',
          height: '45px',
          backgroundColor: '#FFCC01'
        }}
      >&nbsp;</div>
      {site_info.show_locale_selector ? (
        <div className="pull-right" style={{ marginTop: '5px', marginRight: '10px'}}>
          <div className="side-by-side">
            {locales.map(locale => (
              <div key={locale.code} className="side" style={{cursor: 'pointer'}} onClick={() => getOnSelectFlag(locale.code)}>
                <ReactCountryFlag countryCode={localeWrapperFlagIcons(locale.code)} style={{fontSize: '4rem'}}/>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}

const Adac_header = ({site_info, locales, getOnSelectFlag}) => {
  return (
    <div className="zindexbooster" style={{background: 'white', marginBottom: '35px'}}>
      <div className="header-top-adac"></div>
      <div className="header__adac">
        <div className="container">
          <div className="header__logo-adac">
            <Image src={site_info.logo} className="logo__adac no_tab" alt="ADAC Logo" style={{ height: site_info.logo_height + 'px' }}/>
          </div>
          <div className="header__right-adac">
            <div className="pos-right-adac">
              <div className="header-phone-text-adac header-container-distance-adac"><i className="fas fa-phone fa-flip-horizontal header__links-data-adac"></i>T 089 558 95 96 97</div>
              <div className="header-small-text-adac">(Mo -Sa: 8:00 bis 20:00 Uhr)</div>
            </div>
          </div>
        </div>
      </div>
      {site_info.show_locale_selector ? (
        <div className="pull-right" style={{ marginTop: '5px', marginRight: '10px'}}>
          <div className="side-by-side">
            {locales.map(locale => (
              <div key={locale.code} className="side" style={{cursor: 'pointer'}} onClick={() => getOnSelectFlag(locale.code)}>
                <ReactCountryFlag countryCode={localeWrapperFlagIcons(locale.code)} style={{fontSize: '4rem'}}/>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}

const Lufthansa = ({site_info, locales, getOnSelectFlag}) => {
  return (
    <div style={{marginBottom: '200px'}}>
      <div style={{background: 'white'}}>
        <div
          className="container-fluid animated fadeIn zindexbooster"
          style={{
            minHeight: '90px',
            backgroundColor: '#05164d',
            marginBottom: 0,
            padding: 0,
            maxWidth: '1440px'
          }}>
          <div className="col-xs-6">
            <img
              className="header-logo no_tab"
              src={site_info.logo}
              alt="Lufthansa Logo"
              style={{
                height: site_info.logo_height + 'px',
                marginTop: '25px',
                marginLeft: '10px'
              }}
            />
          </div>
        </div>
        {site_info.show_locale_selector ? (
          <div className="pull-right" style={{ marginTop: '5px', marginRight: '10px'}}>
            <div className="side-by-side">
              {locales.map(locale => (
                <div key={locale.code} className="side" style={{cursor: 'pointer'}} onClick={() => getOnSelectFlag(locale.code)}>
                  <ReactCountryFlag countryCode={localeWrapperFlagIcons(locale.code)} style={{fontSize: '4rem'}}/>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <img
        src="https://cleverq-terminvergabe.s3.eu-central-1.amazonaws.com/images/img.png"
        style={{
          display: 'block',
          maxWidth: '1440px',
          position: 'absolute',
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0
        }}
      />
    </div>
  )
}

const Ikk = ({site_info, locales, getOnSelectFlag}) => {
  return (
    <Fragment>
      <Helmet>
        <style>
          {`
          .align-items-end {
              display: flex;
              align-items: flex-end;
          }
          
          .breadcrumbs {
              border-top: 1px #edf0f1 solid;
              padding: 23px 0 17px;
          }
          
          .breadcrumbs ul li {
              display: inline-block;
              margin-right: 24px;
              color: #a3aab1;
              font-weight: 600;
          }
          
          .icon-position {
            margin-left: -15px;
          }
          
          .breadcrumbs a {
            font-weight: 300;
            color: #a3aab1;
          }
          `}
        </style>
      </Helmet>
      <div className="container-fluid zindexbooster" style={{maxWidth: '89.5em'}}>
        <div className="row">
          <div className="col-lg-2 col-md-3 col-sm-6 col-xs-6 align-items-end">
            <div style={{marginBottom: '27px', marginTop: '55px'}}>
              <a href="#">
                <img
                  className="header-logo no_tab"
                  src={site_info.logo}
                  alt="Ikk Logo"
                  style={{
                    height: site_info.logo_height + 'px'
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="breadcrumbs" style={{marginBottom: '-20px'}}>
        <div className="container-fluid" style={{maxWidth: '91.5em'}}>
          <div className="row">
            <div className="col-xs-12">
              <ul>
                <li>
                  <a href="https://www.ikk-classic.de/">
                    IKK classic
                  </a>
                </li>
                <li>
                  <i className="fas fa-chevron-right icon-position"></i>
                </li>
                <li>
                  <div style={{marginLeft: '-20px'}}>Terminvergabe</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const FinanzamtSH = ({site_info, locales, getOnSelectFlag}) => {
  return (
    <div style={{background: 'white', marginBottom: '35px'}}>
      <div
        className="container animated fadeIn zindexbooster"
        style={{
          minHeight: '82px',
          backgroundColor: 'white',
          marginBottom: 0,
          padding: 0
        }}>
        <img
          src={site_info.logo}
          className="header-logo no_tab"
          alt="Finanzamt SH Logo"
          style={{
            height: site_info.logo_height + 'px',
            marginTop: '25px'
          }}
        />
      </div>
      <div
        style={{
          marginTop: '1px',
          height: '45px',
          backgroundColor: 'white',
          borderBottom: '2px solid #113d69'
        }}
      >&nbsp;</div>
      {site_info.show_locale_selector ? (
        <div className="pull-right" style={{ marginTop: '5px', marginRight: '10px'}}>
          <div className="side-by-side">
            {locales.map(locale => (
              <div key={locale.code} className="side" style={{cursor: 'pointer'}} onClick={() => getOnSelectFlag(locale.code)}>
                <ReactCountryFlag countryCode={localeWrapperFlagIcons(locale.code)} style={{fontSize: '4rem'}}/>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}

const KreisPaderborn = ({site_info, locales, getOnSelectFlag}) => {
  return (
    <div className="zindexbooster" style={{marginBottom: '35px'}}>
      <div
        className="container-fluid">
        <div className="row decoration">
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
        </div>
        <div className="container">
          <img
            src={site_info.logo}
            className="header-logo no_tab"
            alt="Kreis Paderborn Logo"
            style={{
              height: site_info.logo_height + 'px',
              marginTop: '32px'
            }}
          />
        </div>
      </div>
      <Fragment>
        <Helmet>
          <style>{`
            html, body {
              font-family: 'Source Sans Pro',Arial,Helvetica,sans-serif;
            }
          `}</style>
        </Helmet>
        <div className={`container`} style={{marginTop: '50px'}}>
          <div className="row">
            <p className="h3-custom" style={{marginBottom: '-7px', textAlign: 'center', color: 'white', fontSize: '32px'}}>Mein Kreis Paderborn</p>
            <h1 className="h1-custom" style={{marginBottom: '35px', textAlign: 'center', color: 'white', fontSize: '72px',}}>Terminbuchung</h1>
          </div>
        </div>
      </Fragment>
      {site_info.show_locale_selector ? (
        <div className="pull-right" style={{ marginTop: '5px', marginRight: '10px'}}>
          <div className="side-by-side">
            {locales.map(locale => (
              <div key={locale.code} className="side" style={{cursor: 'pointer'}} onClick={() => getOnSelectFlag(locale.code)}>
                <ReactCountryFlag countryCode={localeWrapperFlagIcons(locale.code)} style={{fontSize: '4rem'}}/>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export {
  Default,
  Adac,
  Adac_header,
  Lufthansa,
  Ikk,
  FinanzamtSH,
  KreisPaderborn
}