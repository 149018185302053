import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Helmet from 'react-helmet';
import { Default } from "./elements/Checkout";
import { injectIntl } from "react-intl";

class CheckoutTitle extends Component {
  additionalSubtasks() {
    this.props.setSubtaskEdit()
    this.props.setShowSubtaskSelector(false)
  }
  createMarkup(markup) {
    return { __html: markup };
  }
  render() {
    const { site_info } = this.props
    const { intl } = this.props
    return (
      <div className="container">
        <Helmet>
          <style>{`
            html, body {
              font-family: 'Source Sans Pro',Arial,Helvetica,sans-serif;
            }
            .issues_header_left, .slot_picker_header_left, .data_forms_header_left{
              background-color: ${site_info.heading_background};
              color: ${site_info.heading_text};
              font-size: 1.1em;
              font-weight: 600;
              padding: 8px;
              border: 1px solid #CBCBCBFF;
              border-bottom: none;
              border-radius: 5px 5px 0px 0px;
            }
          `}</style>
        </Helmet>
        <div className="row">
          <div className="col-sm-7">
            {this.props.selected_subtask && this.props.selected_subtask.length > 0 && !this.props.selected_service_data.appointment_only_one_subtasks ?
              <p className="h4-custom" style={{marginBottom: '-7px', textAlign: 'left', color: 'white', fontSize: '45px'}}><span style={{marginLeft: '-15px'}}>{intl.formatMessage({id: "checkout_title.your"})}&nbsp;</span> <span style={{fontWeight: 900}}>{intl.formatMessage({id: "checkout_title.choice"})}</span></p> : null
            }
          </div>
          <div className="col-sm-5">
            { !this.props.no_issues && this.props.ui.subtask_selector_hide && !this.props.selected_service_data.appointment_only_one_subtasks ?
              <div onClick={() => this.additionalSubtasks()}>
                <Default site_info={this.props.site_info} position={'right'} text={intl.formatMessage({id: "issues.additional_issues", icon_type: "continue_button"})} />
              </div> : null
            }
          </div>
          {this.props.no_issues ?
            <div>
              <p className="h4-custom" style={{marginBottom: '-7px', textAlign: 'left', color: 'white', fontSize: '45px'}}>{intl.formatMessage({id: "checkout_title.your"})}&nbsp;<span style={{fontWeight: 900}}>{intl.formatMessage({id: "checkout_title.choice"})}</span></p>
              <div
                className={`container animated fadeIn ${
                  this.props.site_info.drop_shadows ? 'drop-shadow' : null
                }`} style={{marginTop: '20px'}}>
                <div className="row">
                  <div className="col-md-12 issues_header_left">
                    {this.props.site_info.info_num_task_header}
                  </div>
                </div>
                <div
                  className="row inline-shadow"
                  style={{
                    backgroundColor: this.props.site_info.content_background,
                    color: this.props.site_info.content_text
                  }}>
                  <div style={{ marginTop: '15px' }} />
                  <div className="left-spacer" style={{ marginTop: '-25px' }}>
                    <div
                      className={`pull-left ${'subtask-name'}`}
                      style={{ marginLeft: '15px' }}>
                      <p style={{fontSize: '2.5rem', textDecoration: 'underline'}}>{this.props.selected_service_data.name}</p>
                      {this.props.selected_service_data.description_html.length ?
                        <div
                          style={{ fontSize: '1.7rem' }}
                          dangerouslySetInnerHTML={this.createMarkup(
                            this.props.selected_service_data.description_html
                          )}
                        /> : <p style={{ fontSize: '1.7rem' }}>{this.props.selected_service_data.description}</p>
                      }
                    </div>
                  </div>
                </div>
                {this.props.site_info.info_num_task_footer &&
                this.props.site_info.info_num_task_footer.length ? (
                  <div
                    className="row"
                    style={{
                      backgroundColor: this.props.site_info.heading_background,
                      color: this.props.site_info.heading_text,
                      borderBottom: '1px solid #CBCBCBFF',
                      borderLeft: '1px solid #CBCBCBFF',
                      borderRight: '1px solid #CBCBCBFF'
                    }}>
                    <div className="col-md-12">
                      <p style={{ marginLeft: '-5px', marginTop: '8px' }}>
                        {this.props.site_info.info_num_task_footer}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    days: state.days,
    selected_service: state.user_selected_service,
    selected_service_data: state.user_selected_service_data,
    selected_subtask: state.user_selected_subtask,
    selected_day: state.user_selected_day,
    user_person_count: state.user_count_persons,
    no_issues: state.no_issues,
    ui: state.ui
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(CheckoutTitle)
);

