import { combineReducers } from 'redux';
import siteInfoReducer from './site_info';
import serviceListReducer from './service_list';
import subtaskListReducer from './subtask_list';
import sessionReducer from './session';
import daysListReducer from './days';
import slotsListReducer from './slots';
import bookingReducer from './booking';
import userSelectedService from './user_selected_service';
import userSelectedServiceName from './user_selected_service_name';
import userSelectedServiceData from './user_selected_service_data';
import userSelectedServiceGroup from './user_selected_service_group';
import userSelectedServiceGroupData from './user_selected_service_group_data';
import userSelectedSubtaskGroup from './user_selected_subtask_group';
import userSelectedSubtaskGroupData from './user_selected_subtask_group_data';
import userSelectedDay from './user_selected_day';
import userSelectedSlot from './user_selected_slot';
import userSelectedSlotTimeOfSlot from './user_selected_slot_time_of_slot';
import userCountPersons from './user_count_persons';
import userSelectedLanguage from './user_selected_language';
import userSelectedSubtask from './user_selected_subtask';
import noIssues from './no_issues';
import ui from './ui';
import user_data from './user_data';
import appointment_time from './appointment_time';
import waiting_list_entry from "./waiting_list_entry";
const rootReducer = combineReducers({
  site_info: siteInfoReducer,
  service_list: serviceListReducer,
  subtask_list: subtaskListReducer,
  session_info: sessionReducer,
  days: daysListReducer,
  slots: slotsListReducer,
  booking: bookingReducer,
  user_selected_service: userSelectedService,
  user_selected_service_name: userSelectedServiceName,
  user_selected_service_data: userSelectedServiceData,
  user_selected_service_group: userSelectedServiceGroup,
  user_selected_service_group_data: userSelectedServiceGroupData,
  user_selected_subtask_group: userSelectedSubtaskGroup,
  user_selected_subtask_group_data: userSelectedSubtaskGroupData,
  user_selected_day: userSelectedDay,
  user_selected_slot: userSelectedSlot,
  user_selected_slot_time_of_slot: userSelectedSlotTimeOfSlot,
  user_count_persons: userCountPersons,
  user_selected_language: userSelectedLanguage,
  user_selected_subtask: userSelectedSubtask,
  no_issues: noIssues,
  ui: ui,
  user_data: user_data,
  appointment_time: appointment_time,
  waiting_list_entry: waiting_list_entry,
});

export default rootReducer;
