import React from 'react'
import styled from 'styled-components'
import {FormattedMessage} from "react-intl";
const query = new URLSearchParams(window.location.search);
const samsung_mode =
  query.get('samsung_mode') === 'true' ? true : false

const Default = ({ site_info, item, selected_item, icon_type, disabled, service, subtask }) => {
  const AlternateButton = styled.button`
    background: ${site_info.button_background};
    color: ${site_info.button_text};
    border: ${site_info.border_on_items ? '1px solid black !important' : '1px solid #CBCBCBFF'};
    width: 100%;
    ${item.name.length <= 49 ?  'height: 52.52px' : 'min-height: 52.52px' };
    ${samsung_mode ? 'height: auto' : null};
    text-align: left;
    font-size: 1.1em;
    padding: 13px;
    font-weight: 600;
    &:hover {
      background-color: ${site_info.button_hover} !important;
      color: ${site_info.button_text_hover} !important;
      transition: 500ms;
    }
  `;
  let icon
  switch (icon_type) {
    case 'category':
      if (item.custom_button_icon && item.custom_button_icon.length) {
        icon = item.custom_button_icon
      } else {
        icon = site_info.icon_category
      }
      break;
    case 'issues':
      if (item.custom_button_icon && item.custom_button_icon.length) {
        icon = item.custom_button_icon
      } else {
        icon = site_info.icon_issues
      }
      break;
    case 'issues_group':
      if (item.custom_button_icon && item.custom_button_icon.length) {
        icon = item.custom_button_icon
      } else {
        icon = site_info.icon_issues
      }
      break;
    case 'confirm_button':
      icon = site_info.icon_confirm_button
      break;
    case 'continue_button':
      icon = site_info.icon_continue_button
      break;
    default:
      icon = null
  }

  return (
    <AlternateButton
      className={
        site_info.drop_shadows ? 'drop-shadow' : null
      }
      style={{
        backgroundColor:
          item.show_unavailable ? "#D5D5D5" :
            selected_item === item.id
              ? site_info.button_active
              : site_info.button_background,
        color:
          selected_item === item.id
            ? site_info.button_text_active
            : site_info.button_text,
        borderLeft: site_info.use_icons ? site_info.border_on_items ? '1px solid black' : 0 : `10px solid ${site_info.button_text}`,
        border: '1px solid #CBCBCBFF',
        pointerEvents: item.show_unavailable ? 'none' : 'auto'
      }}>
      {site_info.use_icons ? item.custom_button_image ?
        <img
          className=""
          style={{ maxHeight:'30px', marginTop: '-3px' }}
          src={item.custom_button_image}
          alt=""
        />
        : <span className="no_tab" dangerouslySetInnerHTML={createMarkup(
        icon
      )} style={{paddingRight: '10px'}}/> : null} <span style={{paddingLeft:'5px'}} dangerouslySetInnerHTML={createMarkup(item.name)}></span><span>{item.show_unavailable ? <FormattedMessage id="issues.unavailable" /> : ""}</span> {item.visible_only_for_desks || item.internal_usage_only || item.group_visible_only_for_desks ?
      <div className="pull-right"><i className="fas fa-user-lock"></i></div> : item.invite_only ? <div className="pull-right"><i className="fas fa-envelope"></i></div> : null}
    </AlternateButton>
  )
}

function createMarkup(markup) {
  return { __html: markup };
}

export {
  Default
}

