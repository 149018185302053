import { LOCALES } from '../locales'

export default {
  [LOCALES.ENGLISH]: {
    "issues": {
      "browser_header": "Simply book your appointment online.",
      "what_is_cleverq": "What is cleverQ?",
      "additional_issues": "Select additional issues",
      "unavailable": " - Unavailable"
    },
    "app": {
      "header": "What is cleverQ?",
      "important": "Current information"
    },
    "persons": {
      "header": "Please indicate how many persons you wish to book an appointment for.",
      "information": "<b style={{ color: 'red' }}>PLEASE NOTE:</b> You can only book one appointment, even if you need to submit both an application and apply for a re-entry permit. Even though you can only book one appointment, you will be serviced in both categories.",
      "continue": "Continue to the appointment selection",
      "remove": "Remove",
      "to_much_time": "The number of issues and processes exceeds the allowed time limit. Please reduce your selection."
    },
    "datepicker": {
      "header": "Please select the desired day.",
      "mon": "Mon",
      "tue": "Tue",
      "wed": "Wed",
      "thu": "Thu",
      "fri": "Fri",
      "sat": "Sat",
      "sun": "Sun",
      "no_appointments": "Unfortunately no dates available. Please try again later.",
      "error": "There was a problem with the appointment request, please try again later."
    },
    "slotpicker": {
      "header": "Please select the desired date.",
      "no_appointments": "There are no dates available on this day.",
      "legend_green": "Appointments available",
      "legend_yellow": "Only a few appointments available",
      "legend_grey": "No appointments available",
      "timeout_reached": "The connection to the server was interrupted, please try again."
    },
    "dataforms": {
      "mandatory_fields": "Mandatory fields",
      "header": "Enter your contact information.",
      "data_delete": "Your contact information will automatically be deleted from cleverQ after your appointment.",
      "ms": "Ms.",
      "mr": "Mr.",
      "salutation": "Form of address",
      "company": "Company name",
      "given_name": "Given name",
      "first_name": "First name",
      "name": "Name",
      "surname": "Surname",
      "birthday": "Birthday | Format: dd.mm.yyyy",
      "email": "Email",
      "email_confirmation": "Repeat email",
      "email_optional": "Optional email",
      "email_confirmation_optional": "Optional repeat email",
      "summary": "Your booking:",
      "phone": "Phone",
      "tel_information": " ",
      "appointment_for": " ",
      "appointment_around": " at",
      "appointment_for_only": "for ",
      "on": "on",
      "clock": "o'clock",
      "ident_number": "Customer number",
      "ident_number_text": "Please enter your customer number so that you can confirm your appointment on site with your customer card.",
      "message": "Optional message for your appointment.",
      "book_appointment": "Make an appointment now",
      "book_appointment_not_valid": "Please fill out all marked mandatory fields",
      "book_appointment_started": "Please wait until the appointment is booked...",
      "book_appointment_failed": "Appointment booking failed, please click again.",
      "terms_and_conditions": "Privacy policy",
      "terms_and_conditions_text_for_confirm": "By using the online appointment booking service, you agree that the personal data you provide will be processed by us. <br/> <br/> All personal data will only be stored for the purpose of processing the appointment.",
      "terms_and_conditions_link": "Link to privacy policy",
      "email_info": "In order that the appointment confirmation and any other necessary documents can be sent to you by e-mail, <br/> it is necessary to provide your e-mail address. If you do not provide it, the use of the online appointment system is unfortunately not possible.",
      "mobile_phone_number": "Mobile phone number",
      "sms_notifications_label": "SMS-Notifications",
      "sms_notifications_checkbox_text": "I would like to be kept informed about my appointment via SMS notifications.",
      "sms_notifications_text": "We will not call you, nor will your mobile number be used for any other purpose.",
      "back": "Back",
      "cancel": "Cancel",
      "close_privacy_policy": "Close privacy policy",
      "confirm_policy": "Please confirm the privacy policy",
      "policy": "I have read the data protection information and agree to my data being processed.",
      "show_policy": "Show privacy policy"
    },
    "dataforms_waiting_list_entry": {
      "header": "Registration for the waiting list",
      "waiting_list_entry_not_valid": "Please fill in all marked mandatory fields",
      "waiting_list_entry": "Register now without obligation",
      "waiting_list_entry_started": "Waiting lists Registration in progress, please wait. . . ",
      "go_to_waiting_list_entry": "Continue to waiting list registration",
      "successfull_registered": "We have received your registration. For this to be valid, please click on the link in the e-mail we have sent you. ",
      "register_waiting_list_entry": "Request registration"
    },
    "forms": {
      "bday": {
        "day": "Day",
        "month": "Month",
        "year": "Year"
      },
      "card": {
        "bg": "BG",
        "bg_number": "BG number"
      },
      "email_confirm": "repeat",
      "honorific_prefix": {
        "mister": "Mr.",
        "miss": "Mrs.",
        "other": "Other"
      },
      "validations": {
        "default": {
          "required": "This information is required.",
          "min": "Please enter at least two characters.",
          "max": "Too many characters.",
          "letter_only": "Only letters are allowed in this field.",
          "numbers_only": "Only numbers are allowed in this field."
        },
        "number": {
          "min": "Please enter a number greater than 0.",
          "max": "Please enter a number smaller than 100000."
        },
        "email": {
          "required": "Please enter your e-mail address.",
          "not_matching": "The e-mail addresses do not match.",
          "not_a_valid_email": "Please enter a valid e-mail address."
        },
        "options": {
          "required": "Please select an option."
        },
        "name": {
          "required": "Please enter your name."
        },
        "given_name": {
          "required": "Please enter your given name."
        },
        "family_name": {
          "required": "Pleas enter your family name."
        },
        "honorific_prefix": {
          "required": "Please choose a salutation."
        },
        "street_address": {
          "required": "Please enter the street of your place of residence."
        },
        "postal_code": {
          "required": "Please enter your postcode."
        },
        "city": {
          "required": "Please enter your place of residence."
        },
        "country_name": {
          "required": "Please indicate your country of origin."
        },
        "bday": {
          "day": {
            "required": "Please enter the calendar day of your birthday.",
            "type_error": "Please enter a value between 1 and 31.",
            "min": "This is not a valid calendar day.",
            "max": "This is not a valid calendar day."
          },
          "month": {
            "required": "Please enter a valid calendar month of your birthday.",
            "type_error": "Please enter a value between 1 and 12.",
            "min": "This is not a valid calendar month.",
            "max": "This is not a valid calendar month."
          },
          "year": {
            "required": "Please enter the calendar year of your birthday.",
            "type_error": "Please enter a value between 1880 and ${year}.",
            "min": "Please enter a value between 1880 and 2020.",
            "max": "Please enter a value between 1880 and 2020."
          }
        },
        "card": {
          "bg": {
            "required": "Please enter the left side of BG number.",
            "type_error": "Please enter a value with 5 symbols.",
            "min": "This is not a valid left side of BG number.",
            "max": "This is not a valid left side  of BG number."
          },
          "bg_number": {
            "required": "Please enter the right side of BG number.",
            "type_error": "Please enter a value for right side with 7 symbols.",
            "min": "This is not a valid right side of BG number.",
            "max": "This is not a valid right side  of BG number."
          }
        },
        "sex": {
          "required": "Please choose your gender."
        },
        "tel": {
          "required": "Please enter your phone number.",
          "not_valid": "Please enter a valid telephone number."
        },
        "terms_and_conditions": "Please confirm our privacy policy.",
        "ident_number.required": "Please enter your customer number."
      }
    },
    "appointment_info": {
      "at": "in",
      "location": "Location",
      "header": "Your appointment summary",
      "title": "You have booked an appointment.",
      "title_reserved": "You have booked an appointment.",
      "title_on_site_reserved": "You have booked an appointment.",
      "title_desk_reserved": "You have booked an appointment.",
      "infotext": "<h4>Push notification on your mobile phone </h4> <p> Scan the QR code with the CleverQ App on your mobile phone and receive a reminder about your appointment via push notification. </p>",
      "confirmtext": "<h4>Notes on appointment confirmation</h4> <p> You have reserved an appointment with us. In order to make a binding booking, we need a confirmation in the email sent to you. Please click on the link in this email. Hereby you book a binding date with us. Without this procedure the reservation expires. </p>",
      "on_site_confirmtext": "<h4>On-site-confirmation</h4> <p> Please remember that you will need your reservation code when you visit us. You will need to enter this code in our service terminal on site to make the appointment. </p>",
      "desk_confirmtext": "<h4>Date confirmation by an employee</h4> <p> We have forwarded your appointment request to a member of staff.  As soon as the appointment has been confirmed by the employee, you will receive a confirmation e-mail. </p>",
      "reservation_code": "Reservation-code",
      "summary": "Summary",
      "issue": "Category",
      "appointment": "Appointment",
      "persons": "persons.",
      "person": "person.",
      "name": "Name",
      "birthday": "Birthday",
      "email": "Email",
      "phone": "Phone",
      "message": "Message",
      "back_button": "Back to provider",
      "print_button": "Print",
      "advertising": "<h4> cleverQ - Smartphone App </h4> <p> Download our free cleverQ app and get the benefits of your smartphone. <br/> Book appointments, pull tickets, push messages all the way. <br/> So you'll always be up to date. </p>",
      "advertising_image": "/images/smartphone_english.png",
      "duplicate_info_title": "Appointment duplicate",
      "duplicate_info_title_remove": "Please cancel your old appointment",
      "duplicate_info_text": "You have already booked an appointment. Please cancel your old appointment before booking a new one. You can cancel your old appointment in the email that was send to you earlier.",
      "slot_already_booked_title": "Appointment booking not possible",
      "slot_already_booked_text": "Your desired date selection has unfortunately been booked by someone else in the meantime.  We apologize for the inconvenience and would like to ask you to make a different choice of date."
    },
    "navigation": {
      "cleverq": "Because who likes to wait?",
      "legal_notice": "Legal notice",
      "policy": "Privacy policy",
      "terms_of_use": "Terms of Use"
    },
    "greetings": {
      "confirm_greetings": "Continue to appointment booking"
    },
    "checkout_title": {
      "your": "Your",
      "choice": "choice"
    },
    "enter_invite_code": {
      "verify": "Verify",
      "invite_code":"Invitation code"
    },
    "appointment_times": {
      "at": "at ",
      "on": "on ",
      "o_clock": "o'clock",
      "header": "Your reserved appointment time.",
      "remove": "Select new appointment time",
      "session": {
        "text": "Your session will run in: ",
        "close_error_modal": "Close",
        "reservation_expired": "Reservation expired, please select a new appointment time.",
        "expired": "Your session has expired because you were inactive for too long. Please reload the page."
      }
    }
 }
}
