import {
  USER_SELECTED_SERVICE_GROUP_DATA,
  USER_RESET_SERVICE_GROUP
} from '../actions/types';

export default function(state = 'hidden', action) {
  switch (action.type) {
    case USER_SELECTED_SERVICE_GROUP_DATA:
      // Take the existing state and concatenate to the new list of days
      return action.payload;
    case USER_RESET_SERVICE_GROUP:
      return null;
    default:
      return state;
  }
}
