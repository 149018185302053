import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navbar, Nav, NavItem, Button } from 'react-bootstrap';
import * as actions from '../../actions';
import moment from 'moment';
import { animateScroll as scroll } from 'react-scroll';
import Helmet from 'react-helmet';
import { injectIntl } from "react-intl";
const query = new URLSearchParams(window.location.search);
const queryServiceId = query.get('service_id')
const samsung_mode =
  query.get('samsung_mode') === 'true' ? true : false;

let already_clicked = false


class StatusInfo extends Component {
  backToLastSection = () => {
    if (
      this.props.selected_service_data.subtask_groups &&
      this.props.selected_service_data.subtask_groups.length
    ) {
      if (this.props.ui.subtask_group_edit) {
        this.serviceEdit();
      } else if (this.props.ui.subtask_edit) {
        this.subtaskGroupEdit();
      } else if (this.props.ui.day_edit && !this.props.ui.slot_edit) {
        this.subtaskEdit();
      } else if (this.props.ui.day_edit && this.props.ui.slot_edit) {
        this.dayEdit();
      } else if (this.props.ui.data_edit) {
        this.slotEdit();
      }
    } else {
      if (this.props.ui.subtask_edit) {
        this.serviceEdit();
      } else if (this.props.ui.day_edit && !this.props.ui.slot_edit) {
        this.subtaskEdit();
      } else if (this.props.ui.day_edit && this.props.ui.slot_edit) {
        this.dayEdit();
      } else if (this.props.ui.data_edit) {
        this.slotEdit();
      }
    }
  };
  backToCustomer = () => {
    if (this.props.touchscreen_mode) {
      const home_url = sessionStorage.getItem("home_url");
      if (home_url && home_url !== window.location.href) {
        window.location.replace(home_url)
      } else {
        window.location.replace(window.location.href);
      }
    } else {
      window.location.replace(window.location.href);
    }

    if (window.api && window.api.request && !already_clicked) {
      already_clicked = true
      window.api.request('back_to_home')
    }
  };
  closePolicy = () => {
    this.props.setHidePolicy();
  };
  serviceEdit = () => {
    if (this.props.selected_service_data.name && !queryServiceId) {
      this.props.resetSubtasks();
      this.props.resetPersons();
      this.props.resetSubtaskPersons();
      this.props.resetDay();
      this.props.resetSlot();
      this.props.resetTimeOfSlot();
      this.props.setServiceEdit();
    }
  };
  subtaskGroupEdit = () => {
    if (this.props.selected_subtask_group !== 'hidden') {
      this.props.resetSubtaskGroup();
      this.props.resetSubtasks();
      this.props.resetPersons();
      this.props.resetSubtaskPersons();
      this.props.resetDay();
      this.props.resetSlot();
      this.props.resetTimeOfSlot();
      this.props.setSubtaskGroupEdit();
    }
  };
  subtaskEdit = () => {
    if (this.props.selected_subtask && this.props.selected_subtask.length) {
      this.props.resetDay();
      this.props.resetSlot();
      this.props.resetTimeOfSlot();
      this.props.afterPersonsSelected(false);
      this.props.setSubtaskEdit();
      scroll.scrollToBottom();
    }
  };
  dayEdit = () => {
    if (this.props.selected_day !== 'hidden') {
      this.props.resetSlot();
      this.props.resetTimeOfSlot();
      this.props.afterPersonsSelected(true);
      this.props.setDayEdit();
    }
  };
  slotEdit = () => {
    if (
      this.props.user_selected_slot_time_of_slot &&
      this.props.user_selected_slot_time_of_slot.length
    ) {
      this.props.setSlotEdit();
    }
  };
  render() {
    const {intl} = this.props
    const back_and_cancel_buttons =
      (this.props.booking && this.props.booking.id) ||
      (this.props.booking && this.props.booking.error) ? null : (
        <div>
          <Button
            onClick={() => this.backToLastSection()}
            className="back-button">
            {intl.formatMessage({id: "dataforms.back"})}
          </Button>
        </div>
      );
    return (
      <div>
        <Helmet>
          <style>{`
            .custom-badge {
              display: inline-block;
              min-width: 10px;
              padding: 3px 7px;
              ${samsung_mode ? null : 'font-size: 14px;'}
              font-weight: 600;
              line-height: 1;
              text-align: center;
              white-space: nowrap;
              vertical-align: middle;
              border: 1px solid #CBCBCBFF;
              position: relative;
              bottom: 3px;
              background-color: ${this.props.site_info.week_active};
              color: ${this.props.site_info.week_text_active};
            }
            
            .custom-badge-number {
              background-color: ${this.props.site_info.week_background};
              color: ${this.props.site_info.week_text};
              margin-right: 10px;
            }
            
            .back-button {
              position: fixed;
              ${this.props.embedded_mode ? 'top: 0px;' : 'bottom: 0px;'}
              right: ${samsung_mode ? '140px;' : '118px;'}
              display: inline-block;
              padding: 12px;
              height: 50px;
              font-size: 1.1em;
              font-weight: 600;
              line-height: 1;
              text-align: center;
              white-space: nowrap;
              vertical-align: middle;
              border-radius: 0px 2px 2px 0px;
              z-index: 3000;
              background-color: ${this.props.site_info.embedded_back_button_background};
              color: ${this.props.site_info.embedded_back_button_text};
              border: 1px solid #CBCBCBFF;
            }
            
            .back-button:hover{
              background-color: ${this.props.site_info.embedded_back_button_hover_background};
              color: ${this.props.site_info.embedded_back_button_hover_text};
            }
            
            .sticky-close-button {
              position: fixed;
              bottom: 0px;
              right: 35%;
              display: inline-block;
              padding: 12px;
              height: 50px;
              font-size: 1.1em;
              font-weight: 600;
              line-height: 1;
              text-align: center;
              white-space: nowrap;
              vertical-align: middle;
              background-color: #29a745;
              border-radius: 0px 2px 2px 0px;
              background-color: ${
                this.props.site_info.delete_button_background
              };
              color: ${this.props.site_info.delete_button_text};
              border: 1px solid #CBCBCBFF;
              z-index: 3000;
            }
            
            .cancel-button-top {
              position: fixed;
              top: 0px;
              right: 0px;
              display: inline-block;
              padding: 12px;
              height: 50px;
              font-size: 1.1em;
              font-weight: 600;
              line-height: 1;
              color: ${this.props.site_info.embedded_cancel_button_text};
              text-align: center;
              white-space: nowrap;
              vertical-align: middle;
              background-color: ${this.props.site_info.embedded_cancel_background};
              border-radius: 0px 2px 2px 0px;
              z-index: 3000;
            }
            
            .cancel-button-top:hover{
              background-color: ${this.props.site_info.embedded_cancel_button_hover_background};
              color: ${this.props.site_info.embedded_cancel_button_hover_text};
            }
            
          `}</style>
        </Helmet>
        {this.props.embedded_mode ?
            <div
                style={{
                  paddingTop: '50px',
                  opacity: this.props.keyboardState === 'open' ? '0.3' : '1'
                }}>
              { this.props.summary_mode ?
                <Navbar
                  fixedTop
                  fluid
                  style={{ backgroundColor: 'white', height: '50px' }}>
                  <Nav style={{ width: '100%' }}>
                    {this.props.site_info.show_services ? (
                      <Fragment>
                        <NavItem
                          onClick={
                            this.props.keyboardState === 'closed'
                              ? this.serviceEdit
                              : null
                          }>
                    <span className="custom-badge custom-badge-number">
                      {1}
                    </span>
                    <span className="custom-badge">
                      {this.props.selected_service_data.name}
                    </span>
                        </NavItem>
                        <NavItem
                          onClick={
                            this.props.keyboardState === 'closed'
                              ? this.subtaskEdit
                              : null
                          }>
                    <span className="custom-badge custom-badge-number">
                      {2}
                    </span>
                          <span className="custom-badge">
                      {this.props.selected_subtask.length
                        ? this.props.selected_subtask.length > 1
                          ? this.props.selected_subtask[
                              this.props.selected_subtask.length - 1
                            ].name + ' ...'
                          : truncate(this.props.selected_subtask[0].name)
                        : null}
                    </span>
                        </NavItem>
                        <NavItem
                          onClick={
                            this.props.keyboardState === 'closed'
                              ? this.dayEdit
                              : null
                          }>
                    <span className="custom-badge custom-badge-number">
                      {3}
                    </span>
                          <span className="custom-badge">
                      {this.props.selected_day !== 'hidden'
                        ? moment(this.props.selected_day).format('L')
                        : null}
                    </span>
                        </NavItem>
                        <NavItem
                          onClick={
                            this.props.keyboardState === 'closed'
                              ? this.slotEdit
                              : null
                          }>
                    <span className="custom-badge custom-badge-number">
                      {4}
                    </span>
                          <span className="custom-badge">
                      {this.props.user_selected_slot_time_of_slot !== 'hidden'
                        ? this.props.user_selected_slot_time_of_slot
                        : null}
                    </span>
                        </NavItem>
                      </Fragment>
                    ) : (
                      <Fragment>
                        {this.props.selected_service_data.subtask_groups &&
                        this.props.selected_service_data.subtask_groups.length ? (
                          <NavItem
                            onClick={
                              this.props.keyboardState === 'closed'
                                ? this.subtaskGroupEdit
                                : null
                            }>
                      <span className="custom-badge custom-badge-number">
                        {'1'}
                      </span>
                            <span className="custom-badge">
                        {this.props.selected_subtask_group_data
                          ? this.props.selected_subtask_group_data.name
                          : null}
                      </span>
                          </NavItem>
                        ) : null}
                        <NavItem
                          onClick={
                            this.props.keyboardState === 'closed'
                              ? this.subtaskEdit
                              : null
                          }>
                    <span className="custom-badge custom-badge-number">
                      {2}
                    </span>
                          <span className="custom-badge">
                      {this.props.selected_subtask.length
                        ? this.props.selected_subtask.length > 1
                          ? this.props.selected_subtask[
                        this.props.selected_subtask.length - 1
                          ].name + ' ...'
                          : this.props.selected_subtask[0].name
                        : null}
                    </span>
                        </NavItem>
                        <NavItem
                          onClick={
                            this.props.keyboardState === 'closed'
                              ? this.dayEdit
                              : null
                          }>
                    <span className="custom-badge custom-badge-number">
                      {3}
                    </span>
                          <span className="custom-badge">
                      {this.props.selected_day !== 'hidden'
                        ? moment(this.props.selected_day).format('L')
                        : null}
                    </span>
                        </NavItem>
                        <NavItem
                          onClick={
                            this.props.keyboardState === 'closed'
                              ? this.slotEdit
                              : null
                          }>
                    <span className="custom-badge custom-badge-number">
                      {4}
                    </span>
                          <span className="custom-badge">
                      {this.props.user_selected_slot_time_of_slot !== 'hidden'
                        ? this.props.user_selected_slot_time_of_slot
                        : null}
                    </span>
                        </NavItem>
                      </Fragment>
                    )}
                  </Nav>
                </Navbar> : null
              }
            </div> :
            <div
                style={{
                  paddingTop: '50px',
                  opacity: this.props.keyboardState === 'open' ? '0.3' : '1'
                }}>
              <Navbar
                  fixedBottom
                  fluid
                  style={{ backgroundColor: 'white', height: '50px' }}>
                <Nav style={{ width: '100%' }}>
                  {this.props.site_info.show_services ? (
                      <Fragment>
                        <NavItem
                            onClick={
                              this.props.keyboardState === 'closed'
                                  ? this.serviceEdit
                                  : null
                            }>
                    <span className="custom-badge custom-badge-number">
                      {1}
                    </span>
                          <span className="custom-badge">
                      {truncate(this.props.selected_service_data.name)}
                    </span>
                        </NavItem>
                        <NavItem
                            onClick={
                              this.props.keyboardState === 'closed'
                                  ? this.subtaskEdit
                                  : null
                            }>
                    <span className="custom-badge custom-badge-number">
                      {2}
                    </span>
                    <span className="custom-badge">
                      {this.props.selected_subtask.length ?
                        this.props.selected_subtask.length > 0 ?
                          truncate(this.props.selected_subtask[0].name) : null : null}
                    </span>
                        </NavItem>
                        <NavItem
                            onClick={
                              this.props.keyboardState === 'closed'
                                  ? this.dayEdit
                                  : null
                            }>
                    <span className="custom-badge custom-badge-number">
                      {3}
                    </span>
                          <span className="custom-badge">
                      {this.props.selected_day !== 'hidden'
                          ? moment(this.props.selected_day).format('L')
                          : null}
                    </span>
                        </NavItem>
                        <NavItem
                            onClick={
                              this.props.keyboardState === 'closed'
                                  ? this.slotEdit
                                  : null
                            }>
                    <span className="custom-badge custom-badge-number">
                      {4}
                    </span>
                          <span className="custom-badge">
                      {this.props.user_selected_slot_time_of_slot !== 'hidden'
                          ? this.props.user_selected_slot_time_of_slot
                          : null}
                    </span>
                        </NavItem>
                      </Fragment>
                  ) : (
                      <Fragment>
                        {this.props.selected_service_data.subtask_groups &&
                        this.props.selected_service_data.subtask_groups.length ? (
                            <NavItem
                                onClick={
                                  this.props.keyboardState === 'closed'
                                      ? this.subtaskGroupEdit
                                      : null
                                }>
                      <span className="custom-badge custom-badge-number">
                        {'1'}
                      </span>
                              <span className="custom-badge">
                        {this.props.selected_subtask_group_data
                            ? this.props.selected_subtask_group_data.name
                            : null}
                      </span>
                            </NavItem>
                        ) : null}
                        <NavItem
                            onClick={
                              this.props.keyboardState === 'closed'
                                  ? this.subtaskEdit
                                  : null
                            }>
                    <span className="custom-badge custom-badge-number">
                      {2}
                    </span>
                          <span className="custom-badge">
                      {this.props.selected_subtask.length
                          ? this.props.selected_subtask.length > 1
                              ? this.props.selected_subtask[
                          this.props.selected_subtask.length - 1
                              ].name + ' ...'
                              : this.props.selected_subtask[0].name
                          : null}
                    </span>
                        </NavItem>
                        <NavItem
                            onClick={
                              this.props.keyboardState === 'closed'
                                  ? this.dayEdit
                                  : null
                            }>
                    <span className="custom-badge custom-badge-number">
                      {3}
                    </span>
                          <span className="custom-badge">
                      {this.props.selected_day !== 'hidden'
                          ? moment(this.props.selected_day).format('L')
                          : null}
                    </span>
                        </NavItem>
                        <NavItem
                            onClick={
                              this.props.keyboardState === 'closed'
                                  ? this.slotEdit
                                  : null
                            }>
                    <span className="custom-badge custom-badge-number">
                      {4}
                    </span>
                          <span className="custom-badge">
                      {this.props.user_selected_slot_time_of_slot !== 'hidden'
                          ? this.props.user_selected_slot_time_of_slot
                          : null}
                    </span>
                        </NavItem>
                      </Fragment>
                  )}
                </Nav>
              </Navbar>
            </div>
        }
        {!this.props.ui.show_policy ? (
          <div>
            {this.props.site_info.show_services
              ? this.props.ui.service_edit
                ? null
                : back_and_cancel_buttons
              : this.props.selected_service_data.subtask_groups &&
                this.props.selected_service_data.subtask_groups.length
                ? this.props.ui.subtask_group_edit
                  ? null
                  : back_and_cancel_buttons
                : this.props.ui.subtask_edit
                  ? null
                  : back_and_cancel_buttons}
            <Button
              onClick={() => this.backToCustomer()}
              className={this.props.embedded_mode ? "cancel-button-top" : "cancel-button"}>
              {intl.formatMessage({id: "dataforms.cancel"})}
            </Button>
          </div>
        ) : (
          <Button
            onClick={() => this.closePolicy()}
            className="sticky-close-button">
              {intl.formatMessage({id: "dataforms.close_privacy_policy"})}
          </Button>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    booking: state.booking,
    selected_language: state.user_selected_language,
    selected_service: state.user_selected_service,
    selected_service_data: state.user_selected_service_data,
    selected_subtask_group: state.user_selected_subtask_group,
    selected_subtask_group_data: state.user_selected_subtask_group_data,
    selected_subtask: state.user_selected_subtask,
    user_count_persons: state.user_count_persons,
    user_data: state.user_data,
    selected_day: state.user_selected_day,
    user_selected_slot_time_of_slot: state.user_selected_slot_time_of_slot,
    ui: state.ui
  };
}

function truncate(str){
  if (str) {
    return (str.length > (samsung_mode ? 18 : 30)) ? str.substr(0, (samsung_mode ? 17 : 29)) + '...' : str;
  }
};

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(StatusInfo)
);
