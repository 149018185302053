import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setHidePolicy } from '../../actions';
import { Modal } from 'react-bootstrap';

class PrivacyPolicy extends Component {
  state = {
    show: false
  };
  handleClose() {
    this.props.dispatch(setHidePolicy());
  }
  createMarkup(markup) {
    return { __html: markup };
  }
  defaultContent  = () =>
  {return (<div>
        <p>
          Der nachfolgende Text dient Ihrer Information über die von uns
          vorgenommene Verarbeitung Ihrer personenbezogenen Daten im Rahmen
          unseres Internetangebots.
        </p>
        <br />
        <p>
          <strong>WANN VERARBEITEN WIR WELCHE PERSONENBEZOGENEN DATEN</strong>
        </p>
        <p>
          In folgenden Fällen verarbeiten wir von Ihnen personenbezogene
          Daten:
        </p>
        <p>
          a) Besuchen Sie unserer Website werden durch den auf Ihrem Endgerät
          zum Einsatz kommenden Browser automatisch Informationen an den
          Server unserer Website gesendet. Diese Informationen werden temporär
          in einem sog. Logfile gespeichert. Folgende Informationen werden
          dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung
          gespeichert:
        </p>
        <ul>
          <li>IP-Adresse des anfragenden Rechners,</li>
          <li>Datum und Uhrzeit des Zugriffs,</li>
          <li>Name und URL der abgerufenen Datei,</li>
          <li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
          <li>
            verwendeter Browser und ggf. das Betriebssystem Ihres Rechners
            sowie der Name Ihres Access-Providers.
          </li>
        </ul>
        <p>
          Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie
          Analysedienste ein. Mehr Informationen dazu unter dem Punkt
          „Analysewerkzeuge“.
        </p>
        <p>
          b) Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit
          uns über ein auf der Website bereitgestelltes Formular Kontakt
          aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse
          erforderlich, damit wir wissen, von wem die Anfrage stammt und um
          diese beantworten zu können. Weitere Angaben können freiwillig
          getätigt werden.
        </p>
        <p>
          c) Wenn Sie uns eine Bewerbung zukommen lassen wollen, um sich auf
          eine ausgeschriebene Stelle oder initiativ zu bewerben werden wir
          Ihre übersendeten Unterlagen und personenbezogene Daten verarbeiten.
        </p>
        <ul>
          <li>
            Bei Initiativbewerbungen benötigen wir Name, Adresse und andere
            Kontaktdaten, Geburtstag und -ort und Staatsangehörigkeit) und
            Qualifikationspapiere. Darüber hinaus können Sie weitere
            freiwillige Daten uns mitteilen, die zur Begründung eines
            Beschäftigungsverhältnisses ihrer Meinung nach von Vorteil wären.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <strong>ZWECKE DER DATENVERARBEITUNG</strong>
        </p>
        <p>
          Wir verwenden die von Ihnen aktiv mitgeteilten personenbezogenen
          Daten nur für den jeweils vereinbarten Zweck und nur im
          erforderlichen Umfang.
        </p>
        <p>
          a) Die genannten Daten werden durch uns zu folgenden Zwecken
          verarbeitet:
        </p>
        <ul>
          <li>
            Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,
          </li>
          <li>Gewährleistung einer komfortablen Nutzung unserer Website,</li>
          <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
          <li>zu weiteren administrativen Zwecken.</li>
        </ul>
        <p>
          Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1
          lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben
          aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden
          wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person
          zu ziehen.
        </p>
        <p>
          b) Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns
          erfolgt nach Art. 6 Abs. 1 S. 1 lit. b) DSGVO auf Grundlage eine
          vorvertragliche Maßnahme. Wenn es zu einem Vertrag kommt können die
          Daten in unser Kundenbetreuungssystem aufgenommen werden. Die Daten
          werden zu keinen weiteren Zwecken verarbeitet.
        </p>
        <p>
          c) Die Datenverarbeitung dient dem Zwecke zur Begründung und
          Durchführung eines Beschäftigungsverhältnisses gem. Art. 88 DSGVO in
          Verbindung mit § 26 BDSG. Bei einem positivem entscheid, gehen die
          personenbezogene Daten in unsere Personalakte ein und verfolgen die
          Zwecke des Verfahrens „Mitarbeiterverwaltung“.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            KATEGORIEN VON EMPFÄNGERN DER PERSONENBEZOGENEN DATEN
          </strong>
        </p>
        <p>
          Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als
          den im Folgenden aufgeführten Zwecken findet nicht statt.
        </p>
        <p>Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>
        <ul>
          <li>
            Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche
            Einwilligung dazu erteilt haben,
          </li>
          <li>
            die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein
            überwiegendes schutzwürdiges Interesse an der Nichtweitergabe
            Ihrer Daten haben,
          </li>
          <li>
            für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit.
            c DSGVO eine gesetzliche Verpflichtung besteht, sowie
          </li>
          <li>
            dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO
            für die Abwicklung von Vertragsverhältnissen mit Ihnen
            erforderlich ist.
          </li>
        </ul>
        <p>
          Eine Datenübermittelung in Drittstaaten findet nur statt, soweit Sie
          uns Ihre Einwilligung erteilt haben.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            DAUER, FÜR DIE DIE PERSONENBEZOGENEN DATEN GESPEICHERT WERDEN
          </strong>
        </p>
        <p>
          a) Cookies werden in Ihrem Browser als sog. Session-Cookies
          gespeichert, sodass Ihr Browser diese nach Verlassen der Website
          automatisiert wieder löscht. Die Dauer der Speicherung folgt in
          diesem Fall aus der technischen Funktionalität des von Ihnen
          verwendeten Browsers.
        </p>
        <p>
          b) Personenbezogene Daten, die uns im Zusammenhang mit einer
          Kontaktanfrage auf unserer Website übermittelt werden, werden nur
          für die Dauer der Bearbeitung der Anfrage gespeichert. Wenn es zu
          einem Vertrag kommt, können die von Ihnen angegeben Daten in unserem
          Kundenbetreuungssystem regelmäßig für 10 Jahre gespeichert, wenn es
          keine andere rechtliche Verpflichtung gibt die uns zu einer längeren
          Speicherung verpflichtet.
        </p>
        <p>
          c) Die erhobenen personenbezogenen Daten werden bei einer:<br />
          Absage: Für mindestens drei Monate gespeichert. Die längste
          Speicherdauer beträgt wiederum höchstens sechs Monate.<br />
          Einstellung: Gelten unsere Speicherfristen. Die Ihnen zustehenden
          Informationen werden Ihnen bei Einstellung bereitgestellt.
        </p>
        <p>
          Wir weisen darauf hin, dass wir Ihre Daten löschen, wenn ihre
          Speicherung unzulässig ist (insbesondere wenn die Daten unrichtig
          sind und eine Korrektur nicht möglich ist). An die Stelle der
          Löschung tritt die Sperrung, sofern rechtliche oder tatsächliche
          Hindernisse entgegenstehen (beispielsweise besondere
          Aufbewahrungspflichten aufgrund handels- und steuerrechtlicher
          Vorgaben).
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>WIDERSPRUCHSRECHT</strong>
        </p>
        <p>
          Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
          Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden,
          haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die
          Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür
          Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben
          oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren
          Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe
          einer besonderen Situation von uns umgesetzt wird.
        </p>
        <p>
          Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
          machen, genügt eine E-Mail an datenschutz@bic.com.de
        </p>
        <p>
          <strong>
            <br />
            RECHT AUF AUSKUNFT, BERICHTIGUNG, LÖSCHUNG, EINSCHRÄNKUNG{' '}
          </strong>
        </p>
        <p>
          Der betroffenen Person steht das Recht auf Auskunft über die
          betreffenden personenbezogenen Daten sowie auf Berichtigung oder
          Löschung oder auf Einschränkung der Verarbeitung zu. Ferner hat sie
          ein Widerspruchsrecht gegen die Verarbeitung.
        </p>
        <p>
          <strong>
            <br />
            RECHT AUF DATENÜBERTRAGBARKEIT
          </strong>
        </p>
        <p>
          Der betroffenen Person steht das Recht auf Datenübertragbarkeit zu.
        </p>
        <p>
          <strong>
            <br />
            BESTEHEN EINES BESCHWERDERECHTS BEI DER AUFSICHTSBEHÖRDE
          </strong>
        </p>
        <p>
          Sie haben das Recht, sich bei der Aufsichtsbehörde zu beschweren.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>BEREITSTELLUNG DER PERSONENBEZOGENEN DATEN</strong>
        </p>
        <p>
          Es besteht für Sie keine gesetzliche Pflicht zur Bereitstellung von
          personenbezogenen Daten.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            AUTOMATISIERTE ENTSCHEIDUNGSFINDUNG EINSCHLIESSLICH PROFILING
          </strong>
        </p>
        <p>
          Es kann unter Umständen zu einer automatisierten Profilbildung, mit
          dem Ziel einer Bewertung persönlicher Aspekte, kommen. Wir setzen
          diese Profiling-Maßnahmen in folgenden Fällen ein:
        </p>
        <p>
          Aufgrund gesetzlicher und regulatorischer Vorgaben sind wir zur
          Bekämpfung von Geldwäsche, Terrorismusfinanzierung und
          vermögensgefährdenden Straftaten verpflichtet. Dabei werden auch
          Datenauswertungen (u.a. Abgleich mit gesetzlichen vorgegebenen
          Listen) vorgenommen.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>ANALYSEWERKZEUGE</strong>
        </p>
        <p>
          Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um
          kleine Dateien, die Ihr Browser automatisch erstellt und die auf
          Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden,
          wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät
          keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige
          Schadsoftware.
        </p>
        <p>
          In dem Cookie werden Informationen abgelegt, die sich jeweils im
          Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies
          bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von
          Ihrer Identität erhalten.
        </p>
        <p>
          Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres
          Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte
          Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten
          unserer Website bereits besucht haben. Diese werden nach Verlassen
          unserer Seite automatisch gelöscht.
        </p>
        <p>
          Darüber hinaus setzen wir ebenfalls zur Optimierung der
          Benutzerfreundlichkeit temporäre Cookies ein, die für einen
          bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert
          werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in
          Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei
          uns waren und welche Eingaben und Einstellungen sie getätigt haben,
          um diese nicht noch einmal eingeben zu müssen.
        </p>
        <p>
          Zum anderen setzten wir Cookies ein, um die Nutzung unserer Website
          statistisch zu erfassen und zum Zwecke der Optimierung unseres
          Angebotes für Sie auszuwerten (siehe Ziff. 5). Diese Cookies
          ermöglichen es uns, bei einem erneuten Besuch unserer Seite
          automatisch zu erkennen, dass Sie bereits bei uns waren. Diese
          Cookies werden nach einer jeweils definierten Zeit automatisch
          gelöscht.
        </p>
        <p>
          Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke
          zur Wahrung unserer berechtigten Interessen sowie der Dritter nach
          Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.
        </p>
        <p>
          Die meisten Browser akzeptieren Cookies automatisch. Sie können
          Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem
          Computer gespeichert werden oder stets ein Hinweis erscheint, bevor
          ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von
          Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen
          unserer Website nutzen können.
        </p>
        <p>
          <strong>
            <br />
            DATENSICHERHEIT
          </strong>
        </p>
        <p>
          Wir verwenden innerhalb des Website-Besuchs das verbreitete
          SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils
          höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt
          wird. In der Regel handelt es sich dabei um eine 256 Bit
          Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung
          unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie
          zurück. Ob eine einzelne Seite unseres Internetauftrittes
          verschlüsselt übertragen wird, erkennen Sie an der geschlossenen
          Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der
          unteren Statusleiste Ihres Browsers.
        </p>
        <p>
          Wir bedienen uns im Übrigen geeigneter technischer und
          organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen
          zufällige oder vorsätzliche Manipulationen, teilweisen oder
          vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff
          Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend
          der technologischen Entwicklung fortlaufend verbessert.
        </p>
        <p>
          <strong>KARTENDIENSTE (Google Maps)</strong>
        </p>
        <p>
          Auf der Seite &#8222;Kunden &amp; Referenzen&#8220; nutzen wir
          Google Maps, um geographische Informationen visuell ansprechend
          darzustellen. Google Maps ist ein Kartendienst von Google Inc.
          („Google“). Damit das von uns benutzte Google-Kartenmaterial
          eingebunden und in Ihrem Webbrowser angezeigt werden kann, muss Ihr
          Webbrowser beim Aufruf der Kontaktseite eine Verbindung zu einem
          Server von Google, der sich auch in den USA befinden kann,
          aufnehmen. Google erhält hierdurch die Information, dass von der
          IP-Adresse Ihres Gerätes die Kontaktseite unserer Website aufgerufen
          wurde.
        </p>
        <p>
          <strong>YOUTUBE</strong>
        </p>
        <p className="font_8">
          Unsere Webseite nutzt Plugins der von Google betriebenen Seite
          YouTube. Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave.,
          San Bruno, CA 94066, USA. Wenn Sie eine unserer mit einem
          YouTube-Plugin ausgestatteten Seiten besuchen, wird eine Verbindung
          zu den Servern von YouTube hergestellt. Dabei wird dem
          Youtube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
          Wenn Sie in Ihrem YouTube-Account eingeloggt sind ermöglichen Sie
          YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
          zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
          YouTube-Account ausloggen. ​Weitere Informationen zum Umgang von
          Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter
          https://www.google.de/intl/de/policies/privacy
        </p>
        <p>
          Bei der Einbindung von Youtube-Videos arbeiten wir mit dem
          sogenannten „erweiterten Datenschutzmodus“. Dieser ermöglicht die
          Einbindung von Youtube-Videos ohne die Verwendung von Cookie.
          Aufgrund der Masse von eingebetteten Videos kann es jedoch
          vorkommen, dass auch Videos ohne den erweiterten Datenschutzmodus
          eingebunden wurden. In diesem Fall gilt der vorherige Absatz.
        </p>
        <p>
          <strong>
            <br />
            GOOGLE FONTS<br />
          </strong>
        </p>
        <p>
          Wir verwenden auf unserer Website „Google Fonts“. Dies sind
          Schriftarten des Anbieters Google LLC (1600 Amphitheatre Parkway,
          Mountain View, CA 94043, USA – „Google“). Dies tun wir, um unsere
          Internetpräsenz möglichst reibungslos und übersichtlich laden zu
          können.
        </p>
        <p>
          Zwecks Darstellung wird eine Verbindung zwischen Ihrem Browser und
          der Server von Google aufgebaut. Inhalt dieser Kommunikation ist die
          IP-Adresse. Rechtsgrundlage für die oben genannten
          Verarbeitungsvorgänge ist Art. 6 Abs. 1 S. 1 f DSGVO durch die wir
          unser berechtigtes Interesse, an einer einheitlichen Darstellung,
          begründen.
        </p>
        <p>
          Die Möglichkeit des Opt-Outs diesbezüglich finden Sie unter:
          https://adssettings.google.com/authenticated. Sie können uns Ihren
          entsprechenden Widerspruch auch jederzeit zusenden.
        </p>
        <p>
          Nähere Informationen zum Datenschutz von Google erhalten Sie
          unter:https://www.google.com/policies/privacy/
        </p>
        <p>
          <strong>
            <br />
            AKTUALITÄT UND ÄNDERUNG DIESER DATENSCHUTZERKLÄRUNG
          </strong>
        </p>
        <p>
          Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai
          2018.
        </p>
        <p>
          Durch die Weiterentwicklung unserer Website und Angebote darüber
          oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher
          Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu
          ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf
          der Website von Ihnen abgerufen und ausgedruckt werden.
        </p>
        <br />
        <p>
          <strong>
            VERANTWORTLICHER<br />
          </strong>B.I.C. GmbH<br />
          Business Intelligent Cloud<br />
          Am Farmböddel 7a<br />
          24623 Großenaspe
        </p>
        <p>
          Vertreten durch:<br />
          Geschäftsführer Herr Dirk Ostermann
        </p>
        <p>
          Kontakt:<br />
          Tel.: +49 (0)4551 961380<br />
          E-Mail: info@cleverQ.de
        </p>
        <p>
          <strong>
            <br />
            Kontakt DATENSCHUTZBEAUFTRAGTER<br />
          </strong>datenschutz@bic.com.de
        </p>
      </div>)}


  render() {
    return (
      <Modal
        show={this.props.ui.show_policy}
        onHide={this.handleClose.bind(this)}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg">
        <Modal.Body>
          {
            this.props.data_protection && this.props.data_protection.length > 0 ?
              <div dangerouslySetInnerHTML={this.createMarkup(this.props.data_protection)} />
              :
              this.defaultContent()
          }
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
    data_protection: state.site_info.data_protection,
  };
}

export default connect(mapStateToProps)(PrivacyPolicy);
