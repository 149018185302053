import * as Yup from 'yup'
import React from 'react'
import { FormattedMessage } from "react-intl";

// Default error texts
const default_required = <FormattedMessage id={'forms.validations.default.required'} />
const default_min = <FormattedMessage id={'forms.validations.default.min'} />
const default_max = <FormattedMessage id={'forms.validations.default.max'} />

// Custom RegExp`s
const phoneRegExp =
  '^(?:([+][0-9]{1,2})+[ .-]*)?([(]{1}[0-9]{1,6}[)])?([0-9 .-/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$'

const emailRegExp = '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'

const letterOnly =
  '^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð-\\s]+$'

const numbersOnly =
  '^[1234567890]+$'

// Custom mixins
const equalTo = (ref, msg) => {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value && value === this.resolve(ref)
    },
  })
}

const equalToEmptyString = (ref, msg) => {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref)
    },
  })
}

Yup.addMethod(Yup.string, 'equalTo', equalTo)
Yup.addMethod(Yup.string, 'equalToEmptyString', equalToEmptyString)

const validateText = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.string().required(default_required).min(2, default_min).max(50, default_max),
    })
    : Yup.object({
      [id]: Yup.string().min(2, default_min).max(50, default_max),
    })

const validateNumber = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.number().required(default_required).max(1000000000000000000000000000000, default_max),
    })
    : Yup.object({
      [id]: Yup.number().max(1000000000000000000000000000000, default_max),
    })

const validateTextArea = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.string().required(default_required).max(3500, default_max),
    })
    : Yup.object({
      [id]: Yup.string().max(3500, default_max),
    })

const validateOptions = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.string()
        .required(<FormattedMessage id={'forms.validations.options.required'} />)
    })
    : null

const validateCheckBox = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.boolean().oneOf(
        [true],
        'Bitte aktivieren'
      )
    })
    : null

const validateHonorificPrefix = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.string()
        .required(<FormattedMessage id={'forms.validations.honorific_prefix.required'} />)
    })
    : Yup.object({
      [id]: Yup.string()
    })

const validateHonorificPrefixOther = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.string()
        .required(<FormattedMessage id={'forms.validations.honorific_prefix.required'} />)
    })
    : Yup.object({
      [id]: Yup.string()
    })

const validateName = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.string()
        .required(<FormattedMessage id={'forms.validations.name.required'} />)
        .min(2, default_min)
        .max(50, default_max)
        .matches(letterOnly, { message: <FormattedMessage id={'forms.validations.default.letter_only'} />, excludeEmptyString: true })
    })
    : Yup.object({
      [id]: Yup.string().min(2, default_min).max(50, default_max),
    })

const validateGivenName = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.string()
      .required(<FormattedMessage id={'forms.validations.given_name.required'} />)
      .min(2, default_min)
      .max(50, default_max)
      .matches(letterOnly, { message: <FormattedMessage id={'forms.validations.default.letter_only'} />, excludeEmptyString: true })
    })
    : Yup.object({
      [id]: Yup.string().min(2, default_min).max(50, default_max),
    })

const validateFamilyName = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.string()
      .required(<FormattedMessage id={'forms.validations.family_name.required'} />)
      .min(2, default_min)
      .max(50, default_max)
      .matches(letterOnly, { message: <FormattedMessage id={'forms.validations.default.letter_only'} />, excludeEmptyString: true })
    })
    : Yup.object({
      [id]: Yup.string().min(2, default_min).max(50, default_max),
    })

const validateEmailRequired = Yup.object({
  email: Yup.string()
    .required(<FormattedMessage id={'forms.validations.email.required'} />)
    .email(<FormattedMessage id={'forms.validations.email.not_a_valid_email'} />)
    .matches(emailRegExp, { message: <FormattedMessage id={'forms.validations.email.not_a_valid_email'} /> })
    .max(50, default_max),

  email_confirm: Yup.string()
    .required(<FormattedMessage id={'forms.validations.email.required'} />)
    .email(<FormattedMessage id={'forms.validations.email.not_a_valid_email'} />)
    .matches(emailRegExp, { message: <FormattedMessage id={'forms.validations.email.not_a_valid_email'} /> })
    .max(50, default_max)
    .equalTo(Yup.ref('email'), <FormattedMessage id={'forms.validations.email.not_matching'} />),
})

const validateEmail = Yup.object({
  email: Yup.string()
    .email(<FormattedMessage id={'forms.validations.email.not_a_valid_email'} />)
    .matches(emailRegExp, { message: <FormattedMessage id={'forms.validations.email.not_a_valid_email'} />, excludeEmptyString: true })
    .max(50, default_max),

  email_confirm: Yup.string()
    .email(<FormattedMessage id={'forms.validations.email.not_a_valid_email'} />)
    .matches(emailRegExp, { message: <FormattedMessage id={'forms.validations.email.not_a_valid_email'} />, excludeEmptyString: true })
    .max(50, default_max)
    .equalToEmptyString(Yup.ref('email'), <FormattedMessage id={'forms.validations.email.not_matching'} />),
})

const validateTel = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.string()
      .required(<FormattedMessage id={'forms.validations.tel.required'} />)
      .max(50, default_max),
    })
    : Yup.object({
      [id]: Yup.string()
      .max(50, default_max),
    })

const validateBirthday = (id, required) =>
  required
    ? Yup.object({
      ['bday_' + id]: Yup.object({
        day: Yup.string()
          .test(
              null,
              <FormattedMessage id={'forms.validations.bday.day.type_error'} />,
              (value) => parseInt(value) <= 31 && parseInt(value) > 0
          )
          .required(<FormattedMessage id={'forms.validations.bday.day.required'} />)
          .min(1, <FormattedMessage id={'forms.validations.bday.day.min'} />)
          .max(2, <FormattedMessage id={'forms.validations.bday.day.max'} />)
          .matches(numbersOnly, { message: <FormattedMessage id={'forms.validations.default.numbers_only'} />, excludeEmptyString: true }),

        month: Yup.string()
          .test(
              null,
              <FormattedMessage id={'forms.validations.bday.month.type_error'} />,
              (value) => parseInt(value) <= 12 && parseInt(value) > 0
          )
          .required(<FormattedMessage id={'forms.validations.bday.month.required'} />)
          .min(1, <FormattedMessage id={'forms.validations.bday.month.min'} />)
          .max(2, <FormattedMessage id={'forms.validations.bday.month.max'} />)
          .matches(numbersOnly, { message: <FormattedMessage id={'forms.validations.default.numbers_only'} />, excludeEmptyString: true }),

        year: Yup.string()
          .test(
            null,
            <FormattedMessage id={'forms.validations.bday.year.type_error'} data={{ year: new Date().getFullYear() }}/>,
            (value) => parseInt(value) <= new Date().getFullYear() && parseInt(value) >= 1880
          )
          .required(<FormattedMessage id={'forms.validations.bday.year.required'} />)
          .min(4, <FormattedMessage id={'forms.validations.bday.year.min'} />)
          .max(4, <FormattedMessage id={'forms.validations.bday.year.max'} />)
          .matches(numbersOnly, { message: <FormattedMessage id={'forms.validations.default.numbers_only'} />, excludeEmptyString: true })
      }),
    })
    : Yup.object({
      ['bday_' + id]: Yup.object({
        day: Yup.string()
          .test(
            null,
            <FormattedMessage id={'forms.validations.bday.day.type_error'} />,
            (value) =>  !value || parseInt(value) <= 31 && parseInt(value) > 0
          )
          .matches(numbersOnly, { message: <FormattedMessage id={'forms.validations.default.numbers_only'} />, excludeEmptyString: true })
          .min(1, <FormattedMessage id={'forms.validations.bday.day.min'} />)
          .max(2, <FormattedMessage id={'forms.validations.bday.day.max'} />),

        month: Yup.string()
          .test(
            null,
            <FormattedMessage id={'forms.validations.bday.month.type_error'} />,
            (value) => !value || parseInt(value) <= 12 && parseInt(value) > 0
          )
          .matches(numbersOnly, { message: <FormattedMessage id={'forms.validations.default.numbers_only'} />, excludeEmptyString: true })
          .min(1, <FormattedMessage id={'forms.validations.bday.month.min'} />)
          .max(2, <FormattedMessage id={'forms.validations.bday.month.max'} />),

        year: Yup.string()
          .test(
            null,
            <FormattedMessage id={'forms.validations.bday.year.type_error'} data={{ year: new Date().getFullYear() }}/>,
            (value) => !value || parseInt(value) <= new Date().getFullYear() && parseInt(value) >= 1880
          )
          .matches(numbersOnly, { message: <FormattedMessage id={'forms.validations.default.numbers_only'} />, excludeEmptyString: true })
          .min(4, <FormattedMessage id={'forms.validations.bday.year.min'} />)
          .max(4, <FormattedMessage id={'forms.validations.bday.year.max'} />)
      }),
    })

const validateStreetAddress = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.string()
        .required(<FormattedMessage id={'forms.validations.street_address.required'} />)
        .min(2, default_min)
        .max(50, default_max),
    })
    : Yup.object({
      [id]: Yup.string().min(2, default_min).max(50, default_max),
    })

const validatePostalCode = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.string()
        .required(<FormattedMessage id={'forms.validations.postal_code.required'} />)
        .min(2, default_min)
        .max(50, default_max),
    })
    : Yup.object({
      [id]: Yup.string().min(2, default_min).max(50, default_max),
    })

const validateCity = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.string()
        .required(<FormattedMessage id={'forms.validations.city.required'} />)
        .min(2, default_min)
        .max(50, default_max),
    })
    : Yup.object({
      [id]: Yup.string().min(2, default_min).max(50, default_max),
    })

const validateCountryName = (id, required) =>
  required
    ? Yup.object({
      [id]: Yup.string()
        .required(<FormattedMessage id={'forms.validations.country_name.required'} />)
        .min(2, default_min)
        .max(50, default_max),
    })
    : Yup.object({
      [id]: Yup.string().min(2, default_min).max(50, default_max),
    })

const validateTermsAndConditions = Yup.object({
  TermsAndConditions: Yup.boolean().oneOf(
    [true],
    <FormattedMessage id={'forms.validations.terms_and_conditions'} />
  ),
})

const validateCustomerCardNumber = (id, required) =>
  required
    ? Yup.object({
      ['customer_card_number_' + id]: Yup.object({
        bg: Yup.string()
          .test(
            null,
            <FormattedMessage id={'forms.validations.customer_card_number.bg.type_error'} />,
            (value) => !value || parseInt(value) > 0
          )
          .required(<FormattedMessage id={'forms.validations.card.bg.required'} />)
          .min(5, <FormattedMessage id={'forms.validations.card.bg.min'} />)
          .max(5, <FormattedMessage id={'forms.validations.card.bg.max'} />)
          .matches(numbersOnly, { message: <FormattedMessage id={'forms.validations.default.numbers_only'} />, excludeEmptyString: true }),

        bg_number: Yup.string()
          .test(
            null,
            <FormattedMessage id={'forms.validations.card.bg.type_error'} />,
            (value) => parseInt(value) > 0
          )
          .required(<FormattedMessage id={'forms.validations.card.bg_number.required'} />)
          .min(7, <FormattedMessage id={'forms.validations.card.bg_number.min'} />)
          .max(7, <FormattedMessage id={'forms.validations.card.bg_number.max'} />)
          .matches(numbersOnly, { message: <FormattedMessage id={'forms.validations.default.numbers_only'} />, excludeEmptyString: true }),
      }),
    })
    : Yup.object({
      ['customer_card_number_' + id]: Yup.object({
        bg: Yup.string()
          .test(
            null,
            <FormattedMessage id={'forms.validations.card.bg.type_error'} />,
            (value) =>  !value || parseInt(value) > 0
          )
          .matches(numbersOnly, { message: <FormattedMessage id={'forms.validations.default.numbers_only'} />, excludeEmptyString: true })
          .min(5, <FormattedMessage id={'forms.validations.card.bg.min'} />)
          .max(5, <FormattedMessage id={'forms.validations.card.bg.max'} />),

        bg_number: Yup.string()
          .test(
            null,
            <FormattedMessage id={'forms.validations.card.bg_number.type_error'} />,
            (value) => !value || parseInt(value) > 0
          )
          .matches(numbersOnly, { message: <FormattedMessage id={'forms.validations.default.numbers_only'} />, excludeEmptyString: true })
          .min(7, <FormattedMessage id={'forms.validations.card.bg_number.min'} />)
          .max(7, <FormattedMessage id={'forms.validations.card.bg_number.max'} />)
      }),
    })

export {
  validateText,
  validateNumber,
  validateTextArea,
  validateOptions,
  validateCheckBox,
  validateHonorificPrefix,
  validateHonorificPrefixOther,
  validateName,
  validateGivenName,
  validateFamilyName,
  validateEmailRequired,
  validateEmail,
  validateTel,
  validateBirthday,
  validateStreetAddress,
  validatePostalCode,
  validateCity,
  validateCountryName,
  validateTermsAndConditions,
  validateCustomerCardNumber
}

