import {
  USER_SELECTED_SLOT_TIME_OF_SLOT,
  RESET_USER_SELECTED_SLOT_TIME_OF_SLOT
} from '../actions/types';

export default function(state = [], action) {
  switch (action.type) {
    case USER_SELECTED_SLOT_TIME_OF_SLOT:
      // Take the existing state and concatenate to the new list of days
      return action.payload;
    case RESET_USER_SELECTED_SLOT_TIME_OF_SLOT:
      return [];
    default:
      return state;
  }
}
