 import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import * as actions from '../../actions';
 import { injectIntl } from "react-intl";

const query = new URLSearchParams(window.location.search);
const subtask_id = query.get('subtask_id') ? parseInt(query.get('subtask_id')) : null
const subtask_items = query.get('subtask_items[]') ? JSON.parse(JSON.parse(query.get('subtask_items[]'))[0]) : null

class Persons extends Component {
  state = {
    count: 0
  };
  createMarkup(markup) {
    return { __html: markup };
  }
  componentDidMount () {
    if (this.props.user_person_count && this.props.user_person_count.length) {
      if (!this.props.user_person_count.filter(item => item.subtask_id === this.props.subtask.id).length - 1) {
        this.setState({ count: this.props.user_person_count.filter(item => item.subtask_id === this.props.subtask.id)[0].number })
      }
    }
    this.increment(1)
  }

  handleClick() {
    this.props.resetAppointmentTimes();
    this.props.removedSubtask(this.props.subtask);
    this.props.resetPersons(this.props.subtask.id);
    this.props.selectedDay('hidden');
    this.props.selectedSlot('hidden');
    this.props.selectedSlotTimeOfSlot('hidden');
    this.props.setSubtaskEdit();
  }
  decrement() {
    if (this.state.count > 0) {
      this.props.resetAppointmentTimes();
      this.setState({ count: this.state.count - 1 });
    }

    this.props.personIncrementDecrementChange();
    this.props.selectedDay('hidden');
    this.props.selectedSlot('hidden');
    this.props.selectedSlotTimeOfSlot('hidden');
    this.props.updatePersons(
      this.props.subtask.id,
      this.state.count - 1,
      this.props.subtask.name,
      false,
      this.props.subtask.initial_duration * this.props.site_info.time_slot_minutes,
      this.props.subtask.next_duration * this.props.site_info.time_slot_minutes
    );
  }
  increment(initial) {
    if (this.state.count < this.props.subtask.max_tasks) {
      this.props.resetAppointmentTimes();
      this.setState({ count: this.state.count + 1 });
      this.props.updatePersons(
        this.props.subtask.id,
        this.state.count + 1,
        this.props.subtask.name,
        false,
        this.props.subtask.initial_duration * this.props.site_info.time_slot_minutes,
        this.props.subtask.next_duration * this.props.site_info.time_slot_minutes
      );
    }
    if (initial !== 1) {
      this.props.personIncrementDecrementChange();
      this.props.selectedDay('hidden');
      this.props.selectedSlot('hidden');
      this.props.selectedSlotTimeOfSlot('hidden');
    }
  }
  render() {
    const {intl} = this.props
    return (
       this.props.subtask.service_ids.includes(this.props.selected_service_data.id) ?
        <div
          className={`container animated fadeIn ${
            this.props.site_info.drop_shadows ? 'drop-shadow' : null
          }`}>
          <div className="row">
            <div className="col-md-12 issues_header_left">
              <span>{this.props.site_info.info_num_task_header}</span>
            </div>
          </div>
          <div
            className="row inline-shadow"
            style={{
              backgroundColor: this.props.site_info.content_background,
              color: this.props.site_info.content_text
            }}>
            <div style={{ marginTop: '15px' }} />
            <div className="left-spacer" style={{ marginTop: '-15px' }}>
              <div className="col-lg-4 mobile-paddings-persons">
                <div
                  className={`btn ${
                    this.props.site_info.drop_shadows ? 'drop-shadow' : null
                  }`}
                  style={{
                    height: '36px',
                    lineHeight: '36px',
                    padding: '0',
                    marginTop: '-20px',
                    width: '40px',
                    border: '1px solid #CBCBCBFF',
                    background: this.props.site_info.button_background,
                    color: this.props.site_info.button_text,
                    fontSize: '1.5rem',
                    fontWeight: '600'
                  }}
                  onClick={this.decrement.bind(this)}>
                  <i className="fas fa-minus no_tab" />
                </div>
                <Fragment>
                <span
                  style={{
                    fontSize: '3.5rem',
                    marginLeft: '20px',
                    marginRight: '20px'
                  }}>
                  {this.state.count}
                </span>
                </Fragment>
                <div
                  className={`btn ${
                    this.props.site_info.drop_shadows ? 'drop-shadow' : null
                  }`}
                  style={{
                    height: '36px',
                    lineHeight: '36px',
                    padding: '0',
                    marginTop: '-20px',
                    width: '40px',
                    border: '1px solid #CBCBCBFF',
                    background: this.props.site_info.button_background,
                    color: this.props.site_info.button_text,
                    fontSize: '1.5rem',
                    fontWeight: '600'
                  }}
                  onClick={this.increment.bind(this)}>
                  <i className="fas fa-plus no_tab" />
                </div>
                <button
                  className={`btn ${
                    this.props.site_info.drop_shadows ? 'drop-shadow' : null
                  }`}
                  onClick={this.handleClick.bind(this)}
                  style={{
                    height: '36px',
                    marginLeft: '20px',
                    marginTop: '-20px',
                    background: this.props.site_info.delete_button_background,
                    color: this.props.site_info.delete_button_text,
                    border: '1px solid #CBCBCBFF'
                  }}>
                  <span>{intl.formatMessage({id: "persons.remove"})}</span>
                </button>
              </div>
              <div
                className={`pull-left ${'subtask-name'}`}
                style={{ marginRight: '20px' }}>
                <p className="mobile-paddings-subtask-name" dangerouslySetInnerHTML={this.createMarkup(this.props.subtask.name)}></p>
              </div>
            </div>
          </div>
          {this.props.site_info.info_num_task_footer &&
          this.props.site_info.info_num_task_footer.length ? (
            <div
              className="row"
              style={{
                backgroundColor: this.props.site_info.heading_background,
                color: this.props.site_info.heading_text,
                borderBottom: '1px solid #CBCBCBFF',
                borderLeft: '1px solid #CBCBCBFF',
                borderRight: '1px solid #CBCBCBFF'
              }}>
              <div className="col-md-12">
                <p style={{ marginLeft: '-5px', marginTop: '8px' }}>
                  {this.props.site_info.info_num_task_footer}
                </p>
              </div>
            </div>
          ) : null}
        </div> :

         <div
           className={`container animated fadeIn ${
             this.props.site_info.drop_shadows ? 'drop-shadow' : null
           }`}>
           <div className="row">
             <div className="col-md-12 issues_header_left" style={{color: 'red'}}>
               <span>Fehler</span>
             </div>
           </div>
           <div
             className="row inline-shadow"
             style={{
               backgroundColor: this.props.site_info.content_background,
               color: this.props.site_info.content_text
             }}>
             <div style={{ marginTop: '15px' }} />
             <div className="left-spacer" style={{ marginTop: '-15px' }}>
               <div
                 className={`pull-left ${'subtask-name'}`}
                 style={{ marginRight: '20px', color: 'red' }}>
                 <p>Dieses Anliegen gehört nicht zu dem gewählten Dienst!</p>
               </div>
             </div>
           </div>
         </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    days: state.days,
    selected_service: state.user_selected_service,
    selected_service_data: state.user_selected_service_data,
    selected_subtask: state.user_selected_subtask,
    selected_day: state.user_selected_day,
    user_person_count: state.user_count_persons
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(Persons)
);
