import { LOCALES } from '../locales'

export default {
  [LOCALES.GERMAN]: {
      "issues": {
        "browser_header": "Buchen Sie ganz einfach Ihren Termin online.",
        "what_is_cleverq": "Was ist cleverQ?",
        "additional_issues": "Zusätzliche Anliegen auswählen",
        "unavailable": " - Nicht verfügbar"
      },
      "app": {
        "header": "Was ist cleverQ?",
        "important": "Aktuelle Informationen"
      },
      "persons": {
        "header": "Bitte geben Sie an, für wieviele Personen Sie einen Termin buchen wollen.",
        "information": "<b style={{ color: 'red' }}>BITTE BEACHTEN SIE:</b> Sie können nur einen Termin buchen. Auch wenn Sie sowohl eine Bewerbung einreichen als auch eine Wiedereinreisegenehmigung beantragen müssen. Obwohl Sie nur einen Termin buchen können, werden Sie in beiden Anliegen betreut.",
        "continue": "Weiter zur Terminauswahl",
        "remove": "Entfernen",
        "to_much_time": "Die Anzahl der Anliegen und Vorgänge übersteigt die erlaubte Termindauer. Bitte reduzieren Sie Ihre Auswahl."
      },
      "datepicker": {
        "header": "Bitte wählen Sie Ihren gewünschten Tag.",
        "mon": "Mo",
        "tue": "Di",
        "wed": "Mi",
        "thu": "Do",
        "fri": "Fr",
        "sat": "Sa",
        "sun": "So",
        "no_appointments": "Für Ihre Auswahl sind leider aktuell alle Termine ausgebucht. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
        "error": "Es gab ein Problem bei der Terminabfrage, bitte versuchen Sie es später noch einmal."
      },
      "slotpicker": {
        "header": "Bitte wählen Sie die gewünschte Uhrzeit.",
        "no_appointments": "An diesem Tag sind leider keine Termine verfügbar.",
        "legend_green": "Termine vorhanden",
        "legend_yellow": "Nur noch wenige Termine vorhanden",
        "legend_grey": "Keine Termine vorhanden",
        "timeout_reached": "Die Verbindung zum Server wurde unterbrochen, bitte versuchen Sie es erneut."
      },
      "dataforms": {
        "mandatory_fields": "Pflichtfelder",
        "header": "Bitte geben Sie nun Ihre persönlichen Daten ein.",
        "data_delete": "Ihre Daten werden nach Ihrem Termin automatisch von cleverQ gelöscht.",
        "ms": "Frau",
        "mr": "Herr",
        "salutation": "Anrede",
        "company": "Firmennamen",
        "given_name": "Vorname",
        "surname": "Nachname",
        "birthday": "Geburtstag | Format: dd.mm.jjjj",
        "email": "E-Mail-Adresse",
        "email_confirmation": "E-Mail-Adresse wiederholen",
        "email_optional": "Optionale E-Mail-Adresse",
        "email_confirmation_optional": "Optionale E-Mail-Adresse wiederholen",
        "summary": "Zusammenfassung:",
        "phone": "Optionale Telefon-Nummer",
        "tel_information": " ",
        "appointment_for": " ",
        "appointment_around": " um",
        "appointment_for_only": "für ",
        "on": "am",
        "clock": "Uhr",
        "ident_number": "Kundennummer",
        "ident_number_text": "Bitte geben Sie Ihre Kundennummer ein, damit Sie Ihren Termin, mit Ihrer Kundenkarte vor Ort bestätigen können.",
        "message": "Optionale Nachricht zu Ihrem Termin.",
        "book_appointment": "Termin jetzt verbindlich reservieren",
        "book_appointment_not_valid": "Bitte füllen Sie alle gekennzeichneten Pflichtfelder aus",
        "book_appointment_started": "Termin wird gebucht, bitte warten...",
        "book_appointment_failed": "Terminbuchung fehlgeschlagen, bitte klicken Sie erneut.",
        "terms_and_conditions": "Datenschutzbestimmungen",
        "terms_and_conditions_text_for_confirm": "Durch die Nutzung der Online-Terminbuchung erklären Sie sich damit einverstanden, dass die von Ihnen angegebenen personenbezogenen Daten durch uns verarbeitet werden. <br/> <br/> Sämtliche personenbezogenen Daten werden nur zum Zwecke der Terminabwicklung gespeichert.",
        "terms_and_conditions_link": "Link zur Datenschutzerklärung",
        "email_info": "Damit die Terminbestätigung und eventuelle weitere erforderliche Unterlagen Ihnen per E-Mail zugestellt werden können, <br/> ist die Angabe Ihrer E-Mail Adresse erforderlich. Wenn Sie diese nicht angeben, ist die Nutzung der Online-Terminvereinbarung leider nicht möglich.",
        "mobile_phone_number": "Mobilfunknummer",
        "sms_notifications_label": "SMS-Benachrichtigungen",
        "sms_notifications_checkbox_text": "Ich möchte per SMS-Benachrichtigungen über meinen Termin, auf dem laufenden gehalten werden.",
        "sms_notifications_text": "Wir werden Sie, weder anrufen, noch wird Ihre Mobilfunknummer anderweitig verwendet.",
        "back": "Zurück",
        "cancel": "Abbrechen",
        "close_privacy_policy": "Datenschutzbestimmung schließen",
        "confirm_policy": "Bitte bestätigen Sie die Datenschutzbestimmungen.",
        "policy": "Ich habe die Datenschutzhinweise gelesen und bin mit der Verarbeitung meiner Daten einverstanden.",
        "show_policy": "Datenschutzbestimmungen anzeigen"
      },
      "dataforms_waiting_list_entry": {
        "header": "Registrierung für die Warteliste",
        "waiting_list_entry_not_valid": "Bitte füllen Sie alle gekennzeichneten Pflichtfelder aus",
        "waiting_list_entry": "Jetzt unverbindlich registrieren",
        "waiting_list_entry_started": "Wartelisten Registrierung wird durchgeführt, bitte warten...",
        "go_to_waiting_list_entry": "Weiter zur Wartelistenanmeldung",
        "successfull_registered": "Wir haben Ihre Registrierung erhalten. Damit diese gültig wird, klicken Sie bitte auf den Link in der E-Mail, die wir Ihnen gesendet haben.",
        "register_waiting_list_entry": "Registrierung anfordern"
      },
      "forms": {
        "bday": {
          "day": "Tag",
          "month": "Monat",
          "year": "Jahr"
        },
        "card": {
          "bg": "BG",
          "bg_number": "BG Nummer"
        },
        "email_confirm": "wiederholen",
        "honorific_prefix": {
          "mister": "Herr",
          "miss": "Frau",
          "other": "Divers"
        },
        "validations": {
          "default": {
            "required": "Diese Angabe wird benötigt.",
            "min": "Bitte geben Sie mindestens zwei Zeichen ein.",
            "max": "Zu viele Zeichen.",
            "letter_only": "In diesem Feld sind nur Buchstaben erlaubt.",
            "numbers_only": "In diesem Feld sind nur Zahlen erlaubt."
          },
          "number": {
            "min": "Bitte geben Sie bitte eine Zahl größer 0 ein.",
            "max": "Bitte geben Sie bitte eine Zahl kleiner als 1000000 ein."
          },
          "email": {
            "required": "Bitte geben Sie Ihre Email-Addresse ein.",
            "not_matching": "Die Email-Adressen stimmen nicht überein.",
            "not_a_valid_email": "Bitte geben Sie eine gültige Email-Adresse ein."
          },
          "options": {
            "required": "Bitte wählen Sie eine Option."
          },
          "name": {
            "required": "Bitte geben Sie ihren Namen an."
          },
          "given_name": {
            "required": "Bitte geben Sie Ihren Vornamen an."
          },
          "family_name": {
            "required": "Bitte geben Sie Ihren Nachnamen an."
          },
          "honorific_prefix": {
            "required": "Bitte geben Sie eine Anrede an."
          },
          "street_address": {
            "required": "Bitte geben Sie die Straße Ihres Wohnortes an."
          },
          "postal_code": {
            "required": "Bitte geben Sie Ihre Postleitzahl an."
          },
          "city": {
            "required": "Bitte geben Sie Ihren Wohnort an."
          },
          "country_name": {
            "required": "Bitte geben Sie Ihr Herkunftsland an."
          },
          "bday": {
            "day": {
              "required": "Bitte geben Sie den Kalendertag Ihres Geburtstages ein.",
              "type_error": "Bitte geben Sie einen Wert zwischen 1 und 31 ein.",
              "min": "Dies ist kein gültiger Kalendertag.",
              "max": "Dies ist kein gültiger Kalendertag."
            },
            "month": {
              "required": "Bitte geben Sie den Kalendermonat Ihres Geburtstages ein.",
              "type_error": "Bitte geben Sie einen Wert zwischen 1 und 12 ein.",
              "min": "Dies ist kein gültiger Kalendermonat.",
              "max": "Dies ist kein gültiger Kalendermonat."
            },
            "year": {
              "required": "Bitte geben Sie das Kalenderjahr Ihres Geburtstages ein.",
              "type_error": "Bitte geben Sie einen Wert zwischen 1880 und ${year} ein.",
              "min": "Bitte geben Sie einen Wert zwischen 1880 und 2020 ein.",
              "max": "Bitte geben Sie einen Wert zwischen 1880 und 2020 ein."
            }
          },
          "card": {
            "bg": {
              "required": "Bitte geben Sie den linken Teil Ihrer BG Nummer ein.",
              "type_error": "Bitte geben Sie einen Wert mit 5 Zahlen ein.",
              "min": "Dies ist kein gültiger Wert für den linken Teil Ihrer BG Nummer.",
              "max": "Dies ist kein gültiger Wert für den linken Teil Ihrer BG Nummer."
            },
            "bg_number": {
              "required": "Bitte geben Sie den rechten Teil Ihrer BG Nummer ein.",
              "type_error": "Bitte geben Sie einen Wert mit 7 Zahlen für den rechten Teil Ihrer BG Nummer ein.",
              "min": "Dies ist kein gültiger Wert für den rechten Teil Ihrer BG Nummer.",
              "max": "Dies ist kein gültiger Wert für die rechten Teil Ihrer BG Nummer."
            }
          },
          "sex": {
            "required": "Bitte geben Sie Ihr Geschlecht an."
          },
          "tel": {
            "required": "Bitte geben Sie Ihre Telefonnummer ein.",
            "not_valid": "Bitte geben Sie eine gültige Telefonnummer ein."
          },
          "terms_and_conditions": "Bitte bestätigen Sie unsere Datenschutzerklärung.",
          "ident_number.required": "Bitte geben Sie Ihre Kundennummer ein."
        }
      },
      "appointment_info": {
        "at": "in",
        "location": "Standort",
        "header": "Ihre Termin Zusammenfassung",
        "title": "Ihr Termin wurde gebucht.",
        "title_reserved": "Ihr Termin wurde noch nicht bestätigt.",
        "title_on_site_reserved": "Ihr Termin wurde reserviert. Vor-Ort-Bestätigung erforderlich.",
        "title_desk_reserved": "Ihr Termin wurde reserviert. Mitarbeiterbestätigung erforderlich.",
        "infotext": "<h4>Push-Notification auf Ihr Smartphone</h4><p> Scannen Sie den QR-Code mit unserer cleverQ-App auf Ihrem Smartphone und erhalten Sie bequem Erinnerungen zur Ihrer Terminbuchung per Push-Notification.</p>",
        "confirmtext": "<h4>Hinweise zur Terminbestätigung</h4><p> Sie haben einen Termin bei uns reserviert. Um diesen verbindlich zu buchen, benötigen wir eine Bestätigung in der Ihnen zugestellten Email. Bitte klicken Sie auf den Link in dieser Email. Hiermit buchen Sie einen verbindlichen Termin bei uns.</p><p>{achtung} ohne diesen Vorgang verfällt die Reservierung.</p>",
        "on_site_confirmtext": "<h4>Vor-Ort-Bestätigung</h4><p> Bitte denken Sie daran, dass Sie dieses Dokument entweder in digitaler Form, auf Ihrem Smartphone, oder in ausgedruckter Papierform mit zu Ihrem Termin bringen. Sie benötigen entweder den Reservierungscode oder den QR-Code, um Ihren Termin vor Ort zu bestätigen und diesen dann wahrzunehmen.</p>",
        "desk_confirmtext": "<h4>Terminbestätigung durch einen Mitarbeiter</h4><p> Wir haben Ihre Terminanfrage an einen Mitarbeiter weitergeleitet. Sobald der Termin durch den Mitarbeiter bestätigt wurden, erhalten Sie eine Bestätigungs-Email.</p>",
        "reservation_code": "Reservierungs-Code",
        "summary": "Zusammenfassung",
        "issue": "Anliegen",
        "appointment": "Termin",
        "persons": "Personen.",
        "person": "eine Person",
        "name": "Name",
        "birthday": "Geburtstag",
        "email": "E-Mail Adresse",
        "phone": "Telefonnummer",
        "message": "Mitteilung",
        "back_button": "Zurück zum Anbieter",
        "print_button": "Drucken",
        "advertising": "<h4>cleverQ - Smartphone App</h4><p>Laden Sie unsere kostenlose cleverQ App und nutzen Sie die Vorteile Ihres Smartphones. <br/> Termine buchen, Tickets ziehen, Push-Mitteilungen, alles von unterwegs. <br/> Somit bleiben Sie immer auf dem Laufenden.</p>",
        "advertising_image": "/images/smartphone.png",
        "duplicate_info_title": "Termin duplicat",
        "duplicate_info_title_remove": "Bitte sagen Sie Ihren alten Termin ab",
        "duplicate_info_text": "Sie haben schon einen Termin gebucht. Bitte sagen Sie Ihren alten Termin ab, bevor Sie einen neuen Termin buchen. Sie können den alten Termin absagen, indem Sie die E-Mail prüfen, die Ihnen mit Ihrem alten Termin geschickt wurde.",
        "slot_already_booked_title": "Terminbuchung nicht möglich",
        "slot_already_booked_text": "Ihre gewünsche Terminauswahl wurde zwischenzeitlich leider von jemand anderem gebucht. Wir bitten die Unannehmlichkeiten zu entschuldigen und würden Sie bitte eine andere Terminauswahl zu tätigen."
      },
      "navigation": {
        "cleverq": "Denn wer wartet schon gerne?",
        "legal_notice": "Impressum",
        "policy": "Datenschutz",
        "terms_of_use": "Nutzungsbedingungen"
      },
      "greetings": {
        "confirm_greetings": "Weiter zur Terminbuchung"
      },
      "checkout_title": {
        "your": "Ihre",
        "choice": "Auswahl"
      },
      "enter_invite_code": {
        "verify": "Verifizieren",
        "invite_code":"Einladungscode"
      },
      "appointment_times": {
        "at": "am ",
        "on": "um ",
        "o_clock": "Uhr",
        "header": "Ihr reservierter Terminzeitpunkt.",
        "remove": "Neuen Terminzeitpunkt wählen",
        "session": {
          "text": "Ihre Sitzung läuft ab in: ",
          "close_error_modal": "Schließen",
          "reservation_expired": "Reservierung abgelaufen, bitte wählen Sie einen neuen Terminzeitpunkt.",
          "expired": "Ihre Sitzung ist abgelaufen, da Sie zu lange inaktiv waren. Bitte laden Sie die Seite neu."
        }
      }
  }
}
