import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactKeyboard from 'react-simple-keyboard';
import { Button } from 'react-bootstrap';
//import 'react-simple-keyboard/build/css/index.css';
import '../../../styles/keyboard.css';
import { injectIntl } from "react-intl";

class Keyboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layoutName: 'default',
      keyboard_pressed: true,
      text_preview: '',
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.active_input_name !== prevProps.active_input_name) {
      if (this.props.active_input_name) {
        document.getElementsByName(this.props.active_input_name)[0].style.borderColor = 'green'
        document.getElementsByName(this.props.active_input_name)[0].style.borderWidth = '3px'
        this.setState({ text_preview: this.props.formikRef.current.values[this.props.active_input_name], show_keyboard: true })
      }
    }
  }
  handleChange = input => {
    if (this.props.active_input_name) {
      let field = document.getElementsByName(this.props.active_input_name)[0]
      this.props.formikRef.current.setFieldValue(this.props.active_input_name, input)
      this.props.formikRef.current.setFieldTouched(this.props.active_input_name)
      this.setState({ text_preview: input })
    }
  };
  onKeyPress = button => {
    if (button === '{shift}' || button === '{lock}') this.handleShift();
    if (button === '{enter}') {
      this.props.reset_active_input();
    }
  };
  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === 'default' ? 'shift' : 'default'
    });
  };
  showKeyboard = type => {
    this.setState({ show_keyboard: true, edit_field: type });
  };
  close_keyboard = () => {
    this.setState({ show_keyboard: false });
    this.props.reset_active_input();
  };
  render() {
    document.addEventListener("touchstart", function() {}, true);
    return (
      <Fragment>
        <div
          id="keyboard_inside"
          className="keyboard-bottom"
          style={{
            visibility: this.props.active_input_name ? 'visible' : 'hidden'
          }}>
          {window.innerHeight > 920 ?
            <div className="container">
              <div style={{padding: '5px', background: 'white', borderRadius: '5px 5px 0 0', fontWeight: 'bold', fontSize: '30px', color: 'green'}}>{this.props.active_input_label}</div>
              <div className="keyboard-preview" style={{background: 'white'}}>{this.state.text_preview}</div>
            </div> : null}
          <Button
            onClick={e => this.close_keyboard()}
            className="keyboard-close-button pull-left">
            Tastatur schließen
          </Button>
          <ReactKeyboard
            ref={r => (this.keyboardRef = r)}
            inputName={this.props.active_input_name}
            layoutName={this.props.active_input_type && this.props.active_input_type !== 'number' && this.props.active_input_type !== 'bday' ? this.state.layoutName : 'number'}
            onChange={input => this.handleChange(input)}
            onKeyPress={button => this.onKeyPress(button)}
            theme={'simple-keyboard hg-theme-default'}
            display={{
              '{alt}': '.?123',
              '{smileys}': '\uD83D\uDE03',
              '{shift}': '⇧',
              '{shiftactivated}': '⇧',
              '{enter}': 'Eingabe',
              '{bksp}': '⌫',
              '{altright}': '.?123',
              '{downkeyboard}': '🞃',
              '{space}': 'Leertaste',
              '{default}': 'ABC',
              '{back}': '⇦'
            }}
            layout={{
              default: [
                '1 2 3 4 5 6 7 8 9 0   {bksp}',
                ' q w e r t z u i o p \u00FC ',
                ' a s d f g h j k l \u00F6 \u00E4 {enter}',
                '{shift} y x c v b n m . - {shift}',
                '.com .de @ {space}'
              ],
              shift: [
                '1 2 3 4 5 6 7 8 9 0 ` {bksp}',
                ' Q W E R T Z U I O P \u00DC ',
                " A S D F G H J K L \u00D6 \u00C4 ' {enter}",
                '{shift} Y X C V B N M _ {shift}',
                '.com .de @ {space}'
              ],
              number: [
                '1 2 3 4 5 6 7 8 9 0 {bksp}',
                '                ',
                '         {enter}',
                '                ',
                '                '
              ]
            }}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    selected_service: state.user_selected_service,
    selected_service_data: state.user_selected_service_data,
    selected_subtask: state.user_selected_subtask,
    user_count_persons: state.user_count_persons,
    selected_day: state.user_selected_day,
    selected_slot: state.user_selected_slot,
    form: state.form,
    user_data: state.user_data
  };
}

export default injectIntl(connect(mapStateToProps)(Keyboard));
