import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import FlagIcon from "react-world-flags";
import { Default, Adac, Adac_header, Lufthansa, Ikk, FinanzamtSH, KreisPaderborn } from './themes/Header'
import { injectIntl } from "react-intl";
import localeWrapperFlagIcons from "../../helpers/localeWrapperFlagIcons";
import ReactCountryFlag from "react-country-flag";


const query = new URLSearchParams(window.location.search);

class NavigationTop extends Component {
  onSelectFlag(lang) {
    const queryUrlToken = query.get('url_token')
    const queryServiceId = query.get('service_id')
    const queryHash = query.get('hash')
    const queryTouchscreenMode = query.get('touchscreen_mode')
    const queryEmbeddedMode = query.get('embedded_mode')
    const querySamsungMode = query.get('samsung_mode')
    const queryTouchscreenAccessToken = query.get('touchscreen_access_token')
    window.location.replace(
      `${window.location.origin}/public/sites/${
        this.props.site_info.id
      }/appointments/index.html?lang=${lang}${queryUrlToken ? '&url_token=' + queryUrlToken : ''}${queryServiceId ? '&service_id=' + queryServiceId : ''}${queryHash ? '&hash=' + queryHash : ''}${queryTouchscreenMode ? '&touchscreen_mode=true' : ''}${queryEmbeddedMode ? '&embedded_mode=true' : ''}${querySamsungMode ? '&samsung_mode=true' : ''}${queryTouchscreenAccessToken ? '&touchscreen_access_token=' + queryTouchscreenAccessToken : ''}`
    );
    this.props.setActiveLanguage(lang);
    this.props.localeSet(lang);
  }

  render() {
    const query = new URLSearchParams(window.location.search);
    const embedded_mode =
        query.get('embedded_mode') === 'true' ? true : false;
    const samsung_mode =
      query.get('samsung_mode') === 'true' ? true : false;
    let header_render
    switch (this.props.site_info.custom_identifier) {
      case 1:
        header_render = <Adac site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)}/>
        break;
      case 2:
        header_render = <Lufthansa site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)}/>
        break;
      case 3:
        header_render = <Ikk site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)}/>
        break;
      case 4:
        header_render = <FinanzamtSH site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)}/>
        break;
      case 5:
        header_render = <KreisPaderborn site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)}/>
        break;
      case 6:
        // ADAC Württemberg
        header_render = <Adac_header site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)}/>
        break;
      case 7:
        // ADAC NSA
        header_render = <Adac_header site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)}/>
        break;
      default:
        header_render = <Default site_info={this.props.site_info} locales={this.props.locales} getOnSelectFlag={this.onSelectFlag.bind(this)} embedded_mode={embedded_mode}/>
    }
    return this.props.site_info.show_header ? (
      <div className="mobile-header zindexbooster">{header_render}</div>
    ) : (
      <div className="container zindexbooster" style={{minHeight: '35px', marginBottom: '10px'}}>
        <div className="row">
          {!this.props.site_info.show_header && this.props.site_info.show_locale_selector ? (
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '10px', marginRight: '-10px', paddingTop: embedded_mode === true ? '50px' : '10px'}}>
              <div className="side-by-side">
                {this.props.locales.map(locale => (
                  <div key={locale.code} className="side" style={samsung_mode === true ? {cursor: 'pointer', marginTop: '15px'} : {cursor: 'pointer'}} onClick={e => this.onSelectFlag(locale.code)}>
                    <ReactCountryFlag
                      countryCode={localeWrapperFlagIcons(locale.code)}
                      style={{fontSize: samsung_mode === true ? '6rem' : '5rem'}}
                      svg
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    selected_language: state.user_selected_language
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(NavigationTop)
);
