import {
  ADD_APPOINTMENT_TIME,
  EDIT_APPOINTMENT_TIME,
  REMOVE_APPOINTMENT_TIME,
  RESET_APPOINTMENT_TIMES, USER_REMOVED_SUBTASK,
  USER_SELECTED_SUBTASK, USER_UPDATE_PERSONS,
} from '../actions/types'

const reducer = (state = [], action) => {
  switch (action.type) {
    case ADD_APPOINTMENT_TIME:
      // const setSubtaskNewState = state.filter((item) => item.subtask.id !== action.payload.id)
      // const item = {
      //   subtask: action.payload,
      //   count: 1,
      // }
      const filterState = state.filter((item) => item.id !== action.payload.id)
      return [...filterState, action.payload]
    case EDIT_APPOINTMENT_TIME:
      // const setSubtaskNewState = state.filter((item) => item.subtask.id !== action.payload.id)
      // const item = {
      //   subtask: action.payload,
      //   count: 1,
      // }

      const stateBeforeEdit = state.filter((item) => item.id !== action.payload.id)
      return [action.payload, ...stateBeforeEdit]
    case REMOVE_APPOINTMENT_TIME:
      const removedState = state.filter((item) => item.id !== action.payload)
      return removedState
    case RESET_APPOINTMENT_TIMES:
      return []
    default:
      return state
  }
}

export default reducer
