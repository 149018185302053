import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/de';
import { FETCH_APPOINTMENT_DAYS, FETCH_APPOINTMENT_DAYS_ERROR } from '../actions/types';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_APPOINTMENT_DAYS_ERROR:
      return [];
    case FETCH_APPOINTMENT_DAYS:
      let weekArray = [];
      // Groups data to weeks.
      if (action.payload && action.payload.data) {
        let sortedByWeek = _.groupBy(action.payload.data.available_days, result =>
          moment(result['day'], 'YYYY-MM-DD').startOf('isoWeek')
        );

        // Converts the object of objects to an array of objects.
        let convertToArrayOfObject = Object.keys(sortedByWeek).map(key => {
          return sortedByWeek[key];
        });

        for (let i = 0; i < convertToArrayOfObject.length; i++) {
          // Get each week.
          let week = moment(convertToArrayOfObject[i][0].day).week();
          // Get the starting point of the week.
          let start_of_week = moment(convertToArrayOfObject[i][0].day).startOf(
            'week'
          );
          // Get the endpoint of the week.
          let end_of_week = moment(convertToArrayOfObject[i][0].day).endOf(
            'week'
          );
          let daysArray = [];
          for (let j = 0; j < 7; j++) {
            // Put all days from a week to an array with a template.
            let date = moment(start_of_week)
              .add(j, 'days')
              .format('YYYY-MM-DD');
            // If a appointment is present at the specific day then present is true.
            let present = false;
            if (_.find(action.payload.data.available_days, { day: date })) {
              present = true;
            }
            let template = {
              day: date,
              present: present
            };
            daysArray.push(template);
          }
          // Makes the whole array of objects for map.
          weekArray.push({
            duration: action.payload.data.duration,
            duration_minutes: action.payload.data.duration_minutes,
            week: week,
            range: start_of_week.format(
              'DD.MM -' + end_of_week.format(' DD.MM.YYYY')
            ),
            days: daysArray
          });
        }
      }
      // Take the existing state and concatenate to the new list of days
      return weekArray;
    default:
      return state;
  }
}
