import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import moment from 'moment';
import Modal from "react-modal";
import {injectIntl} from "react-intl";

class AppointmentTime extends Component {
  constructor() {
    super();

    this.state = {
      counter: null,
      show_expired_modal: false
    }
  }
  componentDidMount() {
    this.props.setHideDayAndSlotPicker();
    this.appointment_time_countdown = null
    this.appointment_time_countdown = setInterval(() => {
      this.setState({ counter: this.state.counter + 1 })
      if (moment().add(1, 'seconds').isAfter(this.props.appointment_times[0].expiration)) {
        this.setState({ counter: null, show_expired_modal: true })
        clearInterval(this.appointment_time_countdown)
      }
    }, 1000)
  }
  componentWillUnmount() {
    clearInterval(this.appointment_time_countdown)
  }
  renderTimer = (expiration) => {
    let now = moment()
    let end = moment(expiration)
    let duration = moment.duration(end.diff(now));
    let time = moment.utc(duration.as('milliseconds')).format('HH:mm:ss')

    return <span style={duration.as('seconds') < 180 ? {color: this.props.site_info.delete_button_background} : {color: 'green'}}>{time}</span>
  }
  closeErrorModal = () => {
    this.setState({ show_expired_modal: false })
    this.props.selectedSlot('hidden');
    this.props.selectedSlotTimeOfSlot('hidden');
    this.props.resetAppointmentTimes();
  }
  handleClick() {
    this.props.resetAppointmentTimes()
    this.props.setHideDataEdit();
  }
  render() {
    const {intl} = this.props
    const modalCustomStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        width                 : '50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 110
      },
    };

    return (
      <div
        className={`container animated fadeIn ${
          this.props.site_info.drop_shadows ? 'drop-shadow' : null
        }`}>
        <div className="row">
          <div className="col-md-12 issues_header_left">
            {intl.formatMessage({id: "appointment_times.header"})}
          </div>
        </div>
        <div
          className="row inline-shadow"
          style={{
            backgroundColor: this.props.site_info.content_background,
            color: this.props.site_info.content_text,
            fontSize: '1.6rem'
          }}>
          <div style={{ marginTop: '10px' }} />
          <div className="col-md-12" style={{marginBottom: '10px'}}>
            <div>
              <span style={{fontSize: '1.1em'}}><span>{intl.formatMessage({id: "appointment_times.at"})}</span><span style={{fontWeight: 'bold'}}>{moment(this.props.selected_day).format('L')} </span>
              <span>{intl.formatMessage({id: "appointment_times.on"})}</span><span style={{fontWeight: 'bold'}}>{this.props.time_slot} </span><span>{intl.formatMessage({id: "appointment_times.o_clock"})}</span></span>
              <button
                className={`btn ${
                  this.props.site_info.drop_shadows ? 'drop-shadow' : ''
                }`}
                onClick={this.handleClick.bind(this)}
                style={{
                  marginLeft: '20px',
                  marginTop: '-5px',
                  background: this.props.site_info.delete_button_background,
                  color: this.props.site_info.delete_button_text,
                  border: '1px solid #CBCBCBFF'
                }}>
                {intl.formatMessage({id: "appointment_times.remove"})}
              </button>
              <span className="pull-right" style={{fontSize: '1.1em', color: this.props.site_info.delete_button_background}}>
              {!this.state.show_expired_modal ?
                <span style={{fontWeight: 'bold'}}>{intl.formatMessage({id: "appointment_times.session.text"})}{this.renderTimer(this.props.appointment_times[0].expiration)}</span> :
                <span>{intl.formatMessage({id: "appointment_times.session.expired"})}</span>
              }
              </span>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.show_expired_modal}
          onRequestClose={this.closeErrorModal.bind(this)}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          style={modalCustomStyles}
        >
          <span style={{fontWeight: 'bold', color: 'red', fontSize: '1.6rem'}}>{intl.formatMessage({id: 'appointment_times.session.reservation_expired'})}</span><br/>

          <button
            className={`btn ${
              this.props.site_info.drop_shadows ? 'drop-shadow' : null
            }`}
            onClick={this.closeErrorModal}
            style={{
              marginTop: '10px',
              background: this.props.site_info.confirm_button_background,
              color: this.props.site_info.confirm_button_text,
              border: '1px solid #CBCBCBFF'
            }}>
            {intl.formatMessage({id: 'appointment_times.session.close_error_modal'})}
          </button>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    days: state.days,
    selected_service: state.user_selected_service,
    selected_subtask: state.user_selected_subtask,
    selected_day: state.user_selected_day,
    time_slot: state.user_selected_slot_time_of_slot,
    user_person_count: state.user_count_persons,
    no_issues: state.no_issues,
    ui: state.ui,
    appointment_times: state.appointment_time
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(AppointmentTime)
);
