export const FETCH_SITE_URL_NAME = 'fetch_site_url_name';
export const FETCH_SITE_INFO = 'fetch_site_info';
export const FETCH_SERVICES = 'fetch_services';
export const FETCH_SUBTASKS = 'fetch_subtasks';
export const FETCH_SESSION_INFO = 'fetch_session_info';
export const SET_SESSION_INFO = 'set_session_info';
export const FETCH_APPOINTMENT_DAYS = 'fetch_appointment_days';
export const FETCH_APPOINTMENT_SLOTS = 'fetch_appointment_slots';
export const RESET_APPOINTMENT = 'reset_appointment';
export const CREATE_APPOINTMENT = 'create_appointment';
export const CREATE_APPOINTMENT_TOUCHSCREEN = 'create_appointment_touchscreen';
export const USER_SELECTED_SERVICE = 'user_selected_service';
export const USER_SELECTED_SERVICE_DATA = 'user_selected_service_data';
export const USER_SELECTED_SERVICE_NAME = 'user_selected_service_name';
export const USER_SELECTED_SERVICE_GROUP = 'user_selected_service_group';
export const USER_SELECTED_SERVICE_GROUP_DATA =
  'user_selected_service_group_data';
export const USER_SELECTED_SUBTASK_GROUP = 'user_selected_subtask_group';
export const USER_SELECTED_SUBTASK_GROUP_DATA =
  'user_selected_subtask_group_data';
export const USER_SELECTED_DAY = 'user_selected_day';
export const USER_SELECTED_SLOT = 'user_selected_slot';
export const USER_SELECTED_SLOT_TIME_OF_SLOT =
  'user_selected_slot_time_of_slot';
export const USER_SELECTED_WEEK_CHANGE = 'user_week_change';
export const USER_UPDATE_PERSONS = 'user_update_persons';
export const USER_SET_PERSONS_NULL = 'user_set_persons_null';
export const USER_OVERWRITE_PERSONS = 'user_overwrite_persons';
export const USER_SELECTED_LANGUAGE = 'user_selected_language';
export const USER_SELECTED_SUBTASK = 'user_selected_subtask';
export const USER_REMOVED_SUBTASK = 'user_removed_subtask';
export const USER_RESET_SUBTASK_PERSONS = 'user_reset_subtasks_person';
export const USER_RESET_SERVICE = 'user_reset_service';
export const USER_RESET_SUBTASKS = 'user_reset_subtasks';
export const USER_RESET_SERVICE_GROUP = 'user_reset_service_group';
export const USER_RESET_SUBTASK_GROUP = 'user_reset_subtask_group';
export const USER_RESET_DAY = 'user_reset_day';
export const USER_RESET_SLOT = 'user_reset_slot';
export const RESET_USER_SELECTED_SLOT_TIME_OF_SLOT =
  'reset_user_selected_slot_time_of_slot';
export const USER_ONLY_ONE_SUBTASK = 'user_only_one_subtask';
export const SET_NO_ISSUES = 'set_no_issues';
export const SERVICE_EDIT = 'service_edit';
export const SERVICE_GROUP_EDIT = 'service_group_edit';
export const SUBTASK_GROUP_EDIT = 'subtask_group_edit';
export const SUBTASK_EDIT = 'subtask_edit';
export const DAY_EDIT = 'day_edit';
export const SLOT_EDIT = 'slot_edit';
export const DATA_EDIT = 'data_edit';
export const SHOW_POLICY = 'show_policy';
export const HIDE_POLICY = 'hide_policy';
export const UPDATE_TIME_FRAME = 'update_time_frame';
export const SHOW_DATA_DELETE = 'show_data_delete';
export const VALIDATE_INVITE_URL_TOKEN = 'VALIDATE_INVITE_URL_TOKEN'
export const VALIDATE_INVITE_URL_TOKEN_ERROR = 'VALIDATE_INVITE_URL_TOKEN_ERROR'
export const HIDE_SERVICE_SELECTOR = 'hide_service_selector'
export const HIDE_SERVICE_GROUP_SELECTOR = 'hide_service_group_selector'
export const HIDE_SUBTASK_GROUP_SELECTOR = 'hide_subtask_group_selector'
export const HIDE_SUBTASK_SELECTOR = 'hide_subtask_selector'
export const SHOW_SUBTASK_SELECTOR = 'show_subtask_selector'
export const CONFIRM_GREETINGS = 'confirm_settings'
export const RESET_DAY_EDIT = 'reset_day_edit'
export const FETCH_APPOINTMENT_DAYS_ERROR = 'fetch_appointment_days_error'
export const CREATE_RESERVATION_SUCCESS = 'create_reservation_success'
export const CREATE_RESERVATION_FAILED = 'create_reservation_failed'
export const DESTROY_RESERVATION_SUCCESS = 'destroy_reservation_success'
export const DESTROY_RESERVATION_FAILED = 'destroy_reservation_failed'
export const UPDATE_RESERVATION_SUCCESS = 'create_reservation_success'
export const UPDATE_RESERVATION_FAILED = 'create_reservation_failed'
export const ADD_APPOINTMENT_TIME = 'add_appointment_time'
export const EDIT_APPOINTMENT_TIME = 'edit_appointment_time'
export const REMOVE_APPOINTMENT_TIME = 'remove_appointment_time'
export const RESET_APPOINTMENT_TIMES = 'reset_appointment_times'
export const HIDE_DAY_AND_SLOT_PICKER = 'hide_day_and_slot_picker'
export const HIDE_DATA_EDIT = 'hide_data_edit'
export const BOOK_APPOINTMENT_SUCCESS = 'book_appointment_success'
export const BOOK_APPOINTMENT_FAILED = 'book_appointment_failed'
export const STORE_BOOKING_SESSION_KEY = 'store_booking_session_key'
export const RESET_WAITING_LIST_ENTRY = 'reset_waiting_list_entry';
export const CREATE_WAITING_LIST_ENTRY = 'create_waiting_list_entry';
export const WAITING_LIST_ENTRY_SUCCESS = 'waiting_list_entry_success';
export const WAITING_LIST_ENTRY_FAILED = 'waiting_list_entry_failed';

export const TOUCHSCREEN_MODE = 'touchscreen_mode';