import { USER_SELECTED_LANGUAGE } from '../actions/types';

export default function(state = { lang: 'de' }, action) {
  switch (action.type) {
    case USER_SELECTED_LANGUAGE:
      // Take the existing state and concatenate to the new list of days
      return { lang: action.payload };
    default:
      return state;
  }
}
