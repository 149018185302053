import { LOCALES } from '../locales'

export default {
    [LOCALES.SWEDISH]: {
        "issues": {
            "browser_header": "Boka enkelt ditt möte online.",
            "what_is_cleverq": "Vad är cleverQ?",
            "additional_issues": "Välj ytterligare problem."
        },
        "app": {
            "header": "Vad är cleverQ?",
            "important": "Aktuell information"
        },
        "persons": {
            "header": "Ange hur många personer som du vill skall delta i mötet.",
            "information": "<b style={{ color: 'red' }}>VÄNLIGEN NOTERA:</b>Notera Du kan bara boka en tid. Även om du behöver både boka ett möte och ett uppföljningsmöte. Även om du bara kan boka en tid, kommer du att bli omhändertagen i båda ärendena.",
            "continue": "Fortsätt till val av möte",
            "remove": "Ta bort",
            "to_much_time": "Antalet förfrågningar och processer överskrider den tillåtna mötestiden. Minska ditt urval."
        },
        "datepicker": {
            "header": "Välj en önskad dag.",
            "mon": "Mån.",
            "tue": "Tis.",
            "wed": "Ons.",
            "thu": "Tor.",
            "fri": "Fre.",
            "sat": "Lör.",
            "sun": "Sön.",
            "no_appointments": "Tyvärr är alla datum för ditt val för närvarande fullbokade. Vänligen försök igen senare.",
            "error": "Det uppstod ett problem med din förfrågan, försök igen."
        },
        "slotpicker": {
            "header": "Välj ett önskat datum.",
            "no_appointments": "Fördetta datum finns det inga lediga tider.",
            "legend_green": "Möten tillgängliga",
            "legend_yellow": "Det finns bara enstaka tider kvar",
            "legend_grey": "Det finns inga tillgängliga tider",
            "timeout_reached": "Anslutningen till servern avbröts, försök igen."
        },
        "dataforms": {
            "ident_number": "Customer number",
            "ident_number_text": "Please enter your customer number so that you can confirm your appointment on site with your customer card.",
            "mandatory_fields": "Obligatoriska fält",
            "header": "Vänligen ange dina personuppgifter nu.",
            "data_delete": "Din kontaktinformation blir automatiskt raderad av cleverQ efter ditt möte.",
            "ms": "Fru",
            "mr": "Herr",
            "salutation": "Tilltalstitel",
            "company": "Firmanamn",
            "given_name": "Förnamn",
            "surname": "Efternamn",
            "birthday": "Födelsedatum i format dd.mm.åååå",
            "email": "Email adress",
            "email_confirmation": "Upprepa email adress",
            "email_optional": "Ytterligare email adress",
            "email_confirmation_optional": "Upprepa ytterligare email adress",
            "summary": "Sammanfattning:",
            "phone": "Valfritt telefonnummer",
            "tel_information": " ",
            "appointment_for": " ",
            "appointment_around": " vid",
            "appointment_for_only": "för ",
            "on": "på",
            "clock": "klockan",
            "message": "Valfritt meddelande om ditt möte.",
            "book_appointment": "Gör en bindande reservation för mötet nu",
            "book_appointment_not_valid": "Vänligen fyll i alla markerade obligatoriska fält",
            "book_appointment_started": "Mötet bokas, var vänlig vänta...",
            "book_appointment_failed": "Mötesbokningen misslyckades, klicka igen",
            "terms_and_conditions": "Integritetspolicy",
            "terms_and_conditions_text_for_confirm": "Genom att använda online bokningen godkänner du att de personuppgifter du lämnar kommer att behandlas av oss. <br/> <br/> Alla personuppgifter lagras endast i syfte att behandla möten.",
            "terms_and_conditions_link": "Länk till integritetspolicy",
            "email_info": "För att mötesbekräftelsen och eventuella andra nödvändiga dokument ska kunna skickas till dig via e-post, <br/> måste du ange din e-postadress. Om du inte tillhandahåller detta är det tyvärr inte möjligt att använda onlinemötesavtalet.",
            "mobile_phone_number": "Mobilnummer",
            "sms_notifications_label": "SMS-aviseringar",
            "sms_notifications_checkbox_text": "Jag vill gärna hållas uppdaterad om mitt möte via SMS-aviseringar.",
            "sms_notifications_text": "Vi kommer inte att ringa dig, inte heller kommer ditt mobilnummer att användas för något annat ändamål.",
            "back": "Tillbaka",
            "cancel": "Avbryt",
            "close_privacy_policy": "Stäng integritetspolicy",
            "confirm_policy": "Please confirm the privacy policy",
            "policy": "I have read the data protection information and agree to my data being processed.",
            "show_policy": "Show privacy policy"
        },
        "dataforms_waiting_list_entry": {
            "header": "Registrering för väntelista",
            "waiting_list_entry_not_valid": "Fyll i alla markerade obligatoriska fält",
            "waiting_list_entry": "Registrera dig nu utan förpliktelser",
            "waiting_list_entry_started": "Registrering för väntelistan genomförs, var vänlig vänta…",
            "go_to_waiting_list_entry": "Gå vidare till väntelistan",
            "successfull_registered": "Vi har mottagit er registrering, för att verkställa den, klicka på länken i mailet vi har skickat.",
            "register_waiting_list_entry": "Begära registrering"
        },
        "forms": {
            "bday": {
                "day": "Dag",
                "month": "Månad",
                "year": "År"
            },
            "email_confirm": "upprepa",
            "honorific_prefix": {
                "mister": "Herr",
                "miss": "Fru",
                "other": "Annat"
            },
            "validations": {
                "default": {
                    "required": "Denna information är nödvändig.",
                    "min": "Ange minst 2 tecken.",
                    "max": "För många tecken.",
                    "letter_only": "I detta fält kan endast bokstäver anges.",
                    "numbers_only": "I detta fält kan endast siffror anges."
                },
                "number": {
                    "min": "Ange ett tal större än noll.",
                    "max": "Ange ett tal mindre än 1000000."
                },
                "email": {
                    "required": "Ange din email adress.",
                    "not_matching": "Email adresserna matchar inte.",
                    "not_a_valid_email": "Ange en giltig email adress."
                },
                "options": {
                    "required": "Välj ett alternativ."
                },
                "name": {
                    "required": "Ange ditt namn."
                },
                "given_name": {
                    "required": "Ange ditt förnamn."
                },
                "family_name": {
                    "required": "Ange ditt efternamn."
                },
                "honorific_prefix": {
                    "required": "Välj en titel."
                },
                "street_address": {
                    "required": "Ange din gatuadress."
                },
                "postal_code": {
                    "required": "Ange ditt postnummer."
                },
                "city": {
                    "required": "Ange din ort."
                },
                "country_name": {
                    "required": "Ange ditt land."
                },
                "bday": {
                    "day": {
                        "required": "Ange din dag för din födelsedag.",
                        "type_error": "Ange ett värde mellan 1 och 31.",
                        "min": "Detta är inte en giltig kalenderdag.",
                        "max": "Detta är inte en giltig kalenderdag."
                    },
                    "month": {
                        "required": "Ange månad för din födelsedag.",
                        "type_error": "Ange ett värde mellan 1 och 12.",
                        "min": "Detta är inte en giltig kalendermånad.",
                        "max": "Detta är inte en giltig kalendermånad."
                    },
                    "year": {
                        "required": "Ange år för din födelsedag.",
                        "type_error": "Ange ett värde mellan 1880 och ${year}.",
                        "min": "Ange ett värde mellan 1880 och 2020.",
                        "max": "Ange ett värde mellan 1880 och 2020."
                    }
                },
                "card": {
                    "bg": {
                        "required": "Vänligen ange den vänstra delen av ditt BG-nummer.",
                        "type_error": "Ange ett värde med 5 siffror.",
                        "min": "Detta är inte ett giltigt värde för den vänstra delen av ditt BG-nummer.",
                        "max": "Detta är inte ett giltigt värde för den vänstra delen av ditt BG-nummer."
                    },
                    "bg_number": {
                        "required": "Ange den högra delen av ditt BG-nummer.",
                        "type_error": "Ange ett värde med 7 siffror för den högra delen av ditt BG-nummer.",
                        "min": "Detta är inte ett giltigt värde för den högra delen av ditt BG-nummer.",
                        "max": "Detta är inte ett giltigt värde för den högra delen av ditt BG-nummer."
                    }
                },
                "sex": {
                    "required": "Ange ditt kön."
                },
                "tel": {
                    "required": "Ange ditt telefonnummer.",
                    "not_valid": "Ange ett giltigt telefonnummer."
                },
                "terms_and_conditions": "Bekräfta vår integritetspolicy.",
                "ident_number.required": "Please enter your customer number."
            }
        },
        "appointment_info": {
            "at": "I",
            "location": "Plats",
            "header": "Din summering av mötet",
            "title": "Ditt möte är bokat.",
            "title_reserved": "Ditt möte är inte bekräftat ännu.",
            "title_on_site_reserved": "Ditt möte har reserverats. Bekräftelse på plats krävs.",
            "title_desk_reserved": "Ditt möte har reserverats. Bekräftelse av medarbetare krävs.",
            "infotext": "<h4>Push-avisering på din smartphone</h4><p> Skanna QR-koden med vår cleverQ-app på din smartphone och enkel få påminnelser om att boka din tid via push-avisering.</p>",
            "confirmtext": "<h4>Bokningsbekräftelse</h4><p> Du har bokat en tid hos oss. För att boka detta bindande behöver vi en bekräftelse i mejlet som skickas till dig. Klicka på länken i detta mejl. Du bokar härmed ett bindande möte med oss.</p><p>${achtung} utan denna process, upphör bokningen.</p>",
            "on_site_confirmtext": "<h4>Bekräftelse på plats</h4><p> Kom ihåg att ta med detta dokument till ditt möte, antingen i digital form på din smartphone eller i tryckt pappersform. Du behöver antingen bokningskoden eller QR-koden för att bekräfta din tid på plats och sedan behålla den.</p>",
            "desk_confirmtext": "<h4>Bokningsbekräftelse av en anställd</h4><p> Vi har vidarebefordrat din mötesbegäran till en anställd. Så snart mötet har bekräftats av medarbetaren får du ett bekräftelsemail</p>",
            "reservation_code": "Bokningskod",
            "summary": "Sammanfattning",
            "issue": "AnKategoriliegen",
            "appointment": "Möte",
            "persons": "Personer.",
            "person": "Person",
            "name": "Namn",
            "birthday": "Födelsedag",
            "email": "Email",
            "phone": "Telefonnummer",
            "message": "Meddelande",
            "back_button": "Åter till leverantören",
            "print_button": "Skriv ut",
            "advertising": "<h4>cleverQ - Smartphone-app</h4> <p>Ladda ner vår kostnadsfria cleverQ-app och utnyttja fördelarna med din smartphone. <br/> Boka tider, köp biljetter, push-meddelanden, allt på språng. Så du är alltid uppdaterad.</p>",
            "advertising_image": "/images/smartphone.png",
            "duplicate_info_title": "Kopia av mötet",
            "duplicate_info_title_remove": "Avboka ditt gamla möte",
            "duplicate_info_text": "Du har redan bokat en tid. Avboka ditt gamla möte innan du bokar ett nytt. Du kan avboka det gamla mötet genom att kontrollera e-postmeddelandet som skickades till dig med ditt gamla möte.",
            "slot_already_booked_title": "Det är inte möjligt att boka ett möte",
            "slot_already_booked_text": "Tyvärr har datumet du begärt under tiden bokats av någon annan. Vi ber om ursäkt för besväret och ber dig göra ett annat mötesval."
        },
        "navigation": {
            "cleverq": "För vem vill väl vänta?",
            "legal_notice": "Allmänna villkor",
            "policy": "Integritetspolicy"
        },
        "greetings": {
            "confirm_greetings": "Fortsätt till bokning"
        },
        "checkout_title": {
            "your": "Ditt",
            "choice": "Val"
        },
        "enter_invite_code": {
            "verify": "Verifiera",
            "invite_code":"Inbjudningskod"
        },
        "appointment_times": {
            "at": "vid ",
            "on": "på ",
            "o_clock": "Klockan",
            "header": "Din bokade mötes tid.",
            "remove": "Välj en ny mötestid",
            "session": {
                "text": "Din session löper ut om: ",
                "close_error_modal": "Din session löper ut om:",
                "reservation_expired": "Din reservering har löpt ut eftersom du har varit inaktiv för länge. Vänligen ladda om sidan.",
                "expired": "Din session har löpt ut eftersom du har varit inaktiv för länge. Vänligen ladda om sidan."
            }
        }
    }
}