import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from "react-intl";
import * as actions from '../../actions';
import { scrollToTarget } from './elements/ScrollToTarget'
import moment from 'moment';
import Helmet from 'react-helmet';
import Modal from "react-modal";
import {BarLoader, ScaleLoader} from 'react-spinners';

const query = new URLSearchParams(window.location.search);
const queryEmbeddedMode = query.get('embedded_mode')
const embedded_mode = queryEmbeddedMode === "true" ? true : false
let language
class SlotPicker extends Component {
  state = {
    orderedByHours: null,
    columns: null,
    show_error_modal: false,
    modal_error_message: false,
    disabled: false,
    fetching: false,
    timeout_reached: false
  };
  closeErrorModal = () => {
    if (!this.state.timeout_reached) {
      this.setState({ show_error_modal: false })

      // Fetches the appointment slots
      this.props.fetchAppointmentSlots(
        this.props.site_info.id,
        this.props.selected_service,
        this.props.selected_day,
        this.props.no_issues === false
          ? this.props.user_person_count
          : 'no_issues'
      ).then(() => {
        this.props.selectedSlot('hidden');
        this.props.selectedSlotTimeOfSlot('hidden');
        this.props.setSlotEdit();
      });
    } else {
      window.location.reload();
    }
  }
  handleClick(slot_id, time_of_slot, slot, e) {
    e.preventDefault();
    language = query.get('lang') && query.get('lang').length > 1  ? query.get('lang') : this.props.default_locale
    if (slot.outdated !== true && slot.available !== 0) {
      // Get an reservation key
      this.setState({ fetching: true })
      if (!this.props.appointment_times.length) {
        this.props.createReservation(this.props.site_info.id, language).then(data => {
          if (data.type !== 'create_reservation_failed') {
            // Update the reservation with the selected parameters
            this.props.updateReservation(
              this.props.site_info.id,
              data.payload.key,
              this.props.selected_service,
              slot_id,
              this.props.selected_day,
              convert_selected_subtasks(this.props.subtask_items),
              language,
            ).then(response => {
              if (response.type === 'create_reservation_success') {
                this.setState({ fetching: false })
                // Add appointment time
                this.props.addAppointmentTime({
                  id: data.payload.key,
                  day: this.props.selected_day,
                  slot: slot_id,
                  reservation_key: response.payload.data.key,
                  expiration: response.payload.data.expiration
                })
                // Continue with the booking process
                this.props.selectedSlot(slot_id);
                this.props.selectedSlotTimeOfSlot(time_of_slot);
                this.props.setDataEdit();
                scrollToTarget("scroll_target_data_forms")
              } else {
                // Shows an error modal
                if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10 , 11, 12, 13, 14].includes(response.payload.code)) {
                  this.setState({show_error_modal: true, modal_error_message: response.payload.message, fetching: false})
                }
              }
            })
          } else {
            this.setState({show_error_modal: true, modal_error_message: <FormattedMessage id="appointment_times.session.expired" />, fetching: false, timeout_reached: true})
          }
        })
      } else {
        // Update reservation if already a reservation exists in the client
        let reservation = this.props.appointment_times[0]
        this.props.updateReservation(
          this.props.site_info.id,
          reservation.reservation_key,
          this.props.selected_service,
          slot_id,
          this.props.selected_day,
          convert_selected_subtasks(this.props.subtask_items),
          language
        ).then(response => {
          if (response.type) {
            this.setState({ fetching: false })
            // Edit the appointment time, for now onl the first one.
            // So it is not possible to bookt more then one appointment at a time.
            this.props.editAppointmentTime({
              id: response.payload.data.key,
              day: this.props.selected_day,
              slot: slot_id,
              reservation_key: response.payload.data.key,
              expiration: response.payload.data.expiration
            })
            // Continue with the booking process
            this.props.selectedSlot(slot_id);
            this.props.selectedSlotTimeOfSlot(time_of_slot);
            this.props.setDataEdit();
            scrollToTarget("scroll_target_data_forms")
          } else {
            // Here comes a pop or something?
            this.setState({show_reservation_error_modal: true, fetching: false})
            console.error('Slot could not be reserved.')
          }
        })
      }
    } else {
      console.log('Slot is not available.');
      this.setState({ fetching: false })
    }
  }
  renderSlots() {
    return this.props.slots.time_slots.map(slot => {
      if (!this.props.site_info.show_outdated_and_booked_slots) {
        return (
          slot.available > 0 && !slot.outdated ?
            <Fragment key={slot.slot}>
              <div
                onClick={!this.state.fetching ? e => this.handleClick(slot.slot, slot.time_of_slot, slot, e) : null}
                className={`col-sm-${12 /
                this.props.slots.appointment_slots_per_hour}`}
                style={{ padding: '5px' }}>
                <div
                  className={`time_slot inline-shadow ${slot.available === 0 ? 'no_tab' : 'tab_in'} ${
                    this.props.site_info.drop_shadows ? 'drop-shadow' : null
                  }`}
                  style={{
                    border: this.props.site_info.border_on_items ? '1px solid black' : null,
                    backgroundColor:
                      slot.available === 0
                        ? '#dddddd'
                        : slot.available === 1
                        ? this.props.selected_slot === slot.slot
                          ? this.props.site_info.slot_active
                          : this.props.site_info.show_only_few_slots_left ? this.props.site_info.slot_some_appointments_left_background : this.props.site_info.slot_appointments_available_background
                        : this.props.selected_slot === slot.slot
                          ? this.props.site_info.slot_active
                          : this.props.site_info
                            .slot_appointments_available_background,
                    color:
                      slot.available === 0
                        ? '#969696'
                        : slot.available === 1
                        ? this.props.selected_slot === slot.slot
                          ? this.props.site_info.slot_text_active
                          : this.props.site_info.show_only_few_slots_left ? this.props.site_info.slot_some_appointments_left_text : this.props.site_info.slot_text
                        : this.props.selected_slot === slot.slot
                          ? this.props.site_info.slot_text_active
                          : this.props.site_info
                            .slot_text,
                    textDecoration:
                      this.props.selected_slot === slot.slot ? 'underline' : 'none'
                  }}>
                  <span className="no_tab">{slot.time_of_slot}</span>
                </div>
              </div>
            </Fragment> : null
        );
      } else {
        return (
          <Fragment key={slot.slot}>
            <div
              onClick={!this.state.fetching ? e => this.handleClick(slot.slot, slot.time_of_slot, slot, e) : null}
              className={`col-sm-${12 /
              this.props.slots.appointment_slots_per_hour}`}
              style={{ padding: '5px' }}>
              <div
                className={`time_slot inline-shadow ${slot.available === 0 ? 'no_tab' : 'tab_in'} ${
                  this.props.site_info.drop_shadows ? 'drop-shadow' : null
                }`}
                style={{
                  border: this.props.site_info.border_on_items ? '1px solid black' : null,
                  backgroundColor:
                    slot.available === 0
                      ? '#dddddd'
                      : slot.available === 1
                      ? this.props.selected_slot === slot.slot
                        ? this.props.site_info.slot_active
                        : this.props.site_info.show_only_few_slots_left ? this.props.site_info.slot_some_appointments_left_background : this.props.site_info.slot_appointments_available_background
                      : this.props.selected_slot === slot.slot
                        ? this.props.site_info.slot_active
                        : this.props.site_info
                          .slot_appointments_available_background,
                  color:
                    slot.available === 0
                      ? '#969696'
                      : slot.available === 1
                      ? this.props.selected_slot === slot.slot
                        ? this.props.site_info.slot_text_active
                        : this.props.site_info.show_only_few_slots_left ? this.props.site_info.slot_some_appointments_left_text : this.props.site_info.slot_text
                      : this.props.selected_slot === slot.slot
                        ? this.props.site_info.slot_text_active
                        : this.props.site_info
                          .slot_text,
                  textDecoration:
                    this.props.selected_slot === slot.slot ? 'underline' : 'none'
                }}>
                <span className="no_tab">{slot.time_of_slot}</span>
              </div>
            </div>
          </Fragment>
        );
      }
    });
  }
  render() {
    const modalCustomStyles = {
      content : {
        top                   : '45%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        width                 : '50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 110
      },
    };
    const {intl} = this.props
    return (
      <Fragment>
        <div
          className={`container animated fadeIn ${
            this.props.site_info.drop_shadows ? 'drop-shadow' : null
          }`} style={embedded_mode ? {marginTop: '15px'} : null}>
          <Helmet>
            <style>{`
            .issues_header_left, .slot_picker_header_left, .data_forms_header_left{
              background-color: ${this.props.site_info.heading_background};
              color: ${this.props.site_info.heading_text};
              font-size: 1.1em;
              font-weight: 600;
              padding: 8px;
              border: 1px solid #CBCBCBFF;
              border-bottom: none;
              border-radius: 5px 5px 0px 0px;
            }
          `}</style>
          </Helmet>
          <div className="row">
            <div className="col-sm-12 slot_picker_header_left">
              <div className="col-sm-8" style={{ marginLeft: '-15px' }} id="scroll_target_slot_picker">
                <span>{intl.formatMessage({id: "slotpicker.header"})}</span>
              </div>
              <div className="col-sm-4 slot_picker_header_right">
                <span>{moment(this.props.selected_day).format('dddd, DD. MMM YYYY')}</span>
              </div>
            </div>
          </div>
          <div
            className="row inline-shadow"
            style={{
              backgroundColor: this.props.site_info.content_background,
              color: this.props.site_info.content_text
            }}>
            {this.props.slots.time_slots.length > 0 ? (
              this.renderSlots()
            ) : (
              <div className="row">
                <div className="col-xs-12">
                  <h4 style={{ textAlign: 'center', padding: '30px' }}>
                    {intl.formatMessage({id: "slotpicker.no_appointments"})}
                  </h4>
                </div>
              </div>
            )}
          </div>
          <div
            className="row legende"
            style={{
              backgroundColor: this.props.site_info.content_background,
              color: this.props.site_info.content_text
            }}>
            <div
              className="twelve columns"
              style={{ marginRight: '5px', marginTop: '15px'}}>
              <p className="pull-right mobile-bottm-margin" style={{ marginRight: '10px' }}>
                {intl.formatMessage({id: "slotpicker.legend_green"})}{' '}
                <span
                  style={{
                    border: this.props.site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF',
                    color: this.props.site_info
                      .slot_appointments_available_background,
                    backgroundColor: this.props.site_info
                      .slot_appointments_available_background
                  }}>
                -------
              </span>
              </p>
              {this.props.site_info.show_only_few_slots_left ?
                <p className="pull-right mobile-bottm-margin" style={{ marginRight: '10px' }}>
                  {intl.formatMessage({id: "slotpicker.legend_yellow"})}{' '}
                  <span
                    style={{
                      border: this.props.site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF',
                      color: this.props.site_info
                        .slot_some_appointments_left_background,
                      backgroundColor: this.props.site_info
                        .slot_some_appointments_left_background
                    }}>
                -------
              </span>
                </p> : null
              }
              <p className="pull-right mobile-bottm-margin" style={{ marginRight: '10px' }}>
                {intl.formatMessage({id: "slotpicker.legend_grey"})}{' '}
                <span
                  style={{
                    border: this.props.site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF',
                    color: '#D6D6D6',
                    backgroundColor: '#D6D6D6'
                  }}>
                -------
              </span>{' '}
              </p>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.fetching}
          ariaHideApp={false}
          style={modalCustomStyles}
        >
          <BarLoader
            sizeUnit={'px'}
            height={30}
            width={'100%'}
            color={this.props.site_info.loading_indicator}
            loading={true}
          />
        </Modal>
        <Modal
          isOpen={this.state.show_error_modal}
          onRequestClose={this.closeErrorModal.bind(this)}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          style={modalCustomStyles}
        >
          <span style={{fontWeight: 'bold', color: 'red', fontSize: '1.6rem'}}>{this.state.modal_error_message}</span><br/>

          <button
            className={`btn ${
              this.props.site_info.drop_shadows ? 'drop-shadow' : null
            }`}
            onClick={this.closeErrorModal}
            style={{
              marginTop: '10px',
              background: this.props.site_info.confirm_button_background,
              color: this.props.site_info.confirm_button_text,
              border: '1px solid #CBCBCBFF'
            }}>
            {intl.formatMessage({id: 'appointment_times.session.close_error_modal'})}
          </button>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    services: state.service_list,
    days: state.days,
    slots: state.slots,
    selected_slot: state.user_selected_slot,
    selected_day: state.user_selected_day,
    selected_service: state.user_selected_service,
    subtask_items: state.user_count_persons,
    selected_subtask: state.user_selected_subtask,
    user_person_count: state.user_count_persons,
    no_issues: state.no_issues,
    ui: state.ui,
    appointment_times: state.appointment_time
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(SlotPicker)
);

const convert_selected_subtasks = (subtask_items) => {
  const booked_subtasks = subtask_items.map((item) => {
    return {
      subtask_id: item.subtask_id,
      number: item.number,
    }
  })

  return booked_subtasks
}
