export const LOCALES = {
  ENGLISH: 'en',
  GERMAN: 'de',
  DUTCH: 'da',
  FRENCH: 'fr',
  GREEK: 'el',
  RUSSIAN: 'ru-RU',
  LUXEMBURG: 'lb-LU',
  UKRAINIAN: 'uk-UA',
  NORWEGIAN: 'nb-NO',
  SWEDISH: 'sv-SE',
  PERSIAN: 'fa',
  ARABIC: 'ar-SA',
  TURKISH: 'tr-TR'
}