import { FETCH_APPOINTMENT_SLOTS } from '../actions/types';

const initialState = {
  time_slots: [],
  duration: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_APPOINTMENT_SLOTS:
      let obj = {
        appointment_slots_per_hour:
          action.payload.data.appointment_slots_per_hour,
        time_slots: action.payload.data.available_time_slots,
        duration: action.payload.data.duration
      };
      // Take the existing state and concatenate to the new list of days
      return obj;
    default:
      return state;
  }
}
