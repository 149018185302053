import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Modal, Table } from 'react-bootstrap';
import { ScaleLoader } from 'react-spinners';
import Qrcode from 'qrcode.react';
import moment from 'moment-timezone';
import ReactToPrint from 'react-to-print';
import styled from "styled-components";
import classNames from 'classnames';
import styles from '../../styles/confirmation.module.css';

const query = new URLSearchParams(window.location.search);

class AppointmentInfo extends Component {
  state = {
    show: true
  };
  handleClose() {
    this.setState({ show: false });
  }
  handleShow() {
    this.setState({ show: true });
  }
  backToCustomer(url) {
    if (url !== 'reload') {
      //navigateHome()
      return (window.location.href = url);
    } else {
      return window.location.reload();
    }
  }
  print() {
    if (query.get('cleverq_printer_mode') === 'true') {
      createTicketCleverQAppointment(this.props.booking, this.props.site_info, this.props.user_person_count, this.props.translate)
    } else {
      if (query.get('cleverq_printer_mode') === 'true') {
        createTicketCleverQAppointment(this.props.booking, this.props.site_info, this.props.user_person_count, this.props.translate)
      } else {
        window.print();
      }
    }
  }
  createMarkup(markup) {
    return { __html: markup };
  }
  render() {
    const {intl} = this.props
    const Button = styled.button`
      background: ${this.props.site_info.button_background};
      color: ${this.props.site_info.button_text};
      text-align: center;
      font-size: 1.1em;
      padding: 13px;
      &:hover {
        background-color: ${this.props.site_info.button_hover} !important;
        color: ${this.props.site_info.button_text_hover} !important;
        transition: 500ms;
      }
    `;
    let root = document.documentElement
    root.style.setProperty('--label-background-color', this.props.site_info.button_background === '#ffffff' ? this.props.site_info.heading_background : this.props.site_info.button_background )
    root.style.setProperty('--label-text-color', this.props.site_info.button_background === '#ffffff' ? this.props.site_info.heading_text : this.props.site_info.button_text)
    let user_infos = this.props.booking.info_fields;
    return (
     <div>
        <Modal
          style={{paddingLeft: 0}}
          show={this.state.show}
          onHide={this.handleClose.bind(this)}
          bsSize="large"
          aria-labelledby="contained-modal-title-lg">
          <Modal.Header className="no_tab" style={{background: this.props.site_info.heading_background, color: this.props.site_info.heading_text}}>
            <Modal.Title className="no_tab" id="contained-modal-title-lg">
              {this.props.booking.status === 'needs_user_confirmation'
                ? intl.formatMessage({id: 'appointment_info.title_reserved'})
                : this.props.booking.status ===
                  'on_site_confirmation_pending'
                  ? intl.formatMessage({id: 'appointment_info.title_on_site_reserved'})
                  : this.props.booking.status === 'needs_confirmation'
                    ? intl.formatMessage({id: 'appointment_info.title_desk_reserved'})
                    : intl.formatMessage({id: 'appointment_info.title'})}
            </Modal.Title>
          </Modal.Header>
          {this.props.booking ? (
            <Modal.Body>
              {this.props.booking.has_fixed_follow_up ?
                <div className="row">
                  <div className="col-sm-3">
                    {this.props.booking.status !==
                    ('needs_user_confirmation' || 'needs_confirmation') ? (
                      <div className="no_tab" style={{float: 'left'}}>
                        {intl.formatMessage({id: 'appointment_info.reservation_code'})}
                        <h1 style={{ color: '#c43131' }}>
                          {this.props.booking.reservation_code}
                        </h1>
                        {!this.props.site_info.hide_qr_code ?
                          <Qrcode
                            value={this.props.booking.qr_code}
                          /> : null
                        }
                      </div>
                    ) : (
                      <h2 style={{ color: '#c43131' }}>&nbsp;</h2>
                    )}
                  </div>
                  <div className="col-sm-6">
                    {this.props.site_info.custom_confirmation_text ?
                      <div className="no_tab" dangerouslySetInnerHTML={this.createMarkup(this.props.site_info.custom_confirmation_text)} /> : null
                    }
                    {this.props.booking.status ===
                    'needs_user_confirmation' ? (
                      <div dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.confirmtext', achtung: <span style="color: #FF0000;"><h4>ACHTUNG</h4></span>}))} />
                    ) : null}
                    {this.props.booking.status ===
                    'on_site_confirmation_pending' ? (
                      <div dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.on_site_confirmtext'}))}/>
                    ) : null}
                    {this.props.booking.status === 'needs_confirmation' ? (
                      <div dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.desk_confirmtext'}))}/>
                    ) : null}
                    {this.props.site_info.show_app_links && this.props.booking.status !==
                    ('needs_user_confirmation' || 'needs_confirmation') ? (
                       <div dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.infotext'}))}/>
                    ) : null}
                  </div>
                  <div className="col-sm-3">
                    {this.props.booking.status !==
                    ('needs_user_confirmation' || 'needs_confirmation') ? (
                      <div style={{float: 'right'}}>
                        {intl.formatMessage({id: 'appointment_info.reservation_code'})}
                        <h1 style={{ color: '#c43131' }}>
                          {this.props.booking.next_reservation_code}
                        </h1>
                        {!this.props.site_info.hide_qr_code ?
                          <Qrcode
                            value={this.props.booking.next_qr_code}
                          /> : null
                        }
                      </div>
                    ) : (
                      <h2 style={{ color: '#c43131' }}>&nbsp;</h2>
                    )}
                  </div>
                </div> :
                <div className="row">
                  <div className="col-sm-8">
                    {this.props.site_info.custom_confirmation_text ?
                      <h4 className="no_tab" dangerouslySetInnerHTML={this.createMarkup(this.props.site_info.custom_confirmation_text)} ></h4> : null
                    }
                    {this.props.booking.status ===
                    'needs_user_confirmation' ? (
                        <h4 className="no_tab" dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.confirmtext'}))}></h4>
                    ) : null}
                    {this.props.booking.status ===
                    'on_site_confirmation_pending' ? (
                      <h4 className="no_tab" dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.on_site_confirmtext'}))}></h4>
                    ) : null}
                    {this.props.booking.status === 'needs_confirmation' ? (
                      <h4 className="no_tab" dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.desk_confirmtext'}))}></h4>
                    ) : null}
                    {this.props.site_info.show_app_links && this.props.booking.status !==
                    ('needs_user_confirmation' || 'needs_confirmation') ? (
                      <h4 className="no_tab" dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.infotext'}))}></h4>
                    ) : null}
                  </div>
                  <div className="col-sm-1"/>
                  <div className="col-sm-3">
                    {this.props.booking.status !==
                    ('needs_user_confirmation' || 'needs_confirmation') ? (
                      <div style={{float: 'right'}}>
                        {intl.formatMessage({id: 'appointment_info.reservation_code'})}
                        <h1 style={{ color: '#c43131' }}>
                          {this.props.booking.reservation_code}
                        </h1>
                        {!this.props.site_info.hide_qr_code ?
                          <Qrcode
                            value={this.props.booking.qr_code}
                          /> : null
                        }
                      </div>
                    ) : (
                      <h2 style={{ color: '#c43131' }}>&nbsp;</h2>
                    )}
                  </div>
                </div>}
              <hr />
              <h4 className="no_tab">{intl.formatMessage({id: 'appointment_info.summary'})}</h4>
              <div className={classNames(styles.summary_container, styles.drop_shadow)}>
                <Table className="no_tab">
                  <tbody>
                  <tr>
                    <td className={styles.label} style={{textAlign: 'center', fontSize: '1.2em'}}>
                      {intl.formatMessage({id: 'appointment_info.appointment'})}{' '}
                      <div className={styles.summary_icon} style={{marginRight: '17px'}}>
                        <i className="fas fa-calendar-alt" style={{color: 'var(--neutral-color'}}></i>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.summary_value} style={{textAlign: 'center', fontSize: '1.3em'}}>
                      {intl.formatMessage({id: 'dataforms.on'})}{' '}
                      {moment(this.props.booking.start)
                        .tz('Europe/Copenhagen')
                        .format('DD.MM.YYYY')}{' '}
                      {intl.formatMessage({id: 'dataforms.appointment_around'})}{' '}
                      {moment(this.props.booking.start)
                        .tz('Europe/Copenhagen')
                        .format('HH:mm')}{' '}
                      {intl.formatMessage({id: 'dataforms.clock'})}{' '}
                    </td>
                  </tr>
                  {this.props.booking.has_fixed_follow_up ?
                    <tr>
                      <td className={styles.summary_value} style={{textAlign: 'center', fontSize: '1.3em'}}>
                        {intl.formatMessage({id: 'dataforms.on'})}{' '}
                        {moment(this.props.booking.next_start)
                          .tz('Europe/Copenhagen')
                          .format('DD.MM.YYYY')}{' '}
                        {intl.formatMessage({id: 'dataforms.appointment_around'})}{' '}
                        {moment(this.props.booking.next_start)
                          .tz('Europe/Copenhagen')
                          .format('HH:mm')}{' '}
                        {intl.formatMessage({id: 'dataforms.clock'})}{' '}
                      </td>
                    </tr> : null}
                  <tr>
                    <td className={styles.summary_value} style={{textAlign: 'center', fontSize: '1.3em'}}>
                      {intl.formatMessage({id: 'appointment_info.at'})}{' '}
                      {`${this.props.site_info.zip_code} ${this.props.site_info.city}, ${this.props.site_info.address}`}
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </div>
              <div className={classNames(styles.summary_container, styles.drop_shadow)}>
                <div className="row">
                  <div className="col-sm-6">
                    <table className="no_tab">
                      <tbody>
                      {this.props.booking.info_fields.length ? this.props.booking.info_fields.map((item) => (
                        item.type !== 'text_area' ?
                          <tr key={item.label}>
                            <td className={styles.label}>
                              {item.label}{' '}
                              <div className={styles.summary_icon}>{iconWrapper(item.type)}</div>
                            </td>
                            <td className={classNames(styles.summary_value, styles.drop_shadow)}>
                              {item.type === 'check_box' ? item.value === 'true' ? <i className="fas fa-check"></i> : <i class="fas fa-times"></i> : item.value }
                            </td>
                          </tr> : null
                      )) : null}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-6">
                    <table className="no_tab">
                      <tbody>
                      <Fragment>
                        <tr>
                          <td colSpan={2} className={styles.label}>
                            {intl.formatMessage({id: 'appointment_info.issue'})}{' '}
                            <div className={styles.summary_icon} style={{marginRight: '17px'}}>
                              <i className="fas fa-folder" style={{color: 'var(--neutral-color'}}></i>
                            </div>
                          </td>
                        </tr>
                        {this.props.booking.subtask_items ? (
                          this.props.user_person_count.map(item => {
                            if (item.number > 0) {
                              return (
                                <tr key={item.subtask_id}>
                                  <td className={classNames(styles.summary_value, styles.drop_shadow)}>
                                    <div dangerouslySetInnerHTML={this.createMarkup(item.name)}></div>
                                    {' '}
                                  </td>
                                  <td className={classNames(styles.summary_value, styles.drop_shadow)} style={{textAlign: 'center', width: '50px'}}>
                                    {item.number}
                                  </td>
                                </tr>
                              );
                            } else {
                              return null;
                            }
                          })
                        ) : (
                          <tr key={this.props.booking.id}>
                            <td className={classNames(styles.summary_value, styles.drop_shadow)}>
                              {this.props.booking.service_name}
                            </td>
                          </tr>
                        )}
                      </Fragment>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className={classNames(styles.summary_container, styles.drop_shadow)}>
                <div className="row">
                  <div className="col-sm-12">
                    <table className="no_tab">
                      <tbody>
                      {this.props.booking.info_fields.length ? this.props.booking.info_fields.map((item) => (
                        item.type === 'text_area' ?
                          <tr key={item.label}>
                            <td className={styles.label} style={{width: '170px'}}>
                              {item.label}{' '}
                              <div className={styles.summary_icon}>{iconWrapper(item.type)}</div>
                            </td>
                            <td className={classNames(styles.summary_value, styles.drop_shadow)}>
                              {item.value}
                            </td>
                          </tr> : null
                      )) : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {this.props.booking.status !==
              ('needs_user_confirmation' || 'needs_confirmation') ? (
                <div>
                  <Button
                    id="btnBack"
                    className={this.props.site_info.drop_shadows ? 'hide-on-print-page hidden-print drop-shadow' : 'hide-on-print-page hidden-print'}
                    style={{border: this.props.site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF'}}
                    onClick={() =>
                      this.backToCustomer(
                        !this.props.session_info.isUser
                          ? this.props.site_info.customer_url
                          : 'reload'
                      ).bind(this)
                    }>
                    <i className="fas fa-chevron-right"></i>&nbsp;&nbsp;{intl.formatMessage({id: 'appointment_info.back_button'})}
                  </Button>
                  {
                    this.state.browserKOKE ? <Button
                      id="btnPrint"
                      className={this.props.site_info.drop_shadows ? 'drop-shadow' : null}
                      style={{marginLeft: '10px', border: this.props.site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF'}}
                      onClick={() => this.print}>
                      <i className="fas fa-print"></i>&nbsp;&nbsp;{intl.formatMessage({id: 'appointment_info.print_button'})}
                    </Button> :
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            id="btnPrint"
                            className={this.props.site_info.drop_shadows ? 'drop-shadow' : null}
                            style={{marginLeft: '10px', border: this.props.site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF'}}
                            onClick={this.print}>
                            <i className="fas fa-print"></i>&nbsp;&nbsp;{intl.formatMessage({id: 'appointment_info.print_button'})}
                          </Button>
                        )}
                        content={() => this.componentRef}
                      />
                  }

                  <div style={{ display: 'none' }}>
                    <AppointmentPrint
                      ref={el => (this.componentRef = el)}
                      booking={this.props.booking}
                      site_info={this.props.site_info}
                      user_person_count={this.props.user_person_count}
                      intl={this.props.intl}
                      selected_service={this.props.selected_service}
                    />
                  </div>
                </div>
              ) : <div>
                <Button
                  id="back_button"
                  className={this.props.site_info.drop_shadows ? 'hide-on-print-page hidden-print drop-shadow' : 'hide-on-print-page hidden-print'}
                  style={{border: this.props.site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF'}}
                  onClick={() =>
                    this.backToCustomer(
                      this.props.site_info.customer_url
                    ).bind(this)
                  }>
                  <i className="fas fa-chevron-right"></i>&nbsp;&nbsp;{intl.formatMessage({id: 'appointment_info.back_button'})}
                </Button>
              </div>}
              <hr />
              {this.props.site_info.show_app_links ? (
                  <div>
                    <div dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.advertising'}))}/>
                    <img
                        className="advertising-image hide-on-print-page hidden-print"
                        src={intl.formatMessage({id: 'appointment_info.advertising_image'})}
                        alt="smartphone"
                    />
                  </div>
              ) : null}
            </Modal.Body>
          ) : (
            <div style={{ marginLeft: '20px' }}>
              <ScaleLoader
                sizeUnit={'px'}
                size={300}
                color={this.props.site_info.loading_indicator}
                loading={true}
              />
            </div>
          )}
          <Modal.Footer>
            {this.props.site_info.show_app_links ? (
              <div className="hide-on-print-page hidden-print">
                <a
                  href="https://play.google.com/store/apps/details?id=de.com.bic.gowait"
                  className="href">
                  <img
                    src="/images/cleverq-appstore.png"
                    alt="AppleStore Store"
                    style={{
                      width: '100px',
                      marginRight: '10px',
                      marginTop: '-5px'
                    }}
                  />
                </a>
                <a
                  href="https://itunes.apple.com/de/app/cleverq/id1111920758?mt=8"
                  className="href">
                  <img
                    src="/images/cleverq-fuer-android.png"
                    alt="Android Store"
                    style={{
                      width: '100px',
                      marginRight: '10px',
                      marginTop: '-5px'
                    }}
                  />
                </a>
              </div>
            ) : null}
          </Modal.Footer>
        </Modal>
     </div>
    );
  }
}

class AppointmentPrint extends Component {
  createMarkup(markup) {
    return { __html: markup };
  }
  render() {
    let service_email_confirm_message = this.props.site_info.services.find(service => service.id === this.props.selected_service)?.email_confirm_message;
    let user_infos = this.props.booking.info_fields;
    const {intl} = this.props
    return (
      <div className="container">
        <h2>{intl.formatMessage({id: 'appointment_info.header'})}</h2>
        <h2>{(this.props.site_info.custom_attachment_text || service_email_confirm_message) ?
            service_email_confirm_message ? service_email_confirm_message : this.props.site_info.custom_attachment_text : null}</h2>
        <Table>
          <tbody>
            <tr>
              <td>{intl.formatMessage({id: 'appointment_info.appointment'})}</td>
              <td>
                {this.props.site_info.location_text_in_summary}{' '}
                {moment(this.props.booking.start)
                  .tz('Europe/Copenhagen')
                  .format('DD.MM.YYYY')}{' '}
                {intl.formatMessage({id: 'dataforms.appointment_around'})}{' '}
                {moment(this.props.booking.start)
                  .tz('Europe/Copenhagen')
                  .format('HH:mm')}{' '}
                {intl.formatMessage({id: 'dataforms.clock'})}{' '}
                {this.props.booking.has_fixed_follow_up ? intl.formatMessage({id: 'dataforms.appointment_around'}) : null}{this.props.booking.has_fixed_follow_up ? ' ' : null}
                {this.props.booking.has_fixed_follow_up ? moment(this.props.booking.next_start)
                  .tz('Europe/Copenhagen')
                  .format('HH:mm') : null}{this.props.booking.has_fixed_follow_up ? ' ' : null}
                {this.props.booking.has_fixed_follow_up ? intl.formatMessage({id: 'dataforms.clock'}) : null}{this.props.booking.has_fixed_follow_up ? ' ' : null}
                {intl.formatMessage({id: 'dataforms.appointment_for_only'})}
                <ul className="list-group" style={{marginTop: '10px'}}>
                  {this.props.booking.subtask_items ? (
                    this.props.user_person_count.map(item => {
                      if (item.number > 0) {
                        return (
                          <li
                            key={item.subtask_id}
                            className="list-group-item d-flex justify-content-between align-items-center">
                            <span dangerouslySetInnerHTML={this.createMarkup(item.name)}/>
                            <span className="badge badge-primary badge-pill">{item.number}</span>
                          </li>
                        );
                      } else {
                        return null;
                      }
                    })
                  ) : (
                    <li
                      key={this.props.booking.id}
                      className="list-group-item d-flex justify-content-between align-items-center">
                      {this.props.booking.service_name}
                    </li>
                  )}
                </ul>
              </td>
            </tr>
            <tr>
              <td>{intl.formatMessage({id: 'appointment_info.location'})}</td>
              <td>{`${this.props.site_info.zip_code} ${this.props.site_info.city}, ${this.props.site_info.address}`}</td>
            </tr>
            {this.props.booking.info_fields.map((info, i) => {
              return (
                <tr key={i}>
                  <td style={{minWidth: '140px'}}>{info.label}</td>
                  <td>{info.value}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {this.props.booking.has_fixed_follow_up ?
          <div className="row">
            <div className="col-md-6 pull-left">
              <span>{intl.formatMessage({id: 'appointment_info.reservation_code'})}</span>
              <h1 style={{ color: '#c43131' }}>
                {this.props.booking.reservation_code}
              </h1>
              {this.props.site_info.show_app_links && !this.props.site_info.hide_qr_code ? (
                <Qrcode
                  value={`cleverq://appointment/site/${
                    this.props.booking.site_id
                  }/code/${this.props.booking.reservation_code}`}
                />
              ) : null}
            </div>
            <div className="col-md-6 pull-right">
              {intl.formatMessage({id: 'appointment_info.reservation_code'})}
              <h1 style={{ color: '#c43131' }}>
                {this.props.booking.next_reservation_code}
              </h1>
              {this.props.site_info.show_app_links && !this.props.site_info.hide_qr_code ? (
                <Qrcode
                  value={`cleverq://appointment/site/${
                    this.props.booking.site_id
                  }/code/${this.props.booking.next_reservation_code}`}
                />
              ) : null}
            </div>
          </div> :
          <div>
            {intl.formatMessage({id: 'appointment_info.reservation_code'})}
            <h1 style={{ color: '#c43131' }}>
              {this.props.booking.reservation_code}
            </h1>
            {this.props.site_info.show_app_links && !this.props.site_info.hide_qr_code ? (
              <Qrcode
                value={`cleverq://appointment/site/${
                  this.props.booking.site_id
                }/code/${this.props.booking.reservation_code}`}
              />
            ) : null}
          </div>}
      </div>
    );
  }
}

const iconWrapper = (type) => {
  switch (type) {
    case 'text':
      return  <i className="fas fa-align-left" style={{color: 'var(--neutral-color'}}></i>
      break
    case 'number':
      return  <i className="fas fa-sort-numeric-up" style={{color: 'var(--neutral-color'}}></i>
      break
    case 'text_area':
      return  <i className="fas fa-align-left" style={{color: 'var(--neutral-color'}}></i>
      break
    case 'options':
      return  <i className="fas fa-mouse-pointer" style={{color: 'var(--neutral-color'}}></i>
      break;
    case 'check_box':
      return <i className="fas fa-mouse-pointer" style={{color: 'var(--neutral-color'}}></i>
      break
    case 'honorific_prefix':
      return <i className="fas fa-user" style={{color: 'var(--neutral-color'}}></i>
      break
    case 'name':
      return <i className="fas fa-user" style={{color: 'var(--neutral-color'}}></i>
      break
    case 'given_name':
      return <i className="fas fa-user" style={{color: 'var(--neutral-color'}}></i>
      break
    case 'family_name':
      return <i className="fas fa-user" style={{color: 'var(--neutral-color'}}></i>
      Object.assign(initialValues, { [id]: '' })
      break
    case 'email':
      return <i className="fas fa-envelope" style={{color: 'var(--neutral-color'}}></i>
      break
    case 'tel':
      return <i className="fas fa-phone" style={{color: 'var(--neutral-color'}}></i>
      break
    case 'bday':
      return <i className="fas fa-user" style={{color: 'var(--neutral-color'}}></i>
      break
    case 'street_address':
      return <i className="fas fa-address-card" style={{color: 'var(--neutral-color'}}></i>
      break
    case 'postal_code':
      return <i className="fas fa-address-card" style={{color: 'var(--neutral-color'}}></i>
      break
    case 'city':
      return <i className="fas fa-address-card" style={{color: 'var(--neutral-color'}}></i>
      break
    case 'country_name':
      return <i className="fas fa-address-card" style={{color: 'var(--neutral-color'}}></i>
      break
    case 'customer_card_number':
      return <i className="fas fa-globe" style={{color: 'var(--neutral-color'}}></i>
      break
    default:
      return null
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    booking: state.booking,
    selected_service: state.user_selected_service,
    selected_service_name: state.user_selected_service_name,
    selected_day: state.user_selected_day,
    selected_slot: state.user_selected_slot,
    user_person_count: state.user_count_persons,
    session_info: state.session_info
  };
}

connect(mapStateToProps)(AppointmentPrint);
export default injectIntl(connect(mapStateToProps)(AppointmentInfo));
