import {
  USER_RESET_SERVICE,
  USER_SELECTED_SERVICE_NAME
} from '../actions/types';

export default function(state = [], action) {
  switch (action.type) {
    case USER_SELECTED_SERVICE_NAME:
      // Take the existing state and concatenate to the new list of days
      return action.payload;
    case USER_RESET_SERVICE:
      // Take the existing state and concatenate to the new list of days
      return [];
    default:
      return state;
  }
}
