import { LOCALES } from '../locales'

export default {
    [LOCALES.RUSSIAN]: {
        "issues": {
            "browser_header": "Забронируйте визит в интернете онлайн",
            "what_is_cleverq": "Что такое cleverQ?",
            "additional_issues": "выберите дополнительные запросы "
        },
        "app": {
            "header": "Что такое cleverQ?",
            "important": "актуальная информация"
        },
        "persons": {
            "header": "Введите на сколько человек вы хотите забронировать визит",
            "information": "<b style={{ color: 'red' }}>Обратите пожалуйста внимание!</b> Вы можете выбрать только один визит. Даже если вы просто хотите отдать резюме или же взять разрешение на вьезд. Не смотря на то вы можете забронировать только один визит,вам ответят в обоих случаях.",
            "continue": "дальше к выбору визита",
            "remove": "удалить",
            "to_much_time": "количество запросов и процессов превышает разрешенные по времени визиты. Пожалуйста уменьшите ваш выбор."
        },
        "datepicker": {
            "header": "Пожалуйста выберите желаемый день",
            "mon": "Понедельник",
            "tue": "Вторник",
            "wed": "Среда",
            "thu": "Четверг",
            "fri": "Пятница",
            "sat": "Суббота",
            "sun": "Воскресенье",
            "no_appointments": "На ваш выбор к сожалению все визиты забронированны. Пожалуйста попробуйте позже еще раз.",
            "error": "Возникла проблема при запросе на посещение, пожалуйста попробуйте позже еще раз."
        },
        "slotpicker": {
            "header": "Пожалуйста выберите нужное время.",
            "no_appointments": "К сожалению в этот день нет посещений в наличие",
            "legend_green": "Посещения доступны",
            "legend_yellow": "Не большое количество посещений доступно",
            "legend_grey": "Посещения не доступны",
            "timeout_reached": "Соеденение с сервером было прервано, попробуйте позже еще раз"
        },
        "dataforms": {
            "ident_number": "Customer number",
            "ident_number_text": "Please enter your customer number so that you can confirm your appointment on site with your customer card.",
            "mandatory_fields": "Обязательные поля",
            "header": "Введите пожалуйста ваши данные",
            "data_delete": "Ваши данные будут автоматически удалены после вашего визита",
            "ms": "Женщина ",
            "mr": "Мужчина",
            "salutation": "Обращение",
            "company": "Название фирмы",
            "given_name": "Имя",
            "surname": "Фамилия",
            "birthday": "День рождения | Формат: дд.мм.гггг.",
            "email": "Адрес электронной почты",
            "email_confirmation": "Повторите адрес электронной почты",
            "email_optional": "Не обязательный адрес электронной почты",
            "email_confirmation_optional": "Повторите не обязательный адрес электронной почты",
            "summary": "Обобщение",
            "phone": "Не обязательный номер телефона",
            "tel_information": " ",
            "appointment_for": " ",
            "appointment_around": " В",
            "appointment_for_only": "Для ",
            "on": "На",
            "clock": "Время",
            "message": "Не обязательное сообщение к вашему визиту",
            "book_appointment": "Забронировать обязательное посещение",
            "book_appointment_not_valid": "Пожалуйста заполните отмеченные обязательные поля",
            "book_appointment_started": "Ваше посещение бронируеться, пожалуйста подождите...",
            "book_appointment_failed": "Бронирование визита не удалось, пожалуйста нажмите еще раз",
            "terms_and_conditions": "Конфиденциальность",
            "sms_notifications_label": "SMS-уведомления",
            "sms_notifications_checkbox_text": "Я хотел бы получать информацию о назначенном мне приеме через SMS-уведомления.",
            "sms_notifications_text": "Мы не будем звонить вам, и номер вашего мобильного телефона не будет использоваться для каких-либо других целей.",
            "back": "Назад",
            "cancel": "Отмена",
            "close_privacy_policy": "Закрыть страницу о конфиденциальности",
            "terms_and_conditions_text_for_confirm": "Используя онлайн сервис для бронирования визитов, вы соглащаетесь, что указынные вами личные данные будут нами обработаны. Все личные данные сохраняються только к использованию по назначению.",
            "terms_and_conditions_link": "Ссылка на соблюдения конфидиальности",
            "email_info": "Чтобы отправлять вам подтверждения о назначение визитов,а так же отправлять требуемые документы через электронную почту, нам требуеться  адрес вашей электронной почты. Если вы его не внесете, использование соглащения на назначения посещений, невозможно.",
            "confirm_policy": "Please confirm the privacy policy",
            "policy": "I have read the data protection information and agree to my data being processed.",
            "show_policy": "Show privacy policy"
        },
        "dataforms_waiting_list_entry": {
            "header": "Регистрация на лист ожидания",
            "waiting_list_entry_not_valid": "Пожалуйста заполните все выделенные обязательные поля",
            "waiting_list_entry": "Не обязывающе зарегистрироваться",
            "waiting_list_entry_started": "Регистрация на лист ожидания выполняеться, пожалуйста подождите....",
            "go_to_waiting_list_entry": "Дальше на регистрацию листа ожидания",
            "successfull_registered": "Мы получили вашу регистрацию. Чтобы она действовала, нажмите пожалуйста на ссылку в почтовом ящике, которую мы вам отправили.",
            "register_waiting_list_entry": "Запросить регистрацию"
        },
        "forms": {
            "bday": {
                "day": "День",
                "month": "Месяц",
                "year": "Год"
            },
            "email_confirm": "повторить",
            "honorific_prefix": {
                "mister": "Женщина",
                "miss": "Мужчина",
                "other": "Различные"
            },
            "validations": {
                "default": {
                    "required": "Эти данные нужны",
                    "min": "Пожалуйста введите два значка",
                    "max": "Много значков",
                    "letter_only": "На этом поле разрешены только буквы",
                    "numbers_only": "На этом поле разрешены только цифры"
                },
                "number": {
                    "min": "Пожалуйста введите цифру больше нуля",
                    "max": "Пожалуйста введите цифру меньше 1000000"
                },
                "email": {
                    "required": "Пожалуйста введите ваш электронный адрес",
                    "not_matching": "Адрес элекронной почты не везде совпадает",
                    "not_a_valid_email": "Введите действительный адрес электронной почты"
                },
                "options": {
                    "required": "Пожалуйста выберите опцию"
                },
                "name": {
                    "required": "Пожалуйста введите вашу фамилию"
                },
                "given_name": {
                    "required": "Пожалуйста введите ваше имя"
                },
                "family_name": {
                    "required": "Пожалуйста введите вашу фамилию"
                },
                "honorific_prefix": {
                    "required": "Пожалуйста введите обращение"
                },
                "street_address": {
                    "required": "Пожалуйста введите улицу вашего проживания"
                },
                "postal_code": {
                    "required": "Пожалуйста введите почтовый индекс"
                },
                "city": {
                    "required": "Пожалуйста введите место проживания"
                },
                "country_name": {
                    "required": "Пожалуйста введите страну происхождения"
                },
                "bday": {
                    "day": {
                        "required": "Пожалуйста введите календарный день вашего рождения",
                        "type_error": "Пожалуйста введите число от 1 до 31",
                        "min": "Это не действительный календарный день",
                        "max": "Это не действительный календарный день"
                    },
                    "month": {
                        "required": "Пожалуйста введите календарный месяц вашего рождения",
                        "type_error": "Пожалуйста введите число от 1 до 12",
                        "min": "Это не действительный календарный месяц",
                        "max": "Это не действительный календарный месяц"
                    },
                    "year": {
                        "required": "Пожалуйста введите календарный год вашего рождения",
                        "type_error": "Пожалуйста введите число между 1880 и ${year}.",
                        "min": "Пожалуйста введите число между 1880 и ${year}.",
                        "max": "Пожалуйста введите число между 1880 и ${year}."
                    }
                },
                "card": {
                    "bg": {
                        "required": "Пожалуйста, введите левую часть номера BG.",
                        "type_error": "Пожалуйста, введите значение из 5 цифр.",
                        "min": "Это недопустимое значение для левой части вашего номера BG.",
                        "max": "Это недопустимое значение для левой части вашего номера BG."
                    },
                    "bg_number": {
                        "required": "Пожалуйста, введите правую часть номера BG.",
                        "type_error": "Пожалуйста, введите значение из 7 цифр для правой части вашего номера BG.",
                        "min": "Это недопустимое значение для правой части вашего номера BG.",
                        "max": "Это недопустимое значение для правой части вашего номера BG."
                    }
                },
                "sex": {
                    "required": "Пожалуйста введите ваш пол"
                },
                "tel": {
                    "required": "Пожалуйста введите ваш номер телефона",
                    "not_valid": "Пожалуйста введите ваш действенный номер телефона"
                },
                "terms_and_conditions": "Пожалуйста подтвердите наши условия о конфиденциальности",
                "ident_number.required": "Please enter your customer number."
            }
        },
        "appointment_info": {
            "at": "В",
            "location": "Местонахождение",
            "header": "К вашей записи на приём обобщение",
            "title": "Ваша запись забронирована",
            "title_reserved": "Ваша запись на приём еще не подтвердилась.",
            "title_on_site_reserved": "Ваш запись на приём зарезервирована. Требуеться подтверждение на месте",
            "title_desk_reserved": "Ваш запись на приём зарезервирована. Требуеться подтверждение персонала",
            "infotext": "<h4>Push уведомления на ваш смартфон</h4> <p> Сканируйте QR код с нашим приложением cleverQ и вы будете получать напоминания о назначенной записе на приём через push уведомления.</p>",
            "confirmtext": "<h4>Зацепки о подтверждение на прием.</h4> <p> Вы назначили время на прием у нас. Чтобы его обязывающе забронировать, нам нужно подтверждение с вашего электронного ящика. Пожалуйста нажмите на ссылку в этом е-майле. Этим вы обязывающе назначаете время на прием, без этого процесса истекает бронирование. </p>",
            "on_site_confirmtext": "<h4>Подтверждение на месте.</h4> <p>  Пожалуйста думайте о том что этот документ нужно в электронном виде на вашем телефоне или в распечатанном виде  принести с собой на  назначенное время. Вам нужен код бронирования или QR код, чтобы подтвердить визит на месте и принять участие. </p>",
            "desk_confirmtext": "<h4>Подтверждение записи на прием через работника.</h4> <p> Мы подали запрос на назначение на прием работнику. Как только работник подтвердит запрос, мы пришлем вам подтверждение на е-майл. </p>",
            "reservation_code": "Код бронирования",
            "summary": "Заключение",
            "issue": "Запрос",
            "appointment": "Назначение на прием",
            "persons": "Личности",
            "person": "Личность",
            "name": "Фамилия",
            "birthday": "День рождения",
            "email": "Е-Майл",
            "phone": "Номер телефона",
            "message": "Сообщение",
            "back_button": "Назад к провайдеру",
            "print_button": "Распечатать",
            "advertising": "<h4> cleverQ Приложение ддя смартфона. </h4> <p> Закачайте наше бесплатное приложение cleverQ и используйте приимущества телефона. Назначения на запись на прием, вытащить тикет, Push сообщения, всё по дороге. Вы всегда будете в курсе событий. </p>",
            "advertising_image": "/images/smartphone_english.png",
            "duplicate_info_title": "Повторение назначения на прием",
            "duplicate_info_title_remove": "Пожалуйста откажитесь от предыдущего назначения на прием",
            "duplicate_info_text": "Вы уже забронировали запись на прием. Пожалуйста откажитесь от старого визита перед тем как назначите новый. Вы можете отказаться от старой записи когда откроете е-майл на который он был отослан.",
            "slot_already_booked_title": "Бронирование  не возможно",
            "slot_already_booked_text": "Запрашиваемое вами назначение на прием уже к сожалению кем то забронировано. Мы извеняемся за неудобства и просим выбрать другую запись на приём."
        },
        "navigation": {
            "cleverq": "Потому что кто ждет охотно?",
            "legal_notice": "Выходные данные",
            "policy": "Защита данных"
        },
        "greetings": {
            "confirm_greetings": "Дальше к назначению на приём"
        },
        "checkout_title": {
            "your": "Ваш",
            "choice": "Выбор"
        },
        "enter_invite_code": {
            "verify": "Подтвердите",
            "invite_code":"Код пригдлашения"
        },
        "appointment_times": {
            "at": "На ",
            "on": "В ",
            "o_clock": "Часов",
            "header": "Ваше забронированное время визита",
            "remove": "Выбрать новое время визита",
            "session": {
                "text": "Ваша сессия закончиться через: ",
                "close_error_modal": "Закрыть",
                "reservation_expired": "Бронирование истекло, пожалуйста выберите другое время визита.",
                "expired": "Ваша сессия закончилась, так как вы были долго не активным. Пожалуйста перегрузите страницу."
            }
        }
    }
}