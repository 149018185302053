import { combineForms } from 'react-redux-form';

export default combineForms(
  {
    user: {
      firstName: '',
      name: '',
      email1: '',
      email2: '',
      checkbox: false
    }
  },
  'user_data'
);
