import {
  RESET_APPOINTMENT,
  CREATE_APPOINTMENT,
  BOOK_APPOINTMENT_SUCCESS,
  CREATE_APPOINTMENT_TOUCHSCREEN
} from '../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case RESET_APPOINTMENT:
      return {};
    case CREATE_APPOINTMENT:
      // Take the existing state and concatenate to the new list of days
      return action.payload.data;
    case BOOK_APPOINTMENT_SUCCESS:
      // Take the existing state and concatenate to the new list of days
      return action.payload;
    case CREATE_APPOINTMENT_TOUCHSCREEN:
      return action.payload.data;
    default:
      return state;
  }
}
