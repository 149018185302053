import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {Popover, OverlayTrigger, Modal} from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll';
import { ScaleLoader } from 'react-spinners';
import styled from 'styled-components';
import * as actions from '../../actions';
import Helmet from 'react-helmet';
import { Default } from './elements/Button'

const query = new URLSearchParams(window.location.search);
const queryEmbeddedMode = query.get('embedded_mode')
const embedded_mode = queryEmbeddedMode === "true" ? true : false

class Issues extends Component {
  constructor(props) {
    super(props);

    this.state = {
      servicesLeft: null,
      servicesRight: null,
      show_modal_description: false,
      subtask_name: null,
      width: 0,
      height: 0,
      active_tooltip: null,
      description: null
    };
  }
  renderTooltip(s) {
    return (
      <Popover id="popover-basic" title={s.name} className="content-scale">
        <div dangerouslySetInnerHTML={this.createMarkup(s.tooltip)} />
      </Popover>
    )
  }
  handleCloseDescription() {
    this.setState({ show_modal_description: false, description: null });
  }
  showDescription(name, text) {
    this.setState({ show_modal_description: true, subtask_name: name, description: text })
  }
  handleClick(subtask, service, e) {
    e.preventDefault();
    this.props.resetAppointmentTimes();
    this.props.hideDatePicker();
    if (subtask.info_only) {
      this.showDescription(subtask.name, subtask.description_html)
      return
    }

    // Checks if a subtask limit is set and only add new subtask when this limit is not exceeded
    if (this.props.selected_service_data.subtask_limit !== 0) {
      if (
        this.props.selected_subtask.length <
        this.props.selected_service_data.subtask_limit
      ) {
        this.props.selectedSubtask(subtask);
        this.props.selectedService(service.id);
        this.props.selectedServiceName(service.name);
        this.props.weekChange();
      }
    } else {
      this.props.selectedSubtask(subtask);
      this.props.selectedService(service.id);
      this.props.selectedServiceName(service.name);
      this.props.weekChange();
    }

    // Remove subtask that is already selected when clicking a subtask again.
    this.props.selected_subtask.map(element => {
      if (element.id === subtask.id) {
        this.props.removedSubtask(subtask);
        this.props.resetPersons(subtask.id);
        this.props.setSubtaskEdit();
      }
      return null;
    });
    scroll.scrollToBottom();
  }
  handleClickOnlyOneSubtask(subtask, service, e) {
    e.preventDefault();
    this.props.resetAppointmentTimes();
    if (subtask.info_only) {
      this.showDescription(subtask.name, subtask.description_html)
      return
    }
    if (this.props.site_info.show_services) {
      if (!this.props.selected_subtask.length) {
        this.props.hideDatePicker();
        this.props.selectedSubtask(subtask);
        this.props.selectedService(service.id);
        this.props.selectedServiceName(service.name);
        this.props.onlyOneSubtask(subtask);
        this.props.resetSubtaskPersons();
        this.props.weekChange();
      } else {
        if (this.props.selected_subtask[0].id !== subtask.id) {
          this.props.hideDatePicker();
          this.props.selectedSubtask(subtask);
          this.props.selectedService(service.id);
          this.props.selectedServiceName(service.name);
          this.props.onlyOneSubtask(subtask);
          this.props.resetSubtaskPersons();
          this.props.weekChange();
        }
      }
    } else {
      if (!this.props.selected_subtask.length) {
        this.props.hideDatePicker();
        this.props.selectedSubtask(subtask);
        this.props.selectedService(service.id);
        this.props.selectedServiceName(service.name);
        this.props.onlyOneSubtask(subtask);
        this.props.resetSubtaskPersons();
        this.props.weekChange();
      } else {
        if (this.props.selected_subtask[0].id !== subtask.id) {
          this.props.hideDatePicker();
          this.props.selectedSubtask(subtask);
          this.props.selectedService(service.id);
          this.props.selectedServiceName(service.name);
          this.props.onlyOneSubtask(subtask);
          this.props.resetSubtaskPersons();
          this.props.weekChange();
        }
      }
    }
    scroll.scrollToBottom();
  }
  entering = e => {
    e.children[0].style.borderTopColor = this.props.site_info.tooltip_background;
    e.children[1].style.backgroundColor = this.props.site_info.tooltip_background;
    e.children[1].style.color = this.props.site_info.tooltip_text;
  };
  renderSubtasks(service_id) {
    const Button = styled.button`
      background: ${this.props.site_info.button_background};
      color: ${this.props.site_info.button_text};
      width: 100%;
      text-align: center;
      font-size: 1.1em;
      padding: 12px;
      margin-bottom: 15px;
      border: 1px solid #CBCBCBFF;
      border-radius: 3px;
   
    `;
    if (this.props.services) {
      return this.props.services.filter(s => s.id === service_id).map(item => {
          return item.subtasks
            .filter(
              x =>
                this.props.selected_service_data.subtask_groups &&
                this.props.selected_service_data.subtask_groups.length
                  ? x.subtask_group_ids.includes(this.props.selected_subtask_group)
                  : x.subtask_group_ids === null || x.subtask_group_ids.length === 0
            )
            .map(s => {
              if ((s.used_for_appointments && !s.visible_only_for_desks && !s.internal_usage_only) || this.props.session_info.isUser) {
                if (this.props.touchscreen_mode === false) {
                  return (
                    <div
                      style={{display: 'flex'}}
                      key={s.id}>
                      {!this.props.site_info.alternate_buttons ? (
                        <Button
                          className={
                            this.props.site_info.drop_shadows
                              ? 'drop-shadow'
                              : null
                          }
                          key={s.id}
                          onClick={e =>
                            item.appointment_only_one_subtasks === true
                              ? this.handleClickOnlyOneSubtask(s, item, e)
                              : this.handleClick(s, item, e)
                          }
                          style={{
                            backgroundColor: this.props.selected_subtask
                              .filter(element => element.id === s.id)
                              .map(subtask => {
                                if (subtask.id === s.id) {
                                  return this.props.site_info.button_active;
                                } else {
                                  return this.props.site_info.button_background;
                                }
                              }),
                            color: this.props.selected_subtask
                              .filter(element => element.id === s.id)
                              .map(subtask => {
                                if (subtask.id === s.id) {
                                  return this.props.site_info
                                    .button_text_active;
                                } else {
                                  return this.props.site_info.button_text;
                                }
                              })
                          }}>
                          <div dangerouslySetInnerHTML={this.createMarkup(s.name)} />
                        </Button>
                      ) : (
                        <div
                          style={{ marginBottom: '15px', width: '100%' }}
                          onClick={e =>
                            item.appointment_only_one_subtasks === true
                              ? this.handleClickOnlyOneSubtask(s, item, e)
                              : this.handleClick(s, item, e)
                          }
                        >
                          <Default
                            site_info={this.props.site_info}
                            icon_type="issues"
                            item={s}
                            selected_item={this.props.selected_subtask
                              .filter(element => element.id === s.id)
                              .map(subtask => {
                                if (subtask.id === s.id) {
                                  return s.id
                                } else {
                                  return null
                                }})[0]}
                          />
                        </div>
                      )}
                      {!this.props.site_info.hide_tooltips && s.tooltip.length ?
                        <Button
                          onClick={() => this.showDescription(s.name, s.tooltip)}
                          className={
                            this.props.site_info.drop_shadows
                              ? 'issue_help drop-shadow'
                              : 'issue_help'
                          }
                          style={{
                            fontSize: '1.6rem',
                            height: this.props.site_info.border_on_items ? '51px' : '52px',
                            width: this.props.site_info.border_on_items ? '51px' : '52px',
                            padding: '0px',
                            marginLeft: '10px',
                            marginTop: this.props.site_info.border_on_items ? '1px' : 0,
                            backgroundColor: this.state.active_tooltip === s.id ? this.props.site_info.tooltip_text : this.props.site_info
                              .tooltip_background,
                            color: this.state.active_tooltip === s.id ? this.props.site_info
                              .tooltip_background : this.props.site_info.tooltip_text,
                            border: this.props.site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF',
                            borderRadius: 0,
                            outline: 'none',
                          }}>
                          <i className="fas fa-question no_tab"></i>
                        </Button> : null
                      }
                    </div>
                  );
                } else {
                  if ((s.used_for_appointments && !s.visible_only_for_desks && !s.internal_usage_only)) {
                    return (
                      <div
                        className="touchscreen-issue-container col-md-6"
                        key={s.id}>
                        {!this.props.site_info.alternate_buttons ? (
                          <Button
                            className={
                              this.props.site_info.drop_shadows
                                ? 'drop-shadow'
                                : null
                            }
                            key={s.id}
                            onClick={e =>
                              item.appointment_only_one_subtasks === true
                                ? this.handleClickOnlyOneSubtask(s, item, e)
                                : this.handleClick(s, item, e)
                            }
                            style={{
                              backgroundColor: this.props.selected_subtask
                                .filter(element => element.id === s.id)
                                .map(subtask => {
                                  if (subtask.id === s.id) {
                                    return this.props.site_info.button_active;
                                  } else {
                                    return this.props.site_info.button_background;
                                  }
                                }),
                              color: this.props.selected_subtask
                                .filter(element => element.id === s.id)
                                .map(subtask => {
                                  if (subtask.id === s.id) {
                                    return this.props.site_info
                                      .button_text_active;
                                  } else {
                                    return this.props.site_info.button_text;
                                  }
                                })
                            }}>
                            <div dangerouslySetInnerHTML={this.createMarkup(s.name)} />
                          </Button>
                        ) : (
                          <div
                            style={{ marginBottom: '15px', width: '100%' }}
                            onClick={e =>
                              item.appointment_only_one_subtasks === true
                                ? this.handleClickOnlyOneSubtask(s, item, e)
                                : this.handleClick(s, item, e)
                            }
                          >
                            <Default
                              site_info={this.props.site_info}
                              icon_type="issues"
                              item={s}
                              selected_item={this.props.selected_subtask
                                .filter(element => element.id === s.id)
                                .map(subtask => {
                                  if (subtask.id === s.id) {
                                    return s.id
                                  } else {
                                    return null
                                  }})[0]}
                            />
                          </div>
                        )}
                        {s.tooltip.length ?
                          <OverlayTrigger
                            trigger="click"
                            placement="left"
                            overlay={this.renderTooltip(s)}
                            onEntering={this.entering}
                            rootClose>
                            <Button
                              className={
                                this.props.site_info.drop_shadows
                                  ? 'issue_help drop-shadow-less'
                                  : 'issue_help'
                              }
                              style={{
                                fontSize: '1.6rem',
                                height: '55px',
                                width: '55px',
                                padding: '0px',
                                marginLeft: '10px',
                                marginTop: '1px',
                                backgroundColor: this.props.site_info
                                  .tooltip_background,
                                color: this.props.site_info.tooltip_text,
                                border: '1px solid #CBCBCBFF'
                              }}>
                              ?
                            </Button>
                          </OverlayTrigger> : null
                        }
                      </div>
                    );
                  }
                }
              } else {
                return null;
              }
            });
        }
      );
    } else {
      return null;
    }
  }
  renderSubtasksWithoutServices() {
    const Button = styled.button`
      background: ${this.props.site_info.button_background};
      color: ${this.props.site_info.button_text};
      width: 100%;
      text-align: center;
      font-size: 1.1em;
      padding: 12px;
      margin-bottom: 15px;
      border: 1px solid #CBCBCBFF;
      border-radius: 3px;
      &:hover {
        background-color: ${this.props.site_info.button_hover} !important;
        color: ${this.props.site_info.button_text_hover} !important;
        transition: 500ms;
      }
    `;
    const AlternateButton = styled.button`
      background: ${this.props.site_info.button_background};
      color: ${this.props.site_info.button_text};
      width: 100%;
      text-align: left;
      font-size: 1.1em;
      padding: 12px;
      font-weight: 500;
      &:hover {
        background-color: ${this.props.site_info.button_hover} !important;
        color: ${this.props.site_info.button_text_hover} !important;
        transition: 500ms;
      }
    `;
    if (this.props.selected_service_data.subtasks) {
      return this.props.selected_service_data.subtasks
        .filter(
          x =>
            this.props.selected_service_data.subtask_groups &&
            this.props.selected_service_data.subtask_groups.length
              ? x.subtask_group_ids.includes(this.props.selected_subtask_group)
              : x.subtask_group_ids === null || x.subtask_group_ids.length === 0
        )
        .map(s => {
          if ((!s.visible_only_for_desks && !s.internal_usage_only) || this.props.session_info.isUser) {
            if (this.props.touchscreen_mode === false) {
              return (
                <div
                  style={{display: 'flex'}}
                  key={s.id}>
                  {!this.props.site_info.alternate_buttons ? (
                    <Button
                      className={
                        this.props.site_info.drop_shadows ? 'drop-shadow' : null
                      }
                      key={s.id}
                      onClick={e =>
                        this.props.selected_service_data
                          .appointment_only_one_subtasks
                          ? this.handleClickOnlyOneSubtask(
                          s,
                          this.props.selected_service_data,
                          e
                          )
                          : this.handleClick(
                          s,
                          this.props.selected_service_data,
                          e
                          )
                      }
                      style={{
                        backgroundColor: this.props.selected_subtask
                          .filter(element => element.id === s.id)
                          .map(subtask => {
                            if (subtask.id === s.id) {
                              return this.props.site_info.button_active;
                            } else {
                              return this.props.site_info.button_background;
                            }
                          }),
                        color: this.props.selected_subtask
                          .filter(element => element.id === s.id)
                          .map(subtask => {
                            if (subtask.id === s.id) {
                              return this.props.site_info.button_text_active;
                            } else {
                              return this.props.site_info.button_text;
                            }
                          })
                      }}>
                      <div dangerouslySetInnerHTML={this.createMarkup(s.name)} />
                    </Button>
                  ) : (
                    <div
                      style={{ marginBottom: '15px', width: '100%' }}
                      onClick={e =>
                        this.props.selected_service_data
                          .appointment_only_one_subtasks
                          ? this.handleClickOnlyOneSubtask(
                          s,
                          this.props.selected_service_data,
                          e
                          )
                          : this.handleClick(
                          s,
                          this.props.selected_service_data,
                          e
                          )
                      }
                    >
                      <Default
                        site_info={this.props.site_info}
                        icon_type="issues"
                        item={s}
                        selected_item={this.props.selected_subtask
                          .filter(element => element.id === s.id)
                          .map(subtask => {
                            if (subtask.id === s.id) {
                              return s.id
                            } else {
                              return null
                            }})[0]}
                      />
                    </div>
                  )}
                  {!this.props.site_info.hide_tooltips && s.tooltip.length ?
                    <Button
                      onClick={() => this.showDescription(s.name, s.tooltip)}
                      className={
                        this.props.site_info.drop_shadows
                          ? 'issue_help drop-shadow-less'
                          : 'issue_help'
                      }
                      style={{
                        fontSize: '1.8rem',
                        height: '52px',
                        width: '52px',
                        padding: '0px',
                        marginLeft: '10px',
                        marginTop: this.props.site_info.border_on_items ? '1px' : 0,
                        backgroundColor: this.props.site_info
                          .tooltip_background,
                        color: this.props.site_info.tooltip_text,
                        border: this.props.site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF',
                        borderRadius: 0,
                        outline: 'none'
                      }}>
                      <i className="fas fa-question"></i>
                    </Button> : null
                  }
                </div>
              );
            } else {
              if (!s.visible_only_for_desks && !s.internal_usage_only) {
                return (
                  <div className="col-md-6" key={s.id}>
                    <div className="touchscreen-issue-container" key={s.id}>
                      {!this.props.site_info.alternate_buttons ? (
                        <Button
                          className={
                            this.props.site_info.drop_shadows ? 'drop-shadow' : null
                          }
                          key={s.id}
                          onClick={e =>
                            this.props.selected_service_data
                              .appointment_only_one_subtasks
                              ? this.handleClickOnlyOneSubtask(
                              s,
                              this.props.selected_service_data,
                              e
                              )
                              : this.handleClick(
                              s,
                              this.props.selected_service_data,
                              e
                              )
                          }
                          style={{
                            backgroundColor: this.props.selected_subtask
                              .filter(element => element.id === s.id)
                              .map(subtask => {
                                if (subtask.id === s.id) {
                                  return this.props.site_info.button_active;
                                } else {
                                  return this.props.site_info.button_background;
                                }
                              }),
                            color: this.props.selected_subtask
                              .filter(element => element.id === s.id)
                              .map(subtask => {
                                if (subtask.id === s.id) {
                                  return this.props.site_info.button_text_active;
                                } else {
                                  return this.props.site_info.button_text;
                                }
                              })
                          }}>
                          <div dangerouslySetInnerHTML={this.createMarkup(s.name)} />
                        </Button>
                      ) : (
                        <div
                          style={{ marginBottom: '15px', width: '100%' }}>
                          <AlternateButton
                            className={
                              this.props.site_info.drop_shadows
                                ? 'drop-shadow'
                                : null
                            }
                            key={s.id}
                            onClick={e =>
                              this.props.selected_service_data
                                .appointment_only_one_subtasks
                                ? this.handleClickOnlyOneSubtask(
                                s,
                                this.props.selected_service_data,
                                e
                                )
                                : this.handleClick(
                                s,
                                this.props.selected_service_data,
                                e
                                )
                            }
                            style={{
                              backgroundColor: this.props.selected_subtask
                                .filter(element => element.id === s.id)
                                .map(subtask => {
                                  if (subtask.id === s.id) {
                                    return this.props.site_info.button_active;
                                  } else {
                                    return this.props.site_info.button_background;
                                  }
                                }),
                              color: this.props.selected_subtask
                                .filter(element => element.id === s.id)
                                .map(subtask => {
                                  if (subtask.id === s.id) {
                                    return this.props.site_info.button_text_active;
                                  } else {
                                    return this.props.site_info.button_text;
                                  }
                                }),
                              borderLeft: `10px solid ${
                                this.props.site_info.button_text
                              }`
                            }}>
                            <div dangerouslySetInnerHTML={this.createMarkup(s.name)} />
                          </AlternateButton>
                        </div>
                      )}
                      {s.tooltip.length ?
                        <OverlayTrigger
                          trigger="click"
                          placement="left"
                          overlay={this.renderTooltip(s)}
                          onEntering={this.entering}
                          rootClose>
                          <Button
                            className={
                              this.props.site_info.drop_shadows ? 'drop-shadow-less' : null
                            }
                            style={{
                              fontSize: '16px',
                              height: '53px',
                              width: '53px',
                              padding: '0px',
                              marginLeft: '10px',
                              backgroundColor: this.props.site_info
                                .tooltip_background,
                              color: this.props.site_info.tooltip_text,
                              border: '1px solid #CBCBCBFF'
                            }}>
                            ?
                          </Button>
                        </OverlayTrigger> : null
                      }
                    </div>
                  </div>
                );
              }
            }
          }
        });
    } else {
      return null;
    }
  }
  createMarkup(markup) {
    return { __html: markup };
  }
  render() {
    const description = this.props.selected_subtask_group_data !== 'hidden' && this.props.selected_subtask_group_data.general_description && this.props.selected_subtask_group_data.general_description.length ?
      this.props.selected_subtask_group_data.general_description :
      this.props.selected_service_data.description_html && this.props.selected_service_data.description_html.length ?
        this.props.selected_service_data.description_html :
        this.props.site_info.info_tasks
    const subtasks_header = this.props.site_info.subtasks_header_html || this.props.site_info.subtasks_header;
    if (!this.props.no_issues) {
      return (
        <Fragment>
          <Modal
            style={{paddingLeft: 0}}
            show={this.state.show_modal_description}
            onHide={this.handleCloseDescription.bind(this)}
            bsSize="large"
            aria-labelledby="contained-modal-title-lg">
            <Modal.Header style={{background: this.props.site_info.heading_background, color: this.props.site_info.heading_text}} closeButton>
              <Modal.Title id="contained-modal-title-lg">
                <div dangerouslySetInnerHTML={this.createMarkup(this.state.subtask_name)} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={this.createMarkup(this.state.description)} />
            </Modal.Body>
          </Modal>
          <Helmet>
            <style>{`
            .issues_header_left, .slot_picker_header_left, .data_forms_header_left{
              background-color: ${this.props.site_info.heading_background};
              color: ${this.props.site_info.heading_text};
              font-size: 1.1em;
              font-weight: 600;
              padding: 8px;
              border: 1px solid #CBCBCBFF;
              border-bottom: none;
              border-radius: 5px 5px 0px 0px;
            }
          `}</style>
          </Helmet>
          <div
            className={`container animated fadeIn ${
              this.props.site_info.drop_shadows ? 'drop-shadow' : null
            }`} style={embedded_mode ? { marginBottom: '15px'} : null }>
            <div className="row">
              <div className="col-md-12 issues_header_left" id="scroll_target_subtasks">
                <span className="no_tab" dangerouslySetInnerHTML={this.createMarkup(subtasks_header)}></span>
              </div>
            </div>
            <div
              className="row inline-shadow"
              style={{
                backgroundColor: this.props.site_info.content_background,
                color: this.props.site_info.content_text
              }}>
              <div style={{ marginTop: '15px' }} />
              {this.props.services && this.props.services.length ? (
                !this.props.touchscreen_mode ? (
                  <div className="col-md-6 mobile-paddings">
                    {this.props.services
                      ? this.props.site_info.show_services
                        ? this.renderSubtasks(this.props.selected_service)
                        : this.renderSubtasksWithoutServices()
                      : null}
                  </div>
                ) : (
                  <div className="subtask-container-flex">
                    {this.props.services
                      ? this.props.site_info.show_services
                        ? this.renderSubtasks(this.props.selected_service)
                        : this.renderSubtasksWithoutServices()
                      : null}
                  </div>
                )
              ) : (
                <div className="col-md-6">
                  <ScaleLoader
                    sizeUnit={'px'}
                    size={300}
                    color={this.props.site_info.loading_indicator}
                    loading={true}
                  />
                </div>
              )}
              {!this.props.touchscreen_mode ? (
                <div className="col-md-6">
                  <div
                    style={{ fontSize: '1.1em' }}
                    dangerouslySetInnerHTML={this.createMarkup(
                      description
                    )}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  }
}

// Map`s the state to props, to get access to specific data from the store.
function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    services: state.service_list,
    subtasks: state.subtask_list,
    selected_service: state.user_selected_service,
    selected_subtask: state.user_selected_subtask,
    selected_service_data: state.user_selected_service_data,
    selected_subtask_group: state.user_selected_subtask_group,
    selected_subtask_group_data: state.user_selected_subtask_group_data,
    no_issues: state.no_issues,
    session_info: state.session_info
  };
}

// Export and connection to the store.
export default connect(
  mapStateToProps,
  actions
)(Issues);
