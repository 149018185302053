import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  printAppointment,
  printAppointmentQrCode,
  printAppointmentSamsung
} from '../../actions';
import { Modal, Table } from 'react-bootstrap';
import { ScaleLoader } from 'react-spinners';
import { injectIntl } from "react-intl";

const query = new URLSearchParams(window.location.search);
const samsung_mode = query.get('samsung_mode')

class AppointmentInfo extends Component {
  state = {
    show: true
  };

  componentDidMount() {
    const {
      start,
      stop,
      reservation_code,
      service_name,
      site_id,
      qr_code
    } = this.props.booking;

    if (samsung_mode) {
      printAppointmentSamsung(this.props.booking, this.props.default_locale)
    } else {
      if (this.props.on_site_confirmation) {
        this.props.dispatch(
          printAppointmentQrCode(site_id, start, stop, reservation_code, service_name, qr_code)
        );
      } else {
        this.props.dispatch(
          printAppointment(site_id, start, stop, reservation_code, service_name)
        );
      }
    }

    const block_time = this.props.browserKoke ? 3000 : 10000;

    setTimeout(() => {
      if (window.api && window.api.request) {
        window.api.request('back_to_home')
      } else {
        if (samsung_mode) {
          window.parent.postMessage({
            component: 'general',
            command: 'back-to-touchscreen'
          }, '*');
        } else {
          this.backToCustomer();
        }
      }
    }, block_time);
  }
  handleClose() {
    this.setState({ show: true });
  }
  handleShow() {
    this.setState({ show: true });
  }
  backToCustomer() {
    if (window.api && window.api.request) {
      window.api.request('back_to_home')
    } else {
      const home_url = sessionStorage.getItem("home_url");
      if (home_url && home_url !== window.location.href) {
        if (this.props.browserKoke) {
          //navigateHome()
        }
        window.location.replace(home_url)
      } else {
        window.location.replace(window.location.href);
      }
    }
  }
  createMarkup(markup) {
    return { __html: markup };
  }
  render() {
    const {intl} = this.props
    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose.bind(this)}
          bsSize="large"
          aria-labelledby="contained-modal-title-lg">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-lg">
              {this.props.booking.status === 'needs_user_confirmation'
                ? intl.formatMessage({id: 'appointment_info.title_reserved'})
                : this.props.booking.status ===
                  'on_site_confirmation_pending'
                  ? intl.formatMessage({id: 'appointment_info.title_on_site_reserved'})
                  : this.props.booking.status === 'needs_confirmation'
                    ? intl.formatMessage({id: 'appointment_info.title_desk_reserved'})
                    : intl.formatMessage({id: 'appointment_info.title'})}
            </Modal.Title>
          </Modal.Header>
          {this.props.booking ? (
            <Modal.Body>
              <div className="row">
                <div className="col-sm-8">
                  {this.props.site_info.custom_confirmation_text ?
                    <h4 className="no_tab" dangerouslySetInnerHTML={this.createMarkup(this.props.site_info.custom_confirmation_text)} ></h4> : null
                  }
                  {this.props.booking.status ===
                  'needs_user_confirmation' ? (
                    <h4 className="no_tab" dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.confirmtext'}))}></h4>
                  ) : null}
                  {this.props.booking.status ===
                  'on_site_confirmation_pending' ? (
                    <h4 className="no_tab" dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.on_site_confirmtext'}))}></h4>
                  ) : null}
                  {this.props.booking.status === 'needs_confirmation' ? (
                    <h4 className="no_tab" dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.desk_confirmtext'}))}></h4>
                  ) : null}
                  {this.props.booking.status !==
                  ('needs_user_confirmation' || 'needs_confirmation') ? (
                    <h4 className="no_tab" dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.infotext'}))}></h4>
                  ) : null}
                </div>
              </div>
              <hr />
              {this.props.site_info.show_app_links ? (
                <div>
                  <div dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.advertising'}))}/>
                  <img
                      className="hide-on-print-page hidden-print"
                      src={intl.formatMessage({id: 'appointment_info.advertising_image'})}
                      alt="smartphone"
                  />
                </div>
              ) : null}
            </Modal.Body>
          ) : (
            <div style={{ marginLeft: '20px' }}>
              <ScaleLoader
                sizeUnit={'px'}
                size={300}
                color={this.props.site_info.loading_indicator}
                loading={true}
              />
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    booking: state.booking,
    selected_service: state.user_selected_service,
    selected_service_name: state.user_selected_service_name,
    selected_day: state.user_selected_day,
    selected_slot: state.user_selected_slot,
    user_person_count: state.user_count_persons
  };
}

export default injectIntl(connect(mapStateToProps)(AppointmentInfo));
