import { FETCH_SESSION_INFO, SET_SESSION_INFO } from '../actions/types';

const initialState = {
  isUser: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_SESSION_INFO:
      // Take the existing state and concatenate to the new list of days
      if (action.payload.data && action.payload.data.username.length) {
        return {
          isUser: true
        };
      } else {
        return {
          isUser: false
        };
      }
    case SET_SESSION_INFO:
      return {
        isUser: true
      }
    default:
      return state;
  }
}
