import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {Popover, OverlayTrigger, Table} from 'react-bootstrap';
import { ScaleLoader } from 'react-spinners';
import { Modal } from 'react-bootstrap';
import * as actions from '../../actions';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Default } from './elements/Button'
import { scrollToTarget } from './elements/ScrollToTarget'
import { FormattedMessage, injectIntl } from "react-intl";

class Category extends Component {
  constructor() {
    super();

    this.state = {
      show_modal: false,
      show_modal_description: false,
      service_name: null,
      description: null,
      width: 0,
      height: 0,
      active_tooltip: null
    }
  }
  renderTooltip(s) {
    return (
      <Popover id="popover-basic" title={s.name}>
        <div dangerouslySetInnerHTML={this.createMarkup(s.description)} />
      </Popover>
    )
  }
  createMarkup(markup) {
    return { __html: markup };
  }
  handleClose() {
    this.setState({ show_modal: false });
  }
  handleCloseDescription() {
    this.setState({ show_modal_description: false, description: null });
  }
  showDescription(name, text) {
    this.setState({ show_modal_description: true, service_name: name, description: text })
  }
  handleClick(service, e) {
    e.preventDefault();
    this.props.resetAppointmentTimes();
    if (!service.info_only) {
      if (
        service.subtasks.length && this.props.site_info.appointments_use_subtasks
      ) {
        if (service.appointment_only_one_subtasks === true) {
          this.props.setMultiTasks(0);
        } else {
          this.props.setMultiTasks(1);
        }
        this.props.selectedService(service.id);
        this.props.selectedServiceData(service);
        this.props.selectedServiceName(service.name);
        this.props.setNoIssues(false);
        this.props.resetSubtasks();
        this.props.resetSubtaskPersons();
        this.props.handleReset(false);
        if (service.subtask_groups && service.subtask_groups.length) {
          this.props.resetSubtaskGroup();
          this.props.setSubtaskGroupEdit();
          scrollToTarget("scroll_target_subtask_groups")
        } else {
          this.props.setSubtaskEdit();
          scrollToTarget("scroll_target_subtasks")
        }
      } else {
        this.props.selectedService(service.id);
        this.props.selectedServiceData(service);
        this.props.selectedServiceName(service.name);
        this.props.setNoIssues(true);
        this.props.resetSubtasks();
        this.props.resetSubtaskPersons();
        this.props.handleReset(false);
        if (service.subtask_groups && service.subtask_groups.length) {
          this.props.resetSubtaskGroup();
          this.props.setSubtaskGroupEdit();
          scrollToTarget("scroll_target_subtask_groups")
        } else {
          this.props.setSubtaskEdit();
          scrollToTarget("scroll_target_subtasks")
        }
      }
    } else {
      if (service.appointment_only_one_subtasks === true) {
        this.props.setMultiTasks(0);
      } else {
        this.props.setMultiTasks(1);
      }
      this.props.selectedService(service.id);
      this.props.selectedServiceData(service);
      this.props.selectedServiceName(service.name);
      this.props.setNoIssues(false);
      this.props.resetSubtasks();
      this.props.resetSubtaskPersons();
      this.props.handleReset(false);
      if (service.subtask_groups && service.subtask_groups.length) {
        this.props.resetSubtaskGroup();
        if (!service.info_only) {
          this.props.setSubtaskGroupEdit();
          scrollToTarget("scroll_target_subtask_groups")
        }
      } else {
        if (!service.info_only) {
          this.props.setSubtaskEdit();
          scrollToTarget("scroll_target_subtasks")
        }
      }
      this.setState({ show_modal: true })
    }
  }
  entering = e => {
    e.children[0].style.borderTopColor = this.props.site_info.service_tooltip_background_color;
    e.children[1].style.backgroundColor = this.props.site_info.service_tooltip_background_color;
    e.children[1].style.color = this.props.site_info.service_tooltip_color;
  };
  renderCategorys() {
    const Button = styled.button`
      background: ${this.props.site_info.button_background};
      color: ${this.props.site_info.button_text};
      width: 100%;
      text-align: center;
      font-size: 1.1em;
      padding: 12px;
      margin-bottom: 15px;
      border: 1px solid #CBCBCBFF;
      border-radius: 3px;
      &:hover {
        background-color: ${this.props.site_info.button_hover} !important;
        color: ${this.props.site_info.button_text_hover} !important;
        transition: 500ms;
      }
    `;

    const service_group_description = this.props.selected_service_group_data.general_description
    const services_data = this.props.selected_service_group !== 'hidden' ? this.props.services.filter(service => service.service_group_id === this.props.selected_service_group) : this.props.services
    return services_data.map(service => {
      if (!service.visible_only_for_desks || (this.props.session_info.isUser && !this.props.touchscreen_mode) ) {
        if (!service.invite_only && !service.not_publicy_listed || this.props.session_info.isUser || this.props.selected_service_group_data.group_not_publicy_listed) {
          return (
            <div style={{display: 'flex'}} key={service.id} className={service_group_description !== undefined ? service_group_description !== "" ? "col-sm-12" : "col-sm-6" : "col-sm-6"}>
              {!this.props.site_info.alternate_buttons ? (
                  <Button
                      className={
                        this.props.site_info.drop_shadows ? 'drop-shadow' : null
                      }
                      onClick={e => !service.show_unavailable && this.handleClick(service, e)}
                      style={{
                        backgroundColor:
                            service.show_unavailable ? "#D5D5D5" :
                              this.props.selected_service === service.id
                                ? this.props.site_info.button_active
                                : this.props.site_info.button_background,
                        color:
                            this.props.selected_service === service.id
                                ? this.props.site_info.button_text_active
                                : this.props.site_info.button_text,
                        width: '100%',
                        pointerEvents: service.show_unavailable ? 'none' : 'auto'
                      }}>
                    <div>
                      {service.name} {service.show_unavailable ? <FormattedMessage id="issues.unavailable" /> : ""}
                    </div>
                  </Button>
              ) : (
                  <div style={{marginBottom: '15px', width: '100%'}} onClick={e => !service.show_unavailable && this.handleClick(service, e)}>
                  <Default
                    site_info={this.props.site_info}
                    icon_type="category"
                    item={service}
                    selected_item={this.props.selected_service}
                  />
                </div>
              )}
              {!this.props.site_info.hide_service_tooltips && service.description.length ?
                <Button
                  onClick={() => this.showDescription(service.name, service.description)}
                  className={
                    this.props.site_info.drop_shadows
                      ? 'issue_help drop-shadow-less'
                      : 'issue_help'
                  }
                  style={{
                    fontSize: '1.6rem',
                    width: this.props.site_info.border_on_items ? '55px' : '56px',
                    padding: '0px',
                    marginLeft: '10px',
                    marginTop: this.props.site_info.border_on_items ? '1px' : 0,
                    backgroundColor: this.state.active_tooltip === service.id ? this.props.site_info.service_tooltip_color : this.props.site_info
                      .service_tooltip_background_color,
                    color: this.state.active_tooltip === service.id ? this.props.site_info
                      .service_tooltip_background_color : this.props.site_info.service_tooltip_color,
                    border: this.props.site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF',
                    borderRadius: 0,
                    outline: 'none'
                  }}>
                  <div dangerouslySetInnerHTML={this.createMarkup(this.props.site_info.service_tooltip_icon)} />
                </Button> : null
              }
            </div>
          );
        }
      }
    });
  }

  render() {
    const service_group_description = this.props.selected_service_group_data.general_description
    const category_header = this.props.site_info.categorys_header_html || this.props.site_info.categorys_header;
    return (
      <Fragment>
        <Modal
          style={{paddingLeft: 0}}
          show={this.state.show_modal}
          onHide={this.handleClose.bind(this)}
          bsSize="large"
          aria-labelledby="contained-modal-title-lg">
          <Modal.Header style={{background: this.props.site_info.heading_background, color: this.props.site_info.heading_text}}>
            <Modal.Title id="contained-modal-title-lg">
              <div dangerouslySetInnerHTML={this.createMarkup(this.props.selected_service_data.name)} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={this.createMarkup(this.props.selected_service_data.description_html)} />
          </Modal.Body>
        </Modal>
        <Modal
          style={{paddingLeft: 0}}
          show={this.state.show_modal_description}
          onHide={this.handleCloseDescription.bind(this)}
          bsSize="large"
          aria-labelledby="contained-modal-title-lg">
          <Modal.Header style={{background: this.props.site_info.heading_background, color: this.props.site_info.heading_text}} closeButton>
            <Modal.Title id="contained-modal-title-lg">
              <div dangerouslySetInnerHTML={this.createMarkup(this.state.service_name)} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={this.createMarkup(this.state.description)} />
          </Modal.Body>
        </Modal>
        <Helmet>
          <style>{`
            .issues_header_left, .slot_picker_header_left, .data_forms_header_left{
              background-color: ${this.props.site_info.heading_background};
              color: ${this.props.site_info.heading_text};
              font-size: 1.1em;
              font-weight: 600;
              padding: 8px;
              border: 1px solid #CBCBCBFF;
              border-bottom: none;
              border-radius: 5px 5px 0px 0px;
            }
          `}</style>
        </Helmet>
        {this.props.site_info.show_services ? (
          <div
            className={`container animated fadeIn ${
              this.props.site_info.drop_shadows ? 'drop-shadow' : null
            }`}>
            <div className="row">
              <div className="col-md-12 issues_header_left no_tab" id="scroll_target_services">
                <span className="no_tab" dangerouslySetInnerHTML={this.createMarkup(category_header)}></span>
              </div>
            </div>
            <div
              className="row inline-shadow"
              style={{
                backgroundColor: this.props.site_info.content_background,
                color: this.props.site_info.content_text
              }}>
              <div style={{ marginTop: '15px' }} />
              {this.props.services && this.props.services.length ? (
                  <Fragment>
                    <div style={{padding: '0px'}} className={service_group_description !== undefined ? service_group_description !== "" ? "col-sm-6" : "col-sm-12" : "col-sm-12"}>{this.props.services ? this.renderCategorys() : null}</div>
                    {service_group_description !== undefined ? service_group_description !== "" ?
                      <div
                        style={{fontSize: '15.4px'}}
                        className={'col-md-6'}
                        dangerouslySetInnerHTML={this.createMarkup(
                          service_group_description
                        )}>
                      </div> : null : null}
                  </Fragment>
                )
                : (
                  <div className="col-md-6">
                    <ScaleLoader
                      sizeUnit={'px'}
                      size={300}
                      color={this.props.site_info.loading_indicator}
                      loading={true}
                    />
                  </div>
                )}
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

// Map`s the state to props, to get access to specific data from the store.
function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    services: state.service_list,
    selected_service: state.user_selected_service,
    selected_service_data: state.user_selected_service_data,
    selected_service_group: state.user_selected_service_group,
    selected_service_group_data: state.user_selected_service_group_data,
    selected_subtask: state.user_selected_subtask,
    session_info: state.session_info,
    appointment_times: state.appointment_time
  };
}

// Export and connection to the store.
export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(Category)
);
