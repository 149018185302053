import React from 'react';

class CustomInput extends React.Component {
constructor(props) {
    super(props);
  }
  render() {
    return (
        <input
          name={this.props.name}
          type="text"
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          value={this.props.value || ''}
          style={this.props.touched && this.props.error ? { borderColor: 'var(--error-color)' } : null}
          readOnly={false}
          id="IdentNumber"
        />
    );
  }
}

export default CustomInput;