import axios from 'axios';
import $ from 'jquery';
import {
  CONFIRM_GREETINGS,
  CREATE_APPOINTMENT_TOUCHSCREEN,
  DATA_EDIT,
  DAY_EDIT,
  FETCH_APPOINTMENT_DAYS,
  FETCH_APPOINTMENT_SLOTS,
  FETCH_SERVICES,
  FETCH_SESSION_INFO,
  SET_SESSION_INFO,
  FETCH_SITE_INFO,
  FETCH_SUBTASKS,
  HIDE_POLICY,
  HIDE_SERVICE_GROUP_SELECTOR,
  HIDE_SERVICE_SELECTOR,
  HIDE_SUBTASK_GROUP_SELECTOR,
  HIDE_SUBTASK_SELECTOR,
  RESET_APPOINTMENT,
  RESET_USER_SELECTED_SLOT_TIME_OF_SLOT,
  SERVICE_EDIT,
  SERVICE_GROUP_EDIT,
  SET_NO_ISSUES,
  SHOW_POLICY,
  SHOW_SUBTASK_SELECTOR,
  SLOT_EDIT,
  SUBTASK_EDIT,
  SUBTASK_GROUP_EDIT,
  UPDATE_TIME_FRAME,
  USER_ONLY_ONE_SUBTASK,
  USER_OVERWRITE_PERSONS,
  USER_REMOVED_SUBTASK,
  USER_RESET_DAY,
  USER_RESET_SERVICE,
  USER_RESET_SLOT,
  USER_RESET_SUBTASK_GROUP,
  USER_RESET_SUBTASK_PERSONS,
  USER_RESET_SUBTASKS,
  USER_SELECTED_DAY,
  USER_SELECTED_LANGUAGE,
  USER_SELECTED_SERVICE,
  USER_SELECTED_SERVICE_DATA,
  USER_SELECTED_SERVICE_GROUP,
  USER_SELECTED_SERVICE_GROUP_DATA,
  USER_SELECTED_SERVICE_NAME,
  USER_SELECTED_SLOT,
  USER_SELECTED_SLOT_TIME_OF_SLOT,
  USER_SELECTED_SUBTASK,
  USER_SELECTED_SUBTASK_GROUP,
  USER_SELECTED_SUBTASK_GROUP_DATA,
  USER_SELECTED_WEEK_CHANGE,
  USER_SET_PERSONS_NULL,
  USER_UPDATE_PERSONS,
  VALIDATE_INVITE_URL_TOKEN,
  VALIDATE_INVITE_URL_TOKEN_ERROR,
  RESET_DAY_EDIT,
  CREATE_RESERVATION_FAILED,
  CREATE_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_FAILED,
  UPDATE_RESERVATION_SUCCESS,
  DESTROY_RESERVATION_SUCCESS,
  DESTROY_RESERVATION_FAILED,
  ADD_APPOINTMENT_TIME,
  EDIT_APPOINTMENT_TIME,
  REMOVE_APPOINTMENT_TIME,
  RESET_APPOINTMENT_TIMES,
  HIDE_DAY_AND_SLOT_PICKER,
  HIDE_DATA_EDIT,
  BOOK_APPOINTMENT_SUCCESS,
  BOOK_APPOINTMENT_FAILED,
  STORE_BOOKING_SESSION_KEY,
  WAITING_LIST_ENTRY_SUCCESS,
  WAITING_LIST_ENTRY_FAILED,
  TOUCHSCREEN_MODE
} from './types';
import { store } from '../WebAppointments';

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port;

// Set CSRF token to axios requests
const token = document.querySelector('[name=csrf-token]').content
axios.defaults.headers.common['X-CSRF-TOKEN'] = token

// Not publicy listed token from url
const query = new URLSearchParams(window.location.search);
const not_publicy_listed_token = query.get('hash') ? query.get('hash') : ''
const not_publicy_listed_service_id = query.get('service_id') ? query.get('service_id') : ''
const not_publicy_listed_service_group_id = query.get('service_group_id') ? query.get('service_group_id') : ''
const touchscreen_access_token = query.get('touchscreen_access_token') ? query.get('touchscreen_access_token') : ''

const api_v4 = axios.create({
  baseURL: `/api/external/v4/sites`,
  mode: 'no-cors',
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 15000
})

const api_v4_base = axios.create({
  baseURL: `/api/external/v4`,
  mode: 'no-cors',
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 15000
})



const HEADERS = {
  Authorization: 'Token token=vielleichtEinTokenHier?',
  'content-type': 'application/json'
};

export function fetchSiteUrlName(url_name, locale) {
  let response
  if (not_publicy_listed_token) {
    response = api_v4.get(`/info?site_url_name=${url_name}&locale=${locale}&not_publicy_listed_token=${not_publicy_listed_token}&service_id=${not_publicy_listed_service_id}`)
  } else {
    response = api_v4.get(`/info?site_url_name=${url_name}&locale=${locale}${query.get('touchscreen_mode') ? '&touchscreen_mode=true' : ''}`)
  }
  return {
    type: FETCH_SITE_INFO,
    payload: response
  };
}

export function fetchSiteInfo(site_id, locale) {
  let response
  if (not_publicy_listed_token && not_publicy_listed_service_group_id) {
    response = api_v4.get(`/${site_id}?locale=${locale}&not_publicy_listed_token=${not_publicy_listed_token}&service_group_id=${not_publicy_listed_service_group_id}`)
  } else {
    if (not_publicy_listed_token) {
      response = api_v4.get(`/${site_id}?locale=${locale}&not_publicy_listed_token=${not_publicy_listed_token}&service_id=${not_publicy_listed_service_id}`)
    } else {
      response = api_v4.get(`/${site_id}?locale=${locale}${query.get('touchscreen_mode') ? '&touchscreen_mode=true' : ''}`)
    }
  }
  return {
    type: FETCH_SITE_INFO,
    payload: response
  };
}

// export function fetchServices(site_id, locale) {
//   const response = api_v4.get(`/${site_id}/services?locale=${locale}`)
//   return {
//     type: FETCH_SERVICES,
//     payload: response
//   };
// }
//
// export function fetchSubtasks(site_id, locale) {
//   const response = api_v4.get(`/${site_id}/subtasks?locale=${locale}`)
//   return {
//     type: FETCH_SUBTASKS,
//     payload: response
//   };
// }

export function fetchSessionInfo() {
  const response = api_v4_base.get(`/${site_id}/sessions/info`)
  return {
    type: FETCH_SESSION_INFO,
    payload: response
  };
}

export function setSessionInfo(session) {
  return {
    type: SET_SESSION_INFO,
    payload: session
  };
}

export async function fetchAppointmentDays(
  site_id,
  service_id,
  from_day,
  to_day,
  count_persons,
  booking_session_key
) {
  let persons = [];
  let result;
  if (count_persons !== 'no_issues') {
    count_persons.map(item => {
      let obj = {
        subtask_id: item.subtask_id,
        number: item.number
      };
      return persons.push(obj);
    });
  }

  try {
    let params = {
      service_id: service_id,
      from_day: from_day,
      to_day: to_day,
      subtask_items: count_persons !== 'no_issues' ? persons : [{}],
      booking_session_key: store.getState().ui.booking_session_key
    }

    if (not_publicy_listed_token) {
      params.not_publicy_listed_token = not_publicy_listed_token
    }

    const response = await api_v4.get(
      `/${site_id}/appointments/available_days`,
      {
        params,
        HEADERS
      }
    )
    const responseBody = await response

    if (responseBody.data) {
      return {
        type: FETCH_APPOINTMENT_DAYS,
        payload: responseBody
      };
    }
  } catch (error) {
    return {
      type: FETCH_APPOINTMENT_DAYS,
      payload: handleErrors(error),
    }
  }
}

export function fetchAppointmentSlots(site_id, service_id, day, count_persons) {
  let persons = [];
  if (count_persons !== 'no_issues') {
    count_persons.map(item => {
      let obj = {
        subtask_id: item.subtask_id,
        number: item.number
      };
      return persons.push(obj);
    });
  }

  let params = {
    service_id: service_id,
    day: day,
    show_all: true,
    subtask_items: count_persons !== 'no_issues' ? persons : [{}],
    booking_session_key: store.getState().ui.booking_session_key
  }

  if (not_publicy_listed_token) {
    params.not_publicy_listed_token = not_publicy_listed_token
  }

  const response = api_v4.get(
    `/${site_id}/appointments/available_time_slots`,
    {
      params,
      HEADERS
    }
  );
  return {
    type: FETCH_APPOINTMENT_SLOTS,
    payload: response
  };
}

export async function createAppointmentTouchscreen(
  site_id,
  service_id,
  day,
  slot,
  subtask_id,
  persons,
  name,
  email,
  locale,
  honorific_prefix
) {
  try {
    let user_infos = []
    user_infos.push(name)

    if (honorific_prefix === 1) {
      user_infos.unshift('---')
    }

    const response = await axios(`${ROOT_URL_v2}/${site_id}/appointments`, {
      method: 'POST',
      data: {
        appointment: {
          site_id: site_id,
          service_id: service_id,
          day: day,
          slot: slot,
          email: email,
          user_infos: user_infos,
          subtask_items: persons.filter(item => item.persons !== 0),
          locale: locale
        },
        booking_session_key: store.getState().ui.booking_session_key
      },
      HEADERS
    });
    return {
      type: CREATE_APPOINTMENT_TOUCHSCREEN,
      payload: response
    };
  } catch (error) {
    // Wrapping the reponse from the error to get the real error code from the response body.
    return {
      type: CREATE_APPOINTMENT_TOUCHSCREEN,
      payload: error.response
    };
  }
}

export function selectedService(service_id) {
  return {
    type: USER_SELECTED_SERVICE,
    payload: service_id
  };
}

export function selectedServiceData(service) {
  return {
    type: USER_SELECTED_SERVICE_DATA,
    payload: service
  };
}

export function selectedServiceGroup(service_group_id) {
  return {
    type: USER_SELECTED_SERVICE_GROUP,
    payload: service_group_id
  };
}

export function selectedServiceGroupData(service_group) {
  return {
    type: USER_SELECTED_SERVICE_GROUP_DATA,
    payload: service_group
  };
}

export function selectedSubtaskGroup(subtask_group_id) {
  return {
    type: USER_SELECTED_SUBTASK_GROUP,
    payload: subtask_group_id
  };
}

export function selectedSubtaskGroupData(subtask_group) {
  return {
    type: USER_SELECTED_SUBTASK_GROUP_DATA,
    payload: subtask_group
  };
}

export function selectedSubtask(subtask_id) {
  return {
    type: USER_SELECTED_SUBTASK,
    payload: subtask_id
  };
}

export function removedSubtask(subtask_id) {
  return {
    type: USER_REMOVED_SUBTASK,
    payload: subtask_id
  };
}

export function resetService() {
  return {
    type: USER_RESET_SERVICE
  };
}

export function resetSubtasks() {
  return {
    type: USER_RESET_SUBTASKS
  };
}

export function resetSubtaskGroup() {
  return {
    type: USER_RESET_SUBTASK_GROUP
  };
}

export function onlyOneSubtask(subtask) {
  return {
    type: USER_ONLY_ONE_SUBTASK,
    payload: subtask
  };
}

export function updatePersons(
  subtask_id,
  count,
  name,
  boolean,
  initial_minutes,
  next_minutes
) {
  return {
    type: USER_UPDATE_PERSONS,
    payload: {
      subtask_id: subtask_id,
      number: count < 1 ? 0 : count,
      name: name,
      onlyOneSubtask: boolean,
      initial_minutes: initial_minutes,
      next_minutes: next_minutes
    }
  };
}

export function overwritePersons(subtask_items) {
  return {
    type: USER_OVERWRITE_PERSONS,
    payload: subtask_items
  };
}

export function resetPersons(subtask_id) {
  return {
    type: USER_SET_PERSONS_NULL,
    payload: {
      subtask_id: subtask_id
    }
  };
}

export function resetSubtaskPersons() {
  return {
    type: USER_RESET_SUBTASK_PERSONS
  };
}

export function resetDay() {
  return {
    type: USER_RESET_DAY
  };
}

export function resetSlot() {
  return {
    type: USER_RESET_SLOT
  };
}

export function resetTimeOfSlot() {
  return {
    type: RESET_USER_SELECTED_SLOT_TIME_OF_SLOT
  };
}

export function resetUser() {
  return {
    type: 'rrf/reset',
    model: 'user_data.user'
  };
}

export function resetAppointment() {
  return {
    type: RESET_APPOINTMENT
  };
}

export function selectedServiceName(service_name) {
  return {
    type: USER_SELECTED_SERVICE_NAME,
    payload: service_name
  };
}

export function selectedDay(day) {
  return {
    type: USER_SELECTED_DAY,
    payload: day
  };
}

export function selectedSlot(slot) {
  return {
    type: USER_SELECTED_SLOT,
    payload: slot
  };
}

export function selectedSlotTimeOfSlot(time_of_slot) {
  return {
    type: USER_SELECTED_SLOT_TIME_OF_SLOT,
    payload: time_of_slot
  };
}

export function weekChange() {
  return {
    type: USER_SELECTED_WEEK_CHANGE
  };
}

export function localeSet(lang) {
  return {
    type: USER_SELECTED_LANGUAGE,
    payload: lang
  };
}

export function setNoIssues(status) {
  return {
    type: SET_NO_ISSUES,
    payload: status
  };
}

export function setServiceEdit() {
  return {
    type: SERVICE_EDIT
  };
}

export function setServiceGroupEdit() {
  return {
    type: SERVICE_GROUP_EDIT
  };
}

export function setSubtaskGroupEdit() {
  return {
    type: SUBTASK_GROUP_EDIT
  };
}

export function setSubtaskEdit() {
  return {
    type: SUBTASK_EDIT
  };
}

export function setDayEdit() {
  return {
    type: DAY_EDIT
  };
}

export function setResetDayEdit() {
  return {
    type: RESET_DAY_EDIT
  }
}

export function setSlotEdit() {
  return {
    type: SLOT_EDIT
  };
}

export function setDataEdit() {
  return {
    type: DATA_EDIT
  };
}

export function setShowPolicy() {
  return {
    type: SHOW_POLICY
  };
}

export function setHidePolicy() {
  return {
    type: HIDE_POLICY
  };
}

export function updateTimeFrame(time_frame) {
  return {
    type: UPDATE_TIME_FRAME,
    payload: time_frame
  };
}

export function setHideServiceSelector() {
  return {
    type: HIDE_SERVICE_SELECTOR
  };
}

export function setHideServiceGroupSelector() {
  return {
    type: HIDE_SERVICE_GROUP_SELECTOR
  };
}

export function setHideSubtaskGroupSelector() {
  return {
    type: HIDE_SUBTASK_GROUP_SELECTOR
  };
}

export function setHideSubtaskSelector() {
  return {
    type: HIDE_SUBTASK_SELECTOR
  };
}

export function setShowSubtaskSelector() {
  return {
    type: SHOW_SUBTASK_SELECTOR
  };
}

export async function printAppointment(
  site_id,
  start,
  stop,
  reservation_code,
  service_name
) {
  let result = new Promise(async (resolve, reject) => {
    let retries = 0;
    let success = false;
    const maxRetries = 3;

    while (retries < maxRetries && !success) {
      try {
        const response = await axios(
          `https://localhost:8443/printAppointmentCleverQ/${site_id}/${start}/${stop}/${service_name}/${reservation_code}`,
          {
            method: 'GET',
            HEADERS
          }
        );
        success = true
        resolve(response.data);
      } catch (err) {
        const status = err?.response?.status || 404;
        console.log(`Error Status: ${status}`);
      }
      retries++
    }
    console.log(`Too many request retries.`);
    reject()
  })

  try {
    if (result) {
      return {
        type: 'APPOINTMENT_PRINTED',
        payload: result
      };
    }
  } catch (error) {
    // Wrapping the reponse from the error to get the real error code from the response body.
    return {
      type: 'PRINT_ERROR',
      payload: 'error'
    };
  }
}

export async function printAppointmentQrCode(
  site_id,
  start,
  stop,
  reservation_code,
  service_name,
  qr_code
) {
  let result = new Promise(async (resolve, reject) => {
    let retries = 0;
    let success = false;
    const maxRetries = 3;

    while (retries < maxRetries && !success) {
      try {
        let code = ""
        if (typeof qr_code !== 'undefined') {
          code = qr_code.replace (/\//g, "_");
        }
        const response = await axios(
          `https://localhost:8443/printAppointmentCleverQQrCode/${site_id}/${start}/${stop}/${service_name}/${reservation_code}/${code}`,
          {
            method: 'GET',
            HEADERS
          }
        );
        success = true
        resolve(response.data);
      } catch (err) {
        const status = err?.response?.status || 404;
        console.log(`Error Status: ${status}`);
      }
      retries++
    }
    console.log(`Too many request retries.`);
    reject()
  })

  try {
    if (result) {
      return {
        type: 'APPOINTMENT_PRINTED',
        payload: result
      };
    }
  } catch (error) {
    // Wrapping the reponse from the error to get the real error code from the response body.
    return {
      type: 'PRINT_ERROR',
      payload: 'error'
    };
  }
}

export async function validateInviteUrlToken(
  site_id,
  invite_url_token,
  locale
) {
  try {
    const response = await api_v4.get(`/${site_id}/validate_invite_url_token?invite_url_token=${invite_url_token}&locale=${locale}`)
    return {
      type: VALIDATE_INVITE_URL_TOKEN,
      payload: response
    };
  } catch (error) {
    // Wrapping the reponse from the error to get the real error code from the response body.
    return {
      type: VALIDATE_INVITE_URL_TOKEN_ERROR,
      payload: error.response
    };
  }
}

export function confirmGreetings() {
  return {
    type: CONFIRM_GREETINGS
  };
}

// ======== Reservations =========>

export async function bookReservations(
  site_id,
  reservations,
  info_requests,
  email,
  email_optional,
  locale,
  invite_url_token,
  nrw_portal_konto_id,
  recaptcha_token,
  not_publicy_listed_token,
  service_id,
  mobile_phone_number,
  ident_number
) {
  try {
    const requestData = {
      reservations: reservations,
      info_field_results: info_requests,
      locale: locale,
      invite_url_token: invite_url_token,
      nrw_portal_konto_id: nrw_portal_konto_id,
      recaptcha_token: recaptcha_token,
      not_publicy_listed_token: not_publicy_listed_token,
      service_id: service_id,
      booking_session_key: store.getState().ui.booking_session_key,
      mobile_phone_number: mobile_phone_number,
      ident_number: ident_number
    };

// Add the email field only if it's provided
    if (!email_optional) {
      requestData.email = email;
    }

    if (touchscreen_access_token) {
      requestData.touchscreen_access_token = touchscreen_access_token
    }

    const responseBody = await api_v4.post(`/${site_id}/appointments/book_reservations`, requestData);

    if (responseBody) {
      return {
        type: BOOK_APPOINTMENT_SUCCESS,
        payload: responseBody.data.appointments[0],
      }
    }
  } catch (error) {
    return {
      type: BOOK_APPOINTMENT_FAILED,
      payload: handleErrors(error),
    }
  }
}

export async function createReservation(site_id, locale) {
  try {
    const response = await api_v4.post(
      `/${site_id}/appointments/reservations`, { locale: locale, not_publicy_listed_token: not_publicy_listed_token, booking_session_key: store.getState().ui.booking_session_key}
    )
    const responseBody = await response

    if (responseBody.data) {
      return{
        type: CREATE_RESERVATION_SUCCESS,
        payload: responseBody.data,
      }
    }
  } catch (error) {
    return {
      type: CREATE_RESERVATION_FAILED,
      payload: handleErrors(error),
    }
  }
}

export async function updateReservation(site_id, reservation_key, service_id, slot_id, day, subtask_items, locale) {
  try {
    const response = await api_v4.patch(
      `/${site_id}/appointments/reservations/${reservation_key}`, {
        reservation: {
          service_id: service_id,
          slot: slot_id,
          day: day,
          subtask_items: subtask_items,
        },
        locale: locale,
        booking_session_key: store.getState().ui.booking_session_key,
        not_publicy_listed_token: not_publicy_listed_token
      }
    )
    const responseBody = await response

    if (responseBody) {
      return {
        type: UPDATE_RESERVATION_SUCCESS,
        payload: responseBody
      }
    }
  } catch (error) {
    return {
      type: UPDATE_RESERVATION_FAILED,
      payload: handleErrors(error)
    }
  }
}

export async function destroyReservation(site_id, reservation_key) {
  try {
    const response = await api_v4.delete(
      `/${site_id}/appointments/reservations/${reservation_key}`, {
          data: {
            booking_session_key: store.getState().ui.booking_session_key
          }
        }
    )
    const responseBody = await response

    if (responseBody.data) {
      return {
        type: DESTROY_RESERVATION_SUCCESS,
        payload: responseBody.data
      }
    }
  } catch (error) {
    return {
      type: DESTROY_RESERVATION_FAILED,
      payload: handleErrors(error),
    }
  }
}

export const addAppointmentTime = (appointment_time) => {
  return {
    type: ADD_APPOINTMENT_TIME,
    payload: appointment_time,
  }
}

export const removeAppointmentTime = (id) => {
  return {
    type: REMOVE_APPOINTMENT_TIME,
    payload: id,
  }
}

export const editAppointmentTime = (id) => {
  return {
    type: EDIT_APPOINTMENT_TIME,
    payload: id,
  }
}

export const resetAppointmentTimes = () => {
  const site_id = store.getState().site_info.id
  const appointment_times = store.getState().appointment_time
  appointment_times.forEach(time => {
    destroyReservation(site_id, time.reservation_key).then(() => {
      console.log('Reservation deleted')
    })
  })
  return {
    type: RESET_APPOINTMENT_TIMES
  }
}

export function setHideDayAndSlotPicker() {
  return {
    type: HIDE_DAY_AND_SLOT_PICKER
  };
}

export function setHideDataEdit() {
  return {
    type: HIDE_DATA_EDIT
  };
}

export function setTouchscreenMode(value) {
  return {
    type: TOUCHSCREEN_MODE,
    payload: value
  };
}

export function storeBookingSessionKey(booking_session_key) {
  return {
    type: STORE_BOOKING_SESSION_KEY,
    payload: booking_session_key
  };
}

export async function createWaitingListEntry(site_id, info_requests, email, locale, recaptcha_token, not_publicy_listed_token, service_id, subtask_items) {
  try {
    const response = await api_v4.post(
      `/${site_id}/waiting_list_entries/create`, {
        locale: locale,
        not_publicy_listed_token: not_publicy_listed_token,
        booking_session_key: store.getState().ui.booking_session_key,
        info_field_results: info_requests,
        email: email,
        recaptcha_token: recaptcha_token,
        service_id: service_id,
        subtask_items: subtask_items
      }
    )
    const responseBody = await response

    if (responseBody.data) {
      return{
        type: WAITING_LIST_ENTRY_SUCCESS,
        payload: responseBody.data,
      }
    }
  } catch (error) {
    return {
      type: WAITING_LIST_ENTRY_FAILED,
      payload: handleErrors(error),
    }
  }
}

export const setPrinterStatus = (desk, touchscreen_id, printer_online) => {
  instance_frontend_v1.post(`/desks/${desk}/printer_status`, {desk: {'printer_online': printer_online, 'touchscreen_id': touchscreen_id}}, {timeout: 10000}).then(
    (response) => {
      console.log('printer status', response.data)
    },
    (error) => {
      console.log('printer status error', error)
    }
  )
}

export const keepAlive = () => {
  window.parent.postMessage({
    component: 'general',
    command: 'keep-alive',
    data: {
      state: "ok",
      message: "string"
    }
  }, '*');
}

export const printAppointmentSamsung = (appointment) => {
  window.parent.postMessage({
    component: 'printer',
    command: 'print_appointment_booking',
    data: appointment
  }, '*');
}

// ======== Helpers ===============>

const handleErrors = (error) => {
  let error_object = {}

  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    error_object = {
      code: error.response.data.error,
      message: error.response.data.message,
    }
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    error_object = {
      code: error.request.status,
      message: error.request.statusText,
    }
  } else {
    // Something happened in setting up the request and triggered an Error
    error_object = {
      code: error.status,
      message: error.message,
    }
  }

  return error_object
}

const chunksParser = body => {
  return body
    .replace(/^(\w{1,3})\r\n/, "") // replace header chunks info
    .replace(/\r\n(\w{1,3})\r\n/, "") // replace in-body chunks info
    .replace(/(\r\n0\r\n\r\n)$/, ""); // replace end chunks info
};
