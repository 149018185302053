import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { injectIntl } from "react-intl";

const query = new URLSearchParams(window.location.search);
const subtask_id = query.get('subtask_id') ? parseInt(query.get('subtask_id')) : null

class OnlyOnePersons extends Component {
  createMarkup(markup) {
    return { __html: markup };
  }

  componentDidMount() {
    this.props.updatePersons(
      this.props.subtask.id,
      1,
      this.props.subtask.name,
      true,
      this.props.subtask.initial_minutes,
      this.props.subtask.next_minutes
    );
  }
  handleClick() {
    this.props.resetAppointmentTimes();
    this.props.removedSubtask(this.props.subtask);
    this.props.resetPersons(this.props.subtask.id);
    this.props.selectedDay('hidden');
    this.props.selectedSlot('hidden');
    this.props.selectedSlotTimeOfSlot('hidden');
  }
  render() {
    const {intl} = this.props
    return (
      <div
        className={`container animated fadeIn ${
          this.props.site_info.drop_shadows ? 'drop-shadow' : null
        }`}
        style={
          !this.props.selected_service_data.appointment_only_one_subtasks
            ? null
            : subtask_id ? null : { display: 'none' }
        }>
        <div className="row">
          <div className="col-md-12 issues_header_left no_tab">
            <span>{this.props.site_info.info_num_task_header}</span>
          </div>
        </div>
        <div
          className="row inline-shadow"
          style={{
            backgroundColor: this.props.site_info.content_background,
            color: this.props.site_info.content_text
          }}>
          <div style={{ marginTop: '15px' }} />
          <div className="left-spacer" style={{ marginTop: '-15px' }}>
            <div className="col-lg-4 mobile-paddings-persons">
              <button
                className={`btn ${
                  this.props.site_info.drop_shadows ? 'drop-shadow' : null
                }`}
                style={{
                  visibility: 'hidden',
                  height: '36px',
                  marginTop: '-30px',
                  width: '40px',
                  border: '1px solid #CBCBCBFF',
                  background: 'rgb(221, 221, 221)',
                  color: 'rgb(150, 150, 150)'
                }}>
                <div
                  style={{
                    fontSize: '3rem',
                    marginTop: '-14px',
                    marginLeft: '0px',
                    fontWeight: '600'
                  }}>
                  -
                </div>
              </button>
              <Fragment>
                <span
                  style={{
                    fontSize: '3.5rem',
                    marginLeft: '20px',
                    marginRight: '20px'
                  }}>
                  1
                </span>
              </Fragment>
              <button
                className={`btn ${
                  this.props.site_info.drop_shadows ? 'drop-shadow' : null
                }`}
                style={{
                  visibility: 'hidden',
                  height: '36px',
                  marginTop: '-30px',
                  width: '40px',
                  border: '1px solid #CBCBCBFF',
                  background: 'rgb(221, 221, 221)',
                  color: 'rgb(150, 150, 150)'
                }}>
                <div
                  style={{
                    fontSize: '3rem',
                    marginTop: '-14px',
                    marginLeft: '-3px',
                    fontWeight: '600'
                  }}>
                  +
                </div>
              </button>
              {!subtask_id ?
                  <button
                      className={`btn ${
                          this.props.site_info.drop_shadows ? 'drop-shadow' : null
                      }`}
                      onClick={this.handleClick.bind(this)}
                      style={{
                        marginLeft: '20px',
                        marginTop: '-20px',
                        background: this.props.site_info.delete_button_background,
                        color: this.props.site_info.delete_button_text,
                        border: '1px solid #CBCBCBFF'
                      }}>
                      {intl.formatMessage({id: "persons.remove"})}
                  </button> : null
              }
            </div>
            <div
              className={`pull-left ${'subtask-name'}`}
              style={{ marginRight: '20px' }}>
              <p className="mobile-paddings-subtask-name" dangerouslySetInnerHTML={this.createMarkup(this.props.subtask.name)}></p>
            </div>
          </div>
        </div>
        {this.props.site_info.info_num_task_footer &&
        this.props.site_info.info_num_task_footer.length ? (
          <div
            className="row"
            style={{
              backgroundColor: this.props.site_info.heading_background,
              color: this.props.site_info.heading_text,
              borderBottom: '1px solid #CBCBCBFF',
              borderLeft: '1px solid #CBCBCBFF',
              borderRight: '1px solid #CBCBCBFF'
            }}>
            <div className="col-md-12">
              <p style={{ marginLeft: '-5px', marginTop: '8px' }}>
                {this.props.site_info.info_num_task_footer}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    days: state.days,
    selected_service: state.user_selected_service,
    selected_service_data: state.user_selected_service_data,
    selected_subtask: state.user_selected_subtask,
    selected_day: state.user_selected_day,
    user_person_count: state.user_count_persons
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(OnlyOnePersons)
);
