import {
  RESET_WAITING_LIST_ENTRY,
  CREATE_WAITING_LIST_ENTRY,
  WAITING_LIST_ENTRY_SUCCESS
} from '../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case RESET_WAITING_LIST_ENTRY:
      return {};
    case CREATE_WAITING_LIST_ENTRY:
      // Take the existing state and concatenate to the new list of days
      return action.payload.data;
    case WAITING_LIST_ENTRY_SUCCESS:
      // Take the existing state and concatenate to the new list of days
      return action.payload;
    default:
      return state;
  }
}
