import {
  USER_SELECTED_SUBTASK_GROUP,
  USER_RESET_SUBTASK_GROUP
} from '../actions/types';

export default function(state = 'hidden', action) {
  switch (action.type) {
    case USER_SELECTED_SUBTASK_GROUP:
      // Take the existing state and concatenate to the new list of days
      return action.payload;
    case USER_RESET_SUBTASK_GROUP:
      // Take the existing state and concatenate to the new list of days
      return 'hidden';
    default:
      return state;
  }
}
