import en from './en-GB'
import de from './de-DE'
import dk from './dk-DA'
import no from './nb-NO'
import gr from './gr-EL'
import lb from './lb-LU'
import ua from './uk-UA'
import ru from './ru-RU'
import fr from './fr-FR'
import se from './sv-SE'
import fa from './fa'
import ar from './ar-SA'
import tr from './tr-TR'

export default {
  ...en,
  ...de,
  ...dk,
  ...fr,
  ...gr,
  ...lb,
  ...no,
  ...se,
  ...ru,
  ...ua,
  ...fa,
  ...ar,
  ...tr
}