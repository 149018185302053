import { LOCALES } from '../locales'

export default {
    [LOCALES.DUTCH]: {
        "issues": {
            "browser_header": "Du skal blot bestille din aftale online.",
            "what_is_cleverq": "Hvad er cleverQ",
            "additional_issues": "Vælg yderligere bekymringer"
        },
        "app": {
            "header": "Hvad er cleverQ",
            "important": "Aktuel information"
        },
        "persons": {
            "header": "Angiv, hvor mange personer du ønsker at bestille tid til.",
            "information": "<b style={{ color: 'red' }}>BEMÆRK:</b> Du kan kun bestille én tid - også selv om du skal indgive en ansøgning og har behov for en tilbagerejsetilladelse. Vi vil håndtere begge dele samtidigt.",
            "continue": "Fortsæt",
            "remove": "Fjern",
            "to_much_time": "Antallet af problemer og processer overstiger den tilladte tidsbegrænsning. Reducer dit valg."
        },
        "datepicker": {
            "header": "Vælg den ønskede dag.",
            "mon": "Man",
            "tue": "Tir",
            "wed": "Ons",
            "thu": "Tor",
            "fri": "Fre",
            "sat": "Lør",
            "sun": "Søn",
            "no_appointments": "Desværre ingen datoer til rådighed. Prøv igen senere.",
            "error": "Der var et problem med anmodningen om aftale. Prøv igen senere."
        },
        "slotpicker": {
            "header": "Vælg den ønskede dag.",
            "no_appointments": "Der er ingen datoer tilgængelige på denne dag.",
            "legend_green": "ledige tider",
            "legend_yellow": "Få ledige tider",
            "legend_grey": "Ingen ledige tider",
            "timeout_reached": "The connection to the server was interrupted, please try again."
        },
        "dataforms": {
            "ident_number": "Customer number",
            "ident_number_text": "Please enter your customer number so that you can confirm your appointment on site with your customer card.",
            "mandatory_fields": "Obligatoriske felter",
            "header": "Indtast dine kontaktoplysninger.",
            "data_delete": "Dine kontaktoplysninger vil automatisk blive slettet fra cleverQ efter den bestilte tid.",
            "ms": "Fru",
            "mr": "Herre",
            "salutation": "Hilsen",
            "company": "Firmanavn",
            "given_name": "Fornavn",
            "surname": "Efternavn",
            "birthday": "Fødselsdag | Format: dd.mm.åååå",
            "email": "E-mail",
            "email_confirmation": "Gentag E-mail",
            "email_optional": "Valgfri E-mail",
            "email_confirmation_optional": "Valgfri Gentag email",
            "summary": "Din bestilling:",
            "phone": "Telefon",
            "tel_information": " ",
            "appointment_for": " ",
            "appointment_around": " kl.",
            "appointment_for_only": "til ",
            "on": "den",
            "clock": " ",
            "message": "Yderligere oplysninger ifm. din aftale",
            "book_appointment": "Bestil tid nu",
            "book_appointment_not_valid": "Udfyld alle markerede obligatoriske felter",
            "book_appointment_started": "Vent til aftalen er reserveret ...",
            "book_appointment_failed": "Aftalebestilling mislykkedes. Klik igen.",
            "terms_and_conditions": "Privat Politik",
            "terms_and_conditions_text_for_confirm": "By using the online appointment booking service, you agree that the personal data you provide will be processed by us. <br/> <br/> All personal data will only be stored for the purpose of processing the appointment.",
            "terms_and_conditions_link": "Link to privacy policy",
            "sms_notifications_label": "SMS-Notofications",
            "sms_notifications_checkbox_text": "I would like to be kept informed about my appointment via SMS notifications.",
            "sms_notifications_text": "We will not call you, nor will your mobile number be used for any other purpose.",
            "back": "Back",
            "cancel": "Cancel",
            "close_privacy_policy": "Close privacy policy",
            "email_info": "In order that the appointment confirmation and any other necessary documents can be sent to you by e-mail, <br/> it is necessary to provide your e-mail address. If you do not provide it, the use of the online appointment system is unfortunately not possible.",
            "confirm_policy": "Please confirm the privacy policy",
            "policy": "I have read the data protection information and agree to my data being processed.",
            "show_policy": "Show privacy policy"
        },
        "forms": {
            "bday": {
                "day": "Dag",
                "month": "Måned",
                "year": "År"
            },
            "card": {
                "bg": "BG",
                "bg_number": "BG number"
            },
            "email_confirm": "gentagelse",
            "honorific_prefix": {
                "mister": "Hr.",
                "miss": "Fr.",
                "other": ""
            },
            "validations": {
                "default": {
                    "required": "Denne information er nødvendig.",
                    "min": "Indtast mindst to tegn.",
                    "max": "For mange tegn.",
                    "letter_only": "Kun bogstaver er tilladt i dette felt.",
                    "numbers_only": "Kun tal er tilladt i dette felt."
                },
                "number": {
                    "min": "Indtast et tal højere end 0.",
                    "max": "Indtast et tal mindre end 100000."
                },
                "email": {
                    "required": "Indtast din e-mail adresse.",
                    "not_matching": "Den indtasted e-mail adresse passer ikke.",
                    "not_a_valid_email": "Indtast en gyldig e-mail adresse."
                },
                "options": {
                    "required": "Vælg en mulighed."
                },
                "name": {
                    "required": "Indtast navn."
                },
                "given_name": {
                    "required": "Indtast fornavn."
                },
                "family_name": {
                    "required": "Indtast efternavn."
                },
                "honorific_prefix": {
                    "required": "!NOT USED IN DENMARK!"
                },
                "street_address": {
                    "required": "Adresse"
                },
                "postal_code": {
                    "required": "Postnummer"
                },
                "city": {
                    "required": "By"
                },
                "country_name": {
                    "required": "Land"
                },
                "bday": {
                    "day": {
                        "required": "Indtast fødsels dag.",
                        "type_error": "Indtast en værdi mellem 1 og 31.",
                        "min": "Dette er ikke en gyldig kalender dag.",
                        "max": "Dette er ikke en gyldig kalender dag."
                    },
                    "month": {
                        "required": "Indtast fødsels måned.",
                        "type_error": "Indtast en værdi mellem 1 og 12.",
                        "min": "Dette er ikke en gyldig kalender måned.",
                        "max": "Dette er ikke en gyldig kalender måned."
                    },
                    "year": {
                        "required": "Indtast fødsels år.",
                        "type_error": "Indtast en værdi mellem 1880 og ${year}.",
                        "min": "Indtast en værdi mellem 1880 og 2020.",
                        "max": "Indtast en værdi mellem 1880 og 2020."
                    }
                },
                "card": {
                    "bg": {
                        "required": "Please enter the left side of BG number.",
                        "type_error": "Please enter a value with 5 symbols.",
                        "min": "This is not a valid left side of BG number.",
                        "max": "This is not a valid left side  of BG number."
                    },
                    "bg_number": {
                        "required": "Please enter the right side of BG number.",
                        "type_error": "Please enter a value for right side with 7 symbols.",
                        "min": "This is not a valid right side of BG number.",
                        "max": "This is not a valid right side  of BG number."
                    }
                },
                "sex": {
                    "required": "Køn."
                },
                "tel": {
                    "required": "Telefonnummer.",
                    "not_valid": "Indtast et gyldigt telefonnummer."
                },
                "terms_and_conditions": "Accepter venligst vores Privat Politik.",
                "ident_number.required": "Please enter your customer number."
            }
        },
        "appointment_info": {
            "at": "på",
            "location": "Placering",
            "header": "Din aftale Opsummering",
            "title": "Du har nu bestilt en tid.",
            "title_reserved": "Du har nu bestilt en tid.",
            "title_on_site_reserved": "Du har nu bestilt en tid.",
            "title_desk_reserved": "Du har nu bestilt en tid.",
            "infotext": "<h4>Push-notifikation på din mobiltelefon</h4><p> Hvis du scanner QR koden med CleverQ App på din mobiltelefon, vil du modtage en påmindelse om din tid via push-notifikation.</p>",
            "confirmtext": "<h4>Bemærkninger til aftalebekræftelse</h4><p> Du har reserveret en aftale hos os, og for at gøre denne reservation bindende, er det nødvendigt, at du bekræfter den i den mail, som vi har sendt til dig.\nDu skal derfor klikke på linket i denne mail.\nNår du har gjort dette, bekræfter du aftalen med os, og den bliver bindende.\nBemærk: Hvis du ikke klikker på linket vil din reservation automatisk blive slettet.</p>",
            "on_site_confirmtext": "<h4>Bekræftelse, når du møder op hos os:</h4><p> Husk at medbringe din reservations kode, når du møder op til aftalen!\nVed fremmødet skal du indtaste reservationskoden på tastaturet i nummerstanderen, så vi kan se, at du er ankommet.</p>",
            "desk_confirmtext": "<h4>Medarbejder bekræftelse af aftale</h4><p> Vi har videresendt dit ønske om en aftale til en medarbejder. \nNår denne har bekræftet aftalen, vil du modtage en mail, som bekræfter aftalen.</p>",
            "reservation_code": "Reservations kode",
            "summary": "Opsummering",
            "issue": "Ærinde",
            "appointment": "Sted og tid",
            "persons": "personer.",
            "person": "person.",
            "name": "Navn",
            "birthday": "Fødselsdag",
            "email": "E-Mail",
            "phone": "Telefon",
            "message": "Meddelelse",
            "back_button": "Tilbage til",
            "print_button": "Print",
            "advertising": "<h4>cleverQ App til din mobiltelefon</h4><p>Download den gratis cleverQ App og udnyt din mobiltelefons fordele. <br/>Bestil tid, træk et kø-nummer og få Push meddelelser på din mobiltelefon. <br/>Så er du altid opdateret.</p>",
            "advertising_image": "/images/smartphone_english.png",
            "duplicate_info_title": "Aftale duplikere",
            "duplicate_info_title_remove": "Slet venligst den allerede oprettede aftale",
            "duplicate_info_text": "Du har tidligere oprettet en aftale. Slet denne gamle aftale før du opretter en ny aftale. Du kan slette din gamle aftale i den e-mail, som var send til dig i forbindelse med den gamle aftale.",
            "slot_already_booked_title": "Oprettelse af en aftale er ikke mulig.",
            "slot_already_booked_text": "Dit ønskede valg af dato er desværre optaget af en anden i mellemtiden. Vi beklager ulejligheden, men må bede dig om at vælge en anden dato."
        },
        "navigation": {
            "cleverq": "Because who likes to wait?",
            "legal_notice": "Om os",
            "policy": "Databeskyttelse"
        },
        "greetings": {
            "confirm_greetings": "Fortsæt med aftale booking"
        },
        "checkout_title": {
            "your": "Dit",
            "choice": "valg"
        },
        "enter_invite_code": {
            "verify": "Verificere",
            "invite_code": "invitationkode"
        },
        "appointment_times": {
            "at": "den ",
            "on": "kl. ",
            "o_clock": "Ur",
            "header": "Din reserverede aftaltid.",
            "remove": "Vælg nyt aftaletidspunkt",
            "session": {
                "text": "Din session kører i: ",
                "close_error_modal": "Tæt",
                "reservation_expired": "Reservationen er udløbet. Vælg et nyt tidspunkt.",
                "expired": "Din session er udløbet."
            }
        }
    }
}