import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from "react-intl";

const style = {
  backgroundColor: 'white',
  padding: '10px',
  borderRadius: '3px'
};

class Header extends Component {
  render() {
    const { logo_align, custom_header, name } = this.props.site_info;
    return (
      !custom_header ?
        <div className="container animated fadeIn">
          <span className="site_name no_tab">{name}</span>
          <div className="row">
            <div
              className={
                logo_align === 'right'
                  ? 'col-6 pull-right'
                  : logo_align === 'left'
                  ? 'col-6 pull-left'
                  : 'center-align'
              }
              style={this.props.site_info.logo_frame ? style : null}>
              <div style={{ marginLeft: '5px', marginTop: '0px' }}>
                {this.props.site_info.logo !== '/logos/original/missing.png' ?
                  <img
                    className="header-logo no_tab"
                    style={{ height: this.props.site_info.logo_height + 'px' }}
                    src={this.props.site_info.logo}
                    alt="logo"
                  /> : null
                }
              </div>
            </div>
          </div>
        </div> : <span className="site_name no_tab">{name}</span>
    );
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    days: state.days,
    selected_service: state.user_selected_service,
    selected_day: state.user_selected_day,
    selected_subtask: state.user_selected_subtask,
    persons: state.user_count_persons
  };
}

export default injectIntl(connect(mapStateToProps)(Header));
