import React, { Component, Fragment } from 'react'
import { useFormikContext, Formik, Form, Field } from 'formik'
import { connect } from 'react-redux';
import * as actions from '../../actions';
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { CustomField } from './elements/FormTypes'
import {
  validateText,
  validateNumber,
  validateTextArea,
  validateOptions,
  validateCheckBox,
  validateHonorificPrefix,
  validateName,
  validateGivenName,
  validateFamilyName,
  validateEmailRequired,
  validateEmail,
  validateTel,
  validateBirthday,
  validateStreetAddress,
  validatePostalCode,
  validateCity,
  validateCountryName,
  validateTermsAndConditions
} from './elements/Validations'
import styles from '../../styles/dataforms.module.css'
import styled from 'styled-components'
import moment from 'moment'
import ReCAPTCHA from "react-google-recaptcha";
import ReactCountdownClock from 'react-countdown-clock'
import Modal from "react-modal";
import Helmet from "react-helmet";
import {animateScroll as scroll} from "react-scroll";
import { injectIntl } from "react-intl";
let schema_array = []
let initialValues = {}

const query = new URLSearchParams(window.location.search);
const not_publicy_listed_token = query.get('hash') ? query.get('hash') : ''
let language;

class DataFormsWaitingListEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      bookmark_reserve_started: false,
      recaptcha_token: null,
      show_retry_button: false,
      show_error_modal: false,
      modal_error_message: '',
      show_data_fields: false
    }
  }
  closeErrorModal = () => {
    window.location.reload()
  }
  onChangeRecaptcha = (value) => {
    this.setState({ recaptcha_token: value })
  }
  showRetryButton = () => {
    this.setState({ show_retry_button: true })
  }
  showDataFields = (e) => {
    e.preventDefault();
    this.setState({ show_data_fields: true })
    scroll.scrollToBottom();
  }
  createMarkup(markup) {
    return { __html: markup };
  }
  render() {
    language = query.get('lang') && query.get('lang').length > 1  ? query.get('lang') : this.props.default_locale
    const {
      site_info,
      selected_service_data,
      session_info,
      selected_service,
      selected_service_name,
      selected_subtask,
      user_person_count,
      selected_language,
      recaptcha_site_key,
      intl} = this.props
    const Button = styled.button`
      background: ${this.props.site_info.confirm_button_background};
      color: ${this.props.site_info.confirm_button_text};
      border: ${this.props.site_info.border_on_items ? '1px solid black !important' : 'none'};
      text-align: left;
      font-size: 1.1em;
      padding: 13px;
      font-weight: 600;
      &:hover {
        background-color: ${this.props.site_info.button_hover} !important;
        color: ${this.props.site_info.button_text_hover} !important;
        transition: 500ms;
      }
    `;
    let rows = []
    let fields = []

    const group_by_row = _.groupBy(selected_service_data.info_fields, 'row')
    const convert_group_by_row = Object.keys(group_by_row).map((key) => {
      return group_by_row[key]
    })
    let root = document.documentElement
    root.style.setProperty('--selected-color', site_info.button_active)
    root.style.setProperty('--checkbox-arrow', site_info.button_text_active)
    root.style.setProperty('--selected-border-color', site_info.button_active)
    convert_group_by_row.forEach((row, i) => {
      row.map((field) => {
        if (!field.desk_only) {
          validationMapper(
            field.id,
            field.type,
            field.mandatory,
            field.mandatory_for_users,
            session_info.isUser,
            this.props.user_infos ? JSON.parse(this.props.user_infos) : null,
            this.props.ui.validated.user_infos)
          if (row.length < 2) {
            fields.push(
              field.type === 'email' ? (
                email_helper(field, site_info.drop_shadows, session_info.isUser, this.props.ui.validated.user_infos, site_info.send_confirm_request_booked_as_user, site_info.custom_email_required_text, intl)
              ) : (
                <Fragment key={uuidv4()}>
                  <div className={styles['col-half']}>
                    {field.column === 1 ? (
                      <Field
                        name={field.id}
                        as={CustomField}
                        type={field.type}
                        label={field.label}
                        required={field.mandatory}
                        shadow={site_info.drop_shadows}
                        options={field.options}
                        readOnly={false}
                      />
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </div>
                  <div className={styles['col-half']}>
                    {field.column === 2 ? (
                      <Field
                        name={field.id}
                        as={CustomField}
                        type={field.type}
                        label={field.label}
                        required={field.mandatory}
                        shadow={site_info.drop_shadows}
                        options={field.options}
                        readOnly={false}
                      />
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </div>
                </Fragment>
              )
            )
          } else if (row.length > 2) {
            fields.push(
              field.type === 'email' ? (
                <div key={uuidv4()} className={styles['col-third']}>
                  {email_helper(field, site_info.drop_shadows, session_info.isUser, this.props.ui.validated.user_infos, site_info.send_confirm_request_booked_as_user, site_info.custom_email_required_text, intl)}
                </div>
              ) : (
                <div key={uuidv4()} className={styles['col-third']}>
                  <Field
                    name={field.id}
                    as={CustomField}
                    type={field.type}
                    label={field.label}
                    required={field.mandatory}
                    shadow={site_info.drop_shadows}
                    options={field.options}
                    readOnly={false}
                  />
                </div>
              )
            )
          } else {
            fields.push(
              field.type === 'email' ? (
                <div key={uuidv4()}>{email_helper(field, site_info.drop_shadows, session_info.isUser, this.props.ui.validated.user_infos, site_info.send_confirm_request_booked_as_user, site_info.custom_email_required_text, intl)}</div>
              ) : (
                <div key={uuidv4()} className={styles['col-half']}>
                  <Field
                    name={field.id}
                    as={CustomField}
                    type={field.type}
                    label={field.label}
                    required={field.mandatory}
                    shadow={site_info.drop_shadows}
                    options={field.options}
                    readOnly={false}
                  />
                </div>
              )
            )
          }
        }
        return null
      })
      rows.push(
        <div key={uuidv4()} className={styles['row-forms']}>
          {fields}
        </div>
      )
      fields = []
    })

    let isSubmitLocked = false;

    const modalCustomStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        width                 : '50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        zIndex: 110
      },
    };
    return(
      <Fragment>
        <Helmet>
          <style>{`
            .issues_header_left, .slot_picker_header_left, .data_forms_header_left{
              background-color: ${this.props.site_info.heading_background};
              color: ${this.props.site_info.heading_text};
              font-size: 1.1em;
              font-weight: 600;
              padding: 8px;
              border: 1px solid #CBCBCBFF;
              border-bottom: none;
              border-radius: 5px 5px 0px 0px;
            }
          `}</style>
        </Helmet>
        <Formik
          initialValues={Object.assign(initialValues, { TermsAndConditions: site_info.show_policy_checkbox ? false : true })}
          validationSchema={mergeSchemas(schema_array)}
          onSubmit={( values, setSubmitting, resetForm) => {
            this.setState({ bookmark_reserve_started: true })
            let user_infos = []
            let email

            for (let [key, value] of Object.entries(values)) {
              let check = key.substring(0, 4) === 'bday' ? 'bday' : null
              switch (key) {
                case 'email':
                  email = value
                  user_infos.push({id: selected_service_data.info_fields.find(item => item.type === 'email').id, value: value})
                  break;
                default:
                  if (key !== 'TermsAndConditions' && key !== 'email_confirm' && !check) {
                    user_infos.push({id: parseInt(key), value: value})
                  }
                  if (check) {
                    let day = value.day.length < 2 ? '0' + value.day : value.day
                    let month = value.month.length < 2 ? '0' + value.month : value.month
                    let year = value.year
                    let item_id = value.item_id
                    user_infos.push({id: item_id, value: [day, month, year].join('.')})
                  }
              }
            }

            if (!isSubmitLocked) {
              isSubmitLocked = true;
              let response = this.props.createWaitingListEntry(
                this.props.site_info.id,
                _.sortBy(user_infos, item => item.id),
                email,
                language,
                this.state.recaptcha_token,
                not_publicy_listed_token,
                this.props.selected_service,
                convert_selected_subtasks(this.props.user_person_count),
              ).then(res => {
                this.setState({ booking_started: false })
                isSubmitLocked = false
                console.log(res.payload.code)
                if ([1, 3, 4, 5, 6, 7, 8, 9, 10 , 11, 12, 13, 14, 15, 16, 17, 18].includes(res.payload.code)) {
                  this.setState({ show_retry_button: false, show_error_modal: true, modal_error_message: res.payload.message })
                }
              }).catch(err => {
                isSubmitLocked = false
              })}

            this.setState({ submitted: true })
            if (this.props.waiting_list_entry.id) {
              isSubmitLocked = true
            }
          }}
        >
          {({ isValid, dirty }) => (
            this.state.show_data_fields ?
              <Fragment>
                <div
                  className={`container animated fadeIn ${
                    this.props.site_info.drop_shadows ? 'drop-shadow' : null
                  }`}>

                  <div className="row">
                    <div className="col-md-12 issues_header_left" style={{textAlign: 'left'}}>
                      {intl.formatMessage({id: "dataforms_waiting_list_entry.header"})}
                    </div>
                  </div>
                  <Form className="form-group">
                    <div
                      className="row inline-shadow data-forms-padding"
                      style={{
                        backgroundColor: site_info.content_background,
                        color: site_info.content_text,
                        textAlign: 'left'
                      }}>
                      {!(this.props.waiting_list_entry && this.props.waiting_list_entry.reservation_code) ?
                        <Fragment>
                          <div className="row">
                            <p style={{ marginLeft: '15px' }}>
                              {intl.formatMessage({id: "dataforms.data_delete"})}
                              <span style={{float: 'right', marginRight: '15px'}}><span style={{color: 'red'}}>*</span> {intl.formatMessage({id: 'dataforms.mandatory_fields'})}</span>
                            </p>
                          </div>

                          {rows}

                          <hr />
                          <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col-md-6">
                              <p
                                style={{
                                  fontSize: '1.1em',
                                  marginTop: '15px',
                                  color: site_info.summary_text_color
                                }}>
                                {intl.formatMessage({id: 'dataforms.summary'})}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p style={{fontSize: '1.1em'}}>{intl.formatMessage({id: 'dataforms.tel_information'})}</p>
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: '0px' }}>
                            <div className="col-md-6">
                      <span
                        style={{ color: site_info.summary_text_color }}>
                        {intl.formatMessage({id: 'dataforms.appointment_for'})}{' '}
                        <ul className="list-group">
                          {user_person_count.length ? (
                            user_person_count.map(item => {
                              if (item.number > 0) {
                                return (
                                    <li
                                        key={item.subtask_id}
                                        style={{fontSize: '1.1em'}}
                                        className={`list-group-item d-flex justify-content-between align-items-center ${
                                            site_info.drop_shadows
                                                ? 'drop-shadow'
                                                : null
                                        }`}>
                                      <span dangerouslySetInnerHTML={this.createMarkup(item.name)}/>
                                      <span className="badge badge-primary badge-pill">
                                      {item.number}
                                    </span>
                                    </li>
                                );
                              } else {
                                return null;
                              }
                            })
                          ) : (
                            <li
                              style={{fontSize: '1.1em'}}
                              className={`list-group-item d-flex justify-content-between align-items-center ${
                                site_info.drop_shadows
                                  ? 'drop-shadow'
                                  : null
                              }`}>
                              {selected_service_name}
                            </li>
                          )}
                        </ul>
                        <div style={{fontSize: '1.1em'}}>
                          {site_info.location_text_in_summary}
                        </div>
                      </span>
                            </div>
                          </div>

                          <div className={styles['row-forms']}>
                            <hr />
                            { site_info.show_policy_checkbox ?
                              <Field
                                name={'TermsAndConditions'}
                                as={CustomField}
                                type={"TermsAndConditions"}
                                label={intl.formatMessage({id: 'dataforms.terms_and_conditions'})}
                                policy_text={createMarkup(
                                  site_info.policy_text
                                )}
                                shadow={site_info.drop_shadows}
                              /> :
                              <div>
                                <p style={{fontSize: '1.1.em'}}>
                                  {intl.formatMessage({id: 'dataforms.terms_and_conditions_text_for_confirm'})}
                                </p>
                                <p><a href={site_info.customer_policy_url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({id: 'dataforms.terms_and_conditions_link'})}</a></p>
                              </div>
                            }
                          </div>

                          <div className="row">
                            <div className="col-md-12" style={{ marginTop: '5px', marginLeft: site_info.recaptcha && !session_info.isUser ? '-15px' : 0 }}>
                              {(isValid && dirty || this.state.recaptcha_token) ? site_info.recaptcha && !session_info.isUser ?
                                <div style={{marginBottom: '10px'}} className="col-md-6">
                                  <ReCAPTCHA
                                    sitekey={recaptcha_site_key}
                                    onChange={this.onChangeRecaptcha}
                                    size="normal"
                                  />
                                </div> : null : null
                              }
                              {this.state.recaptcha_token || !site_info.recaptcha || session_info.isUser ? isValid && dirty ?
                                !this.props.waiting_list_entry.reservation_code ?
                                  <Button
                                    type="submit"
                                    disabled={false}
                                    className={`col-md-6 ${
                                      site_info.drop_shadows ? 'drop-shadow' : null
                                    }`}>
                                    {site_info.use_icons && !this.state.bookmark_reserve_started ? <span dangerouslySetInnerHTML={createMarkup(
                                      site_info.icon_confirm_button
                                    )} style={{paddingRight: '10px', textAlign: this.state.bookmark_reserve_started ? 'center' : 'left'}}/> : null}{this.state.bookmark_reserve_started ? <div><i className='fa fa-sync-alt fa-spin' style={{marginRight: '15px'}}></i>{intl.formatMessage({id: 'dataforms_waiting_list_entry.waiting_list_entry_started'})}</div> : intl.formatMessage({id: 'dataforms_waiting_list_entry.register_waiting_list_entry'})}
                                  </Button> :
                                  this.state.show_retry_button ?
                                    <Button
                                      type="submit"
                                      className={`col-md-6 ${
                                        site_info.drop_shadows ? 'drop-shadow' : null
                                      }`}>
                                      {site_info.use_icons && !this.state.bookmark_reserve_started ? <span dangerouslySetInnerHTML={createMarkup(
                                        site_info.icon_confirm_button
                                      )} style={{paddingRight: '10px', textAlign: this.state.bookmark_reserve_started ? 'center' : 'left'}}/> : null}{intl.formatMessage({id: 'dataforms_reserve.bookmark_failed'})}
                                    </Button> :
                                    <ReactCountdownClock seconds={6}
                                                         color="#000"
                                                         alpha={1}
                                                         size={60}
                                                         onComplete={this.showRetryButton} />
                                : null : null
                              }
                            </div>
                            {!this.state.submitted && !isValid ?
                              <div className="col-md-6" style={{ marginTop: '5px' }}>
                                <span style={{color: 'red', fontSize: '1.1em'}}>{intl.formatMessage({id: 'dataforms_waiting_list_entry.waiting_list_entry_not_valid'})}</span>
                              </div>
                              : null
                            }
                          </div>
                        </Fragment> : <span style={{color: 'green', fontSize: '1.1em', fontWeight: 'bold'}}>{intl.formatMessage({id: 'dataforms_waiting_list_entry.successfull_registered'})}</span>
                      }
                    </div>
                  </Form>
                </div>
              </Fragment> :
              <div
                className={`container animated fadeIn ${
                  this.props.site_info.drop_shadows ? 'drop-shadow' : null
                }`}>

                <div className="row">
                  <div className="col-md-12 issues_header_left" style={{textAlign: 'left'}}>
                    {intl.formatMessage({id: "dataforms_waiting_list_entry.header"})}
                  </div>
                </div>

                <div
                  className="row inline-shadow data-forms-padding"
                  style={{
                    backgroundColor: site_info.content_background,
                    color: site_info.content_text,
                    textAlign: 'left'
                  }}>
                  <div style={{paddingBottom: '10px'}}>
                    <span dangerouslySetInnerHTML={createMarkup(this.props.selected_service_data.waiting_list_description)} />
                  </div>
                  <Button
                    onClick={(e) => this.showDataFields(e)}
                    className={`col-md-4 ${
                      site_info.drop_shadows ? 'drop-shadow' : null
                    }`}>
                    {intl.formatMessage({id: 'dataforms_waiting_list_entry.go_to_waiting_list_entry'})}
                  </Button>
                </div>


              </div>

          )}
        </Formik>
        <Modal
          isOpen={this.state.show_error_modal}
          onRequestClose={this.closeErrorModal.bind(this)}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          style={modalCustomStyles}
        >
          <span style={{fontWeight: 'bold', color: 'red', fontSize: '1.6rem'}}>{this.state.modal_error_message}</span><br/>

          <button
            className={`btn ${
              this.props.site_info.drop_shadows ? 'drop-shadow' : null
            }`}
            onClick={this.closeErrorModal}
            style={{
              marginTop: '10px',
              background: this.props.site_info.confirm_button_background,
              color: this.props.site_info.confirm_button_text,
              border: '1px solid #CBCBCBFF'
            }}>
            {intl.formatMessage({id: 'appointment_times.session.close_error_modal'})}
          </button>
        </Modal>
      </Fragment>
    )
  }

}

const SaveDataformValues = (props) => {
  const { values } = useFormikContext()
  props.getDataFormValues(values)

  return null
}
const mergeSchemas = (array) => {
  const [first, ...rest] = array

  return rest
    .reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first)
    .concat(validateTermsAndConditions)
}

const validationMapper = (id, type, mandatory, mandatory_for_users, isUser, openid_user_infos) => {
  let schema
  switch (type) {
    case 'text':
      schema = validateText(id, mandatory)
      Object.assign(initialValues, { [id]: '' })
      break
    case 'number':
      schema = validateNumber(id, mandatory)
      Object.assign(initialValues, { [id]: '' })
      break
    case 'text_area':
      schema = validateTextArea(id, mandatory)
      Object.assign(initialValues, { [id]: '' })
      break
    case 'options':
      schema = validateOptions(id, mandatory)
      Object.assign(initialValues, { [id]: '' })
      break;
    case 'check_box':
      schema = validateCheckBox(id, mandatory)
      Object.assign(initialValues, { [id]: false })
      break
    case 'honorific_prefix':
      let prefix = ''
      if (openid_user_infos && openid_user_infos.gender && openid_user_infos.gender.length) {
        if (openid_user_infos.gender === 'male') {
          prefix = 'Herr'
        } else {
          prefix = 'Frau'
        }
      }
      schema = validateHonorificPrefix(id, mandatory)
      Object.assign(initialValues, { [id]: prefix })
      break
    case 'honorific_prefix_other':
      let prefix_other = ''
      if (openid_user_infos && openid_user_infos.gender && openid_user_infos.gender.length) {
        if (openid_user_infos.gender === 'male') {
          prefix_other = 'Herr'
        } else {
          prefix_other = 'Frau'
        }
      }
      schema = validateHonorificPrefix(id, mandatory)
      Object.assign(initialValues, { [id]: prefix })
      break
    case 'name':
      schema = validateName(id, mandatory)
      Object.assign(initialValues, { [id]: openid_user_infos ? openid_user_infos.name : ''  })
      break
    case 'given_name':
      schema = validateGivenName(id, mandatory)
      Object.assign(initialValues, { [id]: openid_user_infos ? openid_user_infos.given_name : '' })
      break
    case 'family_name':
      schema = validateFamilyName(id, mandatory)
      Object.assign(initialValues, { [id]: openid_user_infos ? openid_user_infos.family_name : ''  })
      break
    case 'email':
      schema = mandatory_for_users ? validateEmailRequired : isUser ? validateEmail : mandatory ? validateEmailRequired : validateEmail
      Object.assign(initialValues, { email: openid_user_infos ? openid_user_infos.email : ''  })
      Object.assign(initialValues, { email_confirm: openid_user_infos ? openid_user_infos.email : ''  })
      break
    case 'tel':
      let phone_number = null
      if (openid_user_infos) {
        if (openid_user_infos.phone_number.length) {
          phone_number = openid_user_infos.phone_number
        } else {
          phone_number = openid_user_infos.private_mobile
        }
      }
      schema = validateTel(id, mandatory)
      Object.assign(initialValues, { [id]: openid_user_infos ? phone_number && phone_number.length ? phone_number  : '' : '' })
      break
    case 'bday':
      let birthdate = null
      if (openid_user_infos && openid_user_infos.birthdate && openid_user_infos.birthdate.length) {
        birthdate = openid_user_infos.birthdate.split('-')
      }
      schema = validateBirthday(id, mandatory)
      Object.assign(initialValues, { ['bday_' + id]: birthdate ? { day: birthdate[2], month: birthdate[1], year: birthdate[0], item_id: id} : { day: '', month: '', year: '', item_id: id}})
      break
    case 'street_address':
      schema = validateStreetAddress(id, mandatory)
      Object.assign(initialValues, { [id]: openid_user_infos ? openid_user_infos.address.street_address : '' })
      break
    case 'postal_code':
      schema = validatePostalCode(id, mandatory)
      Object.assign(initialValues, { [id]: openid_user_infos ? openid_user_infos.address.postal_code : '' })
      break
    case 'city':
      schema = validateCity(id, mandatory)
      Object.assign(initialValues, { [id]: openid_user_infos ? openid_user_infos.address.locality : '' })
      break
    case 'country_name':
      schema = validateCountryName(id, mandatory)
      Object.assign(initialValues, { [id]: openid_user_infos ? openid_user_infos.address.country : '' })
      break
    default:
      console.log(type)
  }
  if (typeof schema === 'object') {
    schema_array.push(schema)
  }
}

const email_helper = (field, shadow, isUser, nil ,send_confirm_request_booked_as_user, custom_email_required_text, intl) => {
  let email = { value: '' }
  return (
    <Fragment key={uuidv4()}>
      { !isUser ?
        <p>
          {custom_email_required_text && custom_email_required_text.length > 0 ?
              <span dangerouslySetInnerHTML={createMarkup(custom_email_required_text)}/> :
              <span dangerouslySetInnerHTML={createMarkup(intl.formatMessage({id: 'dataforms.email_info'}))}/>}
        </p> : null
      }
      <div className={styles['col-half']}>
        <Field
          name={field.type}
          as={CustomField}
          type={'email'}
          label={field.label}
          required={field.mandatory_for_users ? true : isUser && !send_confirm_request_booked_as_user ? false : field.mandatory}
          shadow={shadow ? styles.drop_shadow : null}
          readOnly={false && (email.value.length > 0)}
        />
      </div>
      <div className={styles['col-half']}>
        <Field
          name={'email_confirm'}
          as={CustomField}
          type={'email_confirm'}
          label={field.label}
          required={field.mandatory_for_users ? true : isUser && !send_confirm_request_booked_as_user ? false : field.mandatory}
          shadow={shadow ? styles.drop_shadow : null}
          readOnly={false && (email.value.length > 0)}
        />
      </div>
    </Fragment>
  )
}

function createMarkup(markup) {
  return { __html: markup };
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    session_info: state.session_info,
    selected_service: state.user_selected_service,
    selected_service_name: state.user_selected_service_name,
    selected_service_data: state.user_selected_service_data,
    selected_subtask: state.user_selected_subtask,
    user_person_count: state.user_count_persons,
    selected_language: state.user_selected_language,
    ui: state.ui,
    waiting_list_entry: state.waiting_list_entry
  };
}

// Export and connection to the store.
export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(DataFormsWaitingListEntry)
);

const convert_selected_subtasks = (subtask_items) => {
  const booked_subtasks = subtask_items.map((item) => {
    return {
      subtask_id: item.subtask_id,
      number: item.number,
    }
  })

  return booked_subtasks
}