import { LOCALES } from '../locales'

export default {
    [LOCALES.FRENCH]: {
        "issues": {
            "browser_header": "Réservez votre rendez-vous tout simplement en ligne.",
            "what_is_cleverq": "C’est quoi cleverQ?",
            "additional_issues": "Veuillez remplir tous les champs obligatoires"
        },
        "app": {
            "header": "C’est quoi cleverQ?",
            "important": "Informations actuelles"
        },
        "persons": {
            "header": "Veuillez indiquer le nombre de personnes pour lesquelles vous voulez prendre rendez-vous",
            "information": "<b style={{ color: 'red' }}>Veuillez noter:</b> Vous ne pouvez réserver qu’un seul rendez-vous. Même si vous désirez soumettre votre candidature ainsi que votre demande d’autorisation d’entrée dans le pays en même temps. Bien que vous ne puissiez réserver un seul rendez-vous vos deux demandes seront traitées.",
            "continue": "Continuer pour demande de rendez-vous",
            "remove": "retirer",
            "to_much_time": "Si la durée autorisée du RV est dépassée, veuillez réduire votre choix."
        },
        "datepicker": {
            "header": "Veuillez choisir le jour.",
            "mon": "Lun",
            "tue": "Mar",
            "wed": "Mer",
            "thu": "Jeu",
            "fri": "Ven",
            "sat": "Sam",
            "sun": "Dim",
            "no_appointments": "Il n’y a aucun rdv de disponible. Veuillez réessayer encore une fois plus tard.",
            "error": "Il y a eu un problème avec la demande de rendez-vous, veuillez réessayer plus tard."
        },
        "slotpicker": {
            "header": "Veuillez choisir l’heure désirée.",
            "no_appointments": "Ce jour-là malheureusement aucun RV n’est disponible.",
            "legend_green": "Rendez-vous disponibles.",
            "legend_yellow": "Seulement quelques RVs de disponibles.",
            "legend_grey": "Pas de RV disponibles."
        },
        "dataforms": {
            "ident_number": "Customer number",
            "ident_number_text": "Please enter your customer number so that you can confirm your appointment on site with your customer card.",
            "mandatory_fields": "Champs obligatoires",
            "header": "Veuillez rentrer vos données personnelles.",
            "data_delete": "Vos données seront annulées automatiquement par cleverQ après votre RV.",
            "ms": "Madame",
            "mr": "Monsieur",
            "salutation": "Titre",
            "company": "Nom d’entreprise",
            "given_name": "Prénom",
            "surname": "Nom de famille",
            "birthday": "Anniversaire | Format: dd.mm.jjjj",
            "email": "adresse e-mail",
            "email_confirmation": "répéter l’adresse e-mail",
            "email_optional": "adresse e-mail optionnelle",
            "email_confirmation_optional": "répéter l’adresse e-mail optionelle",
            "summary": "résumé:",
            "phone": "n° de téléphone optionel",
            "tel_information": " ",
            "appointment_for": " ",
            "appointment_around": "à",
            "appointment_for_only": "pour",
            "on": "le",
            "clock": "heure",
            "message": "message optionnel pour votre RV.",
            "book_appointment": "Réserver RV fixe X maintenant",
            "book_appointment_not_valid": "Veuillez remplir tous les champs obligatoires.",
            "book_appointment_started": "Veuillez attendre que le rendez-vous soit fixé...",
            "book_appointment_failed": "La prise de rendez-vous a échoué, veuillez cliquer à nouveau.",
            "terms_and_conditions": "Règlement sur la protection des données",
            "sms_notifications_label": "SMS-Notofications",
            "sms_notifications_checkbox_text": "I would like to be kept informed about my appointment via SMS notifications.",
            "sms_notifications_text": "We will not call you, nor will your mobile number be used for any other purpose.",
            "back": "Back",
            "cancel": "Cancel",
            "close_privacy_policy": "Close privacy policy",
            "terms_and_conditions_text_for_confirm": "Par l'utilisation de la réservation de votre RV en ligne, vous donnez votre accord pour notre traitement de vos données personnelles. <br/> <br/> Toutes données personnelles seront sauvegardées uniquement afin de réserver votre RV.",
            "terms_and_conditions_link": "Lien pour la déclaration de protection de données",
            "email_info": "Pour que la confirmation de votre réservation ainsi que d'autres documents éventuellement nécessaires puissent vous être envoyés par courriel,<br/> l'indication de votre adresse e-mail est nécessaire. Si celle-ci n'est pas indiquée, l'utilisation de réservation de RV en ligne n'est malheureusement pas possible.",
            "confirm_policy": "Please confirm the privacy policy",
            "policy": "I have read the data protection information and agree to my data being processed.",
            "show_policy": "Show privacy policy"
        },
        "forms": {
            "bday": {
                "day": "Jour",
                "month": "Mois",
                "year": "Année"
            },
            "card": {
                "bg": "BG",
                "bg_number": "BG number"
            },
            "email_confirm": "répéter",
            "honorific_prefix": {
                "mister": "Monsieur",
                "miss": "Madame",
                "other": ""
            },
            "validations": {
                "default": {
                    "required": "Cette information est indispensable.",
                    "min": "Veuillez rentrer deux caractères au minimum.",
                    "max": "Trop de caractères.",
                    "letter_only": "Seules les lettres sont autorisées dans ce champ.",
                    "numbers_only": "Seuls les chiffres sont autorisés dans ce champ."
                },
                "number": {
                    "min": "Veuillez rentrer un chiffre plus grand que „0“.",
                    "max": "Veuillez rentrer un chiffre inférieur à 1.000.000."
                },
                "email": {
                    "required": "Veuillez rentrer votre adresse e-mail.",
                    "not_matching": "Les adresses e-mail ne correspondent pas.",
                    "not_a_valid_email": "Veuillez rentrer une adresse e-mail valide."
                },
                "options": {
                    "required": "Veuillez choisir une option."
                },
                "name": {
                    "required": "Veuillez entrer votre nom."
                },
                "given_name": {
                    "required": "Veuillez entrer votre prénom."
                },
                "family_name": {
                    "required": "Veuillez entrer votre nom de famille."
                },
                "honorific_prefix": {
                    "required": "Veuillez entrer votre titre."
                },
                "street_address": {
                    "required": "Veuillez entrer votre adresse."
                },
                "postal_code": {
                    "required": "Veuillez entrer votre code postal."
                },
                "city": {
                    "required": "Veuillez entrer votre lieu de résidence."
                },
                "country_name": {
                    "required": "Veuillez entrer votre pays d’origine."
                },
                "bday": {
                    "day": {
                        "required": "Veuillez entrer votre jour d’anniversaire.",
                        "type_error": "Veuillez entrer une valeur entre 1 et 31.",
                        "min": "Ceci n’est pas un jour valide.",
                        "max": "Ceci n’est pas un jour valide."
                    },
                    "month": {
                        "required": "Veuillez entrer votre mois de naissance.",
                        "type_error": "Veuillez entrer une valeur entre 1 et 12.",
                        "min": "Ceci n’est pas un mois valide.",
                        "max": "Ceci n’est pas un mois valide."
                    },
                    "year": {
                        "required": "Veuillez entrer votre année de naissance.",
                        "type_error": "Veuillez entrer une valeur entre 1880 et ${year}.",
                        "min": "Veuillez entrer une valeur entre 1880 et 2020.",
                        "max": "Veuillez entrer une valeur entre 1880 et 2020."
                    }
                },
                "card": {
                    "bg": {
                        "required": "Please enter the left side of BG number.",
                        "type_error": "Please enter a value with 5 symbols.",
                        "min": "This is not a valid left side of BG number.",
                        "max": "This is not a valid left side  of BG number."
                    },
                    "bg_number": {
                        "required": "Please enter the right side of BG number.",
                        "type_error": "Please enter a value for right side with 7 symbols.",
                        "min": "This is not a valid right side of BG number.",
                        "max": "This is not a valid right side  of BG number."
                    }
                },
                "sex": {
                    "required": "Veuillez entrer votre sexe."
                },
                "tel": {
                    "required": "Veuillez entrer votre n° de téléphone.",
                    "not_valid": "Veuillez entrer un n° de téléphone valide."
                },
                "terms_and_conditions": "Veuillez confirmer notre règlement pour la protection des données.",
                "ident_number.required": "Please enter your customer number."
            }
        },
        "appointment_info": {
            "at": "dans",
            "location": "Site",
            "header": "Résumé de votre RV",
            "title": "Votre RV a été enregistré.",
            "title_reserved": "Votre RV n’a pas été enregistré.",
            "title_on_site_reserved": "Votre RV a été réservé. Veuillez le confirmer sur place.",
            "title_desk_reserved": "Votre RV a été réservé. Une confirmation par un employé est obligatoire.",
            "infotext": "<h4>Notification push sur votre smartphone</h4><p> Scannez le code QR avec l’appli CleverQ sur votre smartphone pour recevoir des rappels de votre RV par notification PUSH.</p>",
            "confirmtext": "<h4>Note sur la réservation de votre RV</h4><p> Vous avez réservé un RV. Pour le confirmer définitivement, veuillez cliquer le lien dans notre mail de réservation.</p><p>${Attention} sans cette procédure, votre réservation sera annulée.</p>",
            "on_site_confirmtext": "<h4>Confirmation sur place</h4><p> Veuillez penser à amener votre code de réservation lors de votre visite. Vous devez le rentrer dans le terminal sur place pour confirmer votre RV.</p>",
            "desk_confirmtext": "<h4>Confirmation de RV par un employé</h4><p> Nous avons transféré votre demande de RV à un employé. Vous recevrez une confirmation par mail dès l’accord de l’employé.</p>",
            "reservation_code": "Code de réservation",
            "summary": "Résumé",
            "issue": "Préoccupation",
            "appointment": "RV",
            "persons": "Personnes.",
            "person": "une personne",
            "name": "Nom",
            "birthday": "Anniversaire",
            "email": "Adresse e-mail",
            "phone": "Numéro de téléphone",
            "message": "Notification",
            "back_button": "Retour au fournisseur",
            "print_button": "Imprimer",
            "advertising": "<h4>cleverQ - Application pour smartphone</h4><p>Chargez notre application gratuite CleverQ et profitez des avantages de votre smartphone. <br/> Réservation de RV, ticket, communication pouar PUSH, tout en chemin. <br/> Vous resterez ainsi toujours à la page.</p>",
            "advertising_image": "/images/smartphone.png",
            "duplicate_info_title": "Termin duplicat",
            "duplicate_info_title_remove": "Veuillez annuler votre ancien RV",
            "duplicate_info_text": "Vous avez déjà réservé un RV. Veuillez annuler l’ancien avant de prendre un nouveau RV. Vous pouvez annuler votre RV à l’aide de votre mail reçu.",
            "slot_already_booked_title": "Réservation impossible.",
            "slot_already_booked_text": "Votre choix de RV a été malheureusement déjà réservé par quelqu’un d’autre. Veuillez excuser ce désagrément et choisir un nouveau RV."
        },
        "navigation": {
            "cleverq": "Qui aime bien attendre?",
            "legal_notice": "Dépôt légal",
            "policy": "Protection des données"
        },
        "checkout_title": {
            "your": "Votre",
            "choice": "choix"
        },
        "enter_invite_code": {
            "verify": "Vérifier",
            "invite_code": "Code d'invitation"
        },
        "appointment_times": {
            "at": "at ",
            "on": "on ",
            "o_clock": "o'clock",
            "header": "Your reserved appointment time.",
            "remove": "Select new appointment time",
            "session": {
                "text": "Your session will run in: ",
                "close_error_modal": "Close",
                "reservation_expired": "Reservation expired, please select a new appointment time.",
                "expired": "Your session has expired."
            }
        }
    }
}