import { LOCALES } from '../locales'

export default {
  [LOCALES.PERSIAN]: {
    "issues": {
      "browser_header": "یک قرار آنلاین بگیرید.",
      "what_is_cleverq": "چیست؟ cleverQ",
      "additional_issues": "موضوعات بیشتری را انتخاب کنید"
    },
    "app": {
      "header": " چیست؟cleverQ ",
      "important": "اطلاعات فعلی"
    },
    "persons": {
      "header": "تعداد افرادی را که می خواهید برایشان قرار رزرو کنید وارد کنید.",
      "information": "<b style={{ color: 'red' }}>PLEASE NOTE:</b> شما فقط می توانید یک نوبت رزرو کنید.",
      "continue": "به قرار ملاقات ها",
      "remove": "لغو",
      "to_much_time": "تعداد درخواست"
    },
    "datepicker": {
      "header": "لطفا روز مورد نظر خود را انتخاب کنید.",
      "mon": "دوشنبه",
      "tue": "سهشنبه",
      "wed": "چهار شنبه",
      "thu": "پنج شنبه",
      "fri": "جمعه",
      "sat": "شنبه",
      "sun": "یکشنبه",
      "no_appointments": "متأسفانه، تمام تاریخ هایی که انتخاب کرده اید در حال حاضر به طور کامل رزرو شده اند. لطفاً بعداً دوباره امتحان کنید.",
      "error": "مشکلی در درخواست قرار ملاقات وجود داشت. لطفاً بعداً دوباره امتحان کنید."
    },
    "slotpicker": {
      "header": "زمانی را انتخاب کنید.",
      "no_appointments": "متاسفانه هیچ قرار ملاقاتی در این روز وجود ندارد.",
      "legend_green": "امکان تعیین وقت قبلی وجود دارد",
      "legend_yellow": "فقط چند قرار باقی مانده است",
      "legend_grey": "هیچ قراری نمی توان گذاشت",
      "timeout_reached": "اتصال به سرور قطع شد. لطفا دوباره تلاش کنید."
    },
    "dataforms": {
      "mandatory_fields": "فیلد اجباری",
      "header": "لطفا اطلاعات شخصی خود را وارد کنید.",
      "data_delete": "پس از قرار ملاقات، اطلاعات شما به طور خودکار حذف می شود.",
      "ms": "زنانه",
      "mr": "آقا",
      "salutation": "با درود",
      "company": "نام شرکت",
      "given_name": "نام کوچک",
      "first_name": "نام کوچک",
      "name": "نام",
      "surname": "نام خانوادگی",
      "birthday": "تولد | قالب: DD.MM.YYYY",
      "email": "آدرس ایمیل",
      "email_confirmation": "ایمیل را تکرار کنید",
      "email_optional": "آدرس ایمیل اضافی",
      "email_confirmation_optional": "یک آدرس ایمیل اختیاری را دوباره وارد کنید",
      "summary": "ادامه هید:",
      "phone": "شماره تلفن اضافی",
      "tel_information": " ",
      "appointment_for": " ",
      "appointment_around": " دور و بر ",
      "appointment_for_only": " برای ",
      "on": "در",
      "clock": "ساعت",
      "ident_number": "شماره مشتری",
      "ident_number_text": "لطفا شماره مشتری خود را وارد کنید تا بتوانید با کارت مشتری، قرار ملاقات خود را در محل تایید کنید.",
      "message": "پیام اختیاری در مورد قرار ملاقات شما.",
      "book_appointment": "اکنون یک رزرو الزام آور انجام دهید",
      "book_appointment_not_valid": "لطفا تمام فیلدهای الزامی را پر کنید",
      "book_appointment_started": "قرار در حال رزرو است لطفا صبر کنید...",
      "book_appointment_failed": "رزرو قرار انجام نشد، لطفاً دوباره کلیک کنید.",
      "terms_and_conditions": "سیاست حفظ حریم خصوصی",
      "terms_and_conditions_text_for_confirm": "با استفاده از خدمات ثبت نام آنلاین، شما با پردازش اطلاعات شخصی خود موافقت می کنید. همه داده",
      "terms_and_conditions_link": "پیوند به سیاست حفظ حریم خصوصی",
      "email_info": "برای اینکه بتوانید تاییدیه ثبت نام و سایر مدارک لازم را از طریق ایمیل ارسال کنید، باید آدرس ایمیل خود را وارد کنید. متاسفانه ثبت نام اینترنتی بدون این اطلاعات امکان پذیر نیست.",
      "mobile_phone_number": "شماره موبایل",
      "sms_notifications_label": "اعلان های پیامکی",
      "sms_notifications_checkbox_text": "مایلم از طریق پیامک از قرار ملاقاتم مطلع شوم.",
      "sms_notifications_text": "ما با شما تماس نخواهیم گرفت و از شماره موبایل شما برای هدف دیگری استفاده نمی شود.",
      "back": "بازگشت",
      "cancel": "تمام کردن",
      "close_privacy_policy": "سیاست حفظ حریم خصوصی را ببندید",
      "confirm_policy": "لطفاً سیاست حفظ حریم خصوصی را تأیید کنید",
      "policy": "من اطلاعات حفاظت از داده ها را خوانده ام و با پردازش داده هایم موافقت می کنم.",
      "show_policy": "نمایش سیاست حفظ حریم خصوصی"
    },
    "dataforms_waiting_list_entry": {
      "header": "برای لیست انتظار ثبت نام کنید",
      "waiting_list_entry_not_valid": "لطفا تمام فیلدهای الزامی را پر کنید",
      "waiting_list_entry": "اکنون بدون تعهد ثبت نام کنید",
      "waiting_list_entry_started": "در حال ثبت نام برای لیست انتظار، لطفا صبر کنید...",
      "go_to_waiting_list_entry": "ثبت نام را ادامه دهید",
      "successfull_registered": "ما ثبت نام شما را دریافت کردیم. برای انجام این کار، لطفاً روی پیوند موجود در ایمیلی که برای شما ارسال کردیم کلیک کنید.",
      "register_waiting_list_entry": "درخواست ثبت نام"
    },
    "forms": {
      "bday": {
        "day": "روز",
        "month": "ماه",
        "year": "سال"
      },
      "email_confirm": "تکرار",
      "honorific_prefix": {
        "mister": "آقا",
        "miss": "زنانه",
        "other": "دیگر"
      },
      "validations": {
        "default": {
          "required": "این اطلاعات ضروری است.",
          "min": "حداقل دو کاراکتر وارد کنید.",
          "max": "شخصیت های خیلی زیاد",
          "letter_only": "فقط حروف در این قسمت مجاز است.",
          "numbers_only": "فقط اعداد در این قسمت مجاز هستند."
        },
        "number": {
          "min": "لطفا یک عدد بزرگتر از 0 وارد کنید.",
          "max": "لطفا عددی کمتر از 1,000,000 وارد کنید."
        },
        "email": {
          "required": "لطفا آدرس ایمیل خود را وارد کنید.",
          "not_matching": "آدرس های ایمیل مطابقت ندارند.",
          "not_a_valid_email": "لطفا یک آدرس ایمیل معتبر وارد کنید."
        },
        "options": {
          "required": "لطفا یک گزینه را انتخاب کنید."
        },
        "name": {
          "required": "لطفا نام خود را وارد کنید."
        },
        "given_name": {
          "required": "لطفا نام خود را وارد کنید."
        },
        "family_name": {
          "required": "نام خانوادگی خود را وارد کنید."
        },
        "honorific_prefix": {
          "required": "جنسیت"
        },
        "street_address": {
          "required": "وارد خیابان خود شوید"
        },
        "postal_code": {
          "required": "کد پستی خود را وارد کنید."
        },
        "city": {
          "required": "محل زندگی خود را وارد کنید."
        },
        "country_name": {
          "required": "کشور مبدا خود را وارد کنید."
        },
        "bday": {
          "day": {
            "required": "لطفا تاریخ تولد خود را در تقویم وارد کنید.",
            "type_error": "مقداری بین 1 تا 31 وارد کنید.",
            "min": "این یک روز تقویمی نامعتبر است.",
            "max": "این یک روز تقویمی نامعتبر است."
          },
          "month": {
            "required": "لطفا ماه تولد خود را وارد کنید.",
            "type_error": "لطفاً مقداری بین 1 تا 12 وارد کنید.",
            "min": "این یک ماه تقویم معتبر نیست.",
            "max": "این یک ماه تقویم معتبر نیست."
          },
          "year": {
            "required": "لطفا سال تقویمی تاریخ تولد خود را وارد کنید.",
            "type_error": "مقداری بین 1880 و{year}$ وارد کنید.",
            "min": "مقداری بین 1880 و 2020 وارد کنید.",
            "max": "مقداری بین 1880 و 2020 وارد کنید."
          }
        },
        "sex": {
          "required": "لطفا جنسیت خود را وارد کنید"
        },
        "tel": {
          "required": "لطفا شماره تلفن خود را وارد کنید.",
          "not_valid": "لطفا یک شماره تلفن معتبر وارد نمائید."
        },
        "terms_and_conditions": "لطفاً سیاست حفظ حریم خصوصی ما را تأیید کنید.",
        "ident_number.required": "لطفا شماره مشتری خود را وارد کنید"
      }
    },
    "appointment_info": {
      "at": "که در",
      "location": "محل",
      "header": "خلاصه قرار ملاقات شما",
      "title": "قرار ملاقات شما ثابت شده است.",
      "title_reserved": "ملاقات آنها هنوز تایید نشده است.",
      "title_on_site_reserved": "قرار ملاقات شما ثابت شده است. تایید در محل مورد نیاز است.",
      "title_desk_reserved": "قرار ملاقات شما ثابت شده است. رضایت کارمند الزامی است.",
      "infotext": "<h4>اعلان فشاری در تلفن هوشمند خود </h4><p> کد QR را با برنامه cleverQ ما در تلفن هوشمند خود اسکن کنید و یادآورهای راحت برای رزرو قرار خود را از طریق اعلان فشار دریافت کنید.</p>",
      "confirmtext": "<h4>یادداشت در مورد تایید قرار ملاقات</h4> <p>شما یک قرار ملاقات با ما رزرو کرده اید. برای رزرو این امر به صورت الزام آور، به تأییدیه در ایمیلی که برای شما ارسال شده است نیاز داریم. لطفا روی لینک موجود در این ایمیل کلیک کنید. شما بدینوسیله یک قرار ملاقات الزام آور با ما رزرو می کنید بدون این فرآیند، رزرو منقضی می شود.</p>",
      "on_site_confirmtext": "<h4>تأیید در محل</h4> <p> لطفاً به یاد داشته باشید که این سند را به صورت دیجیتالی در تلفن هوشمند خود یا به صورت کاغذ چاپی همراه خود در قرار ملاقات خود داشته باشید. برای تایید قرار ملاقات خود در محل و سپس نگه داشتن آن به کد رزرو یا کد QR نیاز دارید.</p>",
      "desk_confirmtext": "<h4>تأیید قرار ملاقات توسط یک کارمند</h4><p> ما درخواست قرار ملاقات شما را به یک کارمند ارسال کرده ایم. به محض اینکه قرار ملاقات توسط کارمند تأیید شد، یک ایمیل تأیید دریافت خواهید کرد</p>",
      "reservation_code": "کد رزرو",
      "summary": "خلاصه",
      "issue": "خروجی",
      "appointment": "ملاقات",
      "persons": "افراد.",
      "person": "یک شخصperson.",
      "name": "نام خانوادگی",
      "birthday": "روز تولد",
      "email": "آدرس ایمیل",
      "phone": "شماره تلفن",
      "message": "ارتباط",
      "back_button": "بازگشت به ارائه دهنده",
      "print_button": "فشار",
      "advertising": "<h4> cleverQ - برنامه گوشی هوشمند </h4> <p>برنامه رایگان cleverQ ما را دانلود کنید و از مزایای گوشی هوشمند خود لذت ببرید. <br/> قرار ملاقات رزرو کنید، بلیط بخرید، اعلان </p>",
      "advertising_image": "/images/smartphone_english.png",
      "duplicate_info_title": "انتصاب تکراری",
      "duplicate_info_title_remove": "لطفا قرار قبلی خود را لغو کنید",
      "duplicate_info_text": "شما قبلا یک قرار ملاقات رزرو کرده اید. لطفا قبل از رزرو قرار جدید، قرار قبلی خود را لغو کنید. می توانید با بررسی ایمیلی که با قرار قبلی برای شما ارسال شده است، قرار قبلی را لغو کنید.",
      "slot_already_booked_title": "امکان رزرو نوبت وجود ندارد",
      "slot_already_booked_text": "متأسفانه، تاریخ درخواستی شما توسط شخص دیگری رزرو شده است. بابت مشکل پیش آمده پوزش می طلبیم و لطفاً یک قرار ملاقات دیگر را انتخاب کنید."
    },
    "navigation": {
      "cleverq": "چون چه کسی دوست دارد صبر کند؟",
      "legal_notice": "حک",
      "policy": "حفاظت از داده ها"
    },
    "greetings": {
      "confirm_greetings": "برای رزرو نوبت اقدام کنید"
    },
    "checkout_title": {
      "your": "او",
      "choice": "انتخاب"
    },
    "enter_invite_code": {
      "verify": "به منظور بررسی",
      "invite_code":"کد دعوت"
    },
    "appointment_times": {
      "at": "در ",
      "on": "دور و بر ",
      "o_clock": "ساعت",
      "header": "زمان قرار رزرو شده شما",
      "remove": "زمان قرار جدید را انتخاب کنید",
      "session": {
        "text": "جلسه شما در: ",
        "close_error_modal": "بستن",
        "reservation_expired": "زمان رزرو منقضی شده است، لطفا یک زمان قرار جدید را انتخاب کنید.",
        "expired": "جلسه شما منقضی شده است زیرا برای مدت طولانی غیرفعال بوده اید. لطفا صفحه را دوباره بارگذاری کنید."
      }
    }
 }
}