import React from 'react'
import { IntlProvider } from 'react-intl'
import messages from './messages'
import {flatten} from 'flat'
import IntlPolyfill from 'intl'
import 'intl/locale-data/jsonp/en'
import 'intl/locale-data/jsonp/de'
import 'intl/locale-data/jsonp/da'
import 'intl/locale-data/jsonp/lb-LU'
import 'intl/locale-data/jsonp/el'
import 'intl/locale-data/jsonp/ru-RU'
import 'intl/locale-data/jsonp/sv-SE'
import 'intl/locale-data/jsonp/uk-UA'
import 'intl/locale-data/jsonp/nb-NO'
import 'intl/locale-data/jsonp/fr'
import 'intl/locale-data/jsonp/fa'
import 'intl/locale-data/jsonp/ar-SA'
import 'intl/locale-data/jsonp/tr-TR'

if (global.Intl) {
  Intl.NumberFormat = IntlPolyfill.NumberFormat
  Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat
} else {
  global.Intl = IntlPolyfill
}

const Provider = ({ children, locale }) => (
  <IntlProvider locale={locale} textComponent={React.Fragment} messages={flatten(messages[locale])}>
    {children}
  </IntlProvider>
)

export default Provider
