import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import styles from '../../styles/dataforms.module.css'
import classNames from "classnames";
import { injectIntl } from "react-intl";

class EnterInviteCode extends Component {
  constructor() {
    super();
    this.state = {
      invite_code: ''
    }
    this.handleChange = this.handleChange.bind(this);
  }
  createMarkup(markup) {
    return { __html: markup };
  }
  handleClick() {
    if (this.state.invite_code.length) {
      window.location.replace(window.location.protocol + '//' + window.location.host + '/public/invite/' + this.state.invite_code)
    }
  }
  handleChange(e) {
    this.setState({ invite_code: e.target.value })
  }
  render() {
    const Button = styled.button`
      background: ${this.props.site_info.confirm_button_background};
      color: ${this.props.site_info.confirm_button_text};
      border: ${this.props.site_info.border_on_items ? '1px solid black' : 'none'};
      text-align: left;
      font-size: 1.2em;
      padding: 15px;
      font-weight: 600;
      &:hover {
        background-color: ${this.props.site_info.button_hover} !important;
        color: ${this.props.site_info.button_text_hover} !important;
        transition: 500ms;
      }
    `;
    const {intl} = this.props
    return (
      <Fragment>
        <Helmet>
          <style>{`
            .issues_header_left, .slot_picker_header_left, .data_forms_header_left{
              background-color: ${this.props.site_info.heading_background};
              color: ${this.props.site_info.heading_text};
              font-size: 1.1em;
              font-weight: 600;
              padding: 8px;
              border: 1px solid #CBCBCBFF;
              border-bottom: none;
              border-radius: 5px 5px 0px 0px;
            }
          `}</style>
        </Helmet>
        <div
          className={`container animated fadeIn ${
            this.props.site_info.drop_shadows ? 'drop-shadow' : null
          }`}>
          <div className="row">
            <div className="col-md-12 issues_header_left">
              {this.props.site_info.greetings_header}
            </div>
          </div>
          <div
            className="row inline-shadow"
            style={{
              backgroundColor: this.props.site_info.content_background,
              color: this.props.site_info.content_text,
              fontSize: '1.6rem'
            }}>
            <div style={{ marginTop: '10px' }} />
            <div className="col-md-12">
              <div dangerouslySetInnerHTML={this.createMarkup(this.props.site_info.greetings_content)} />
            </div>
            <div className="col-md-6" style={{marignTop: '30px'}}>
              <label className={styles['label-custom']}>
                {intl.formatMessage({id: 'enter_invite_code.invite_code'})}
              </label>
              <div
                className={classNames(
                  styles['input-group'],
                  styles['input-group-icon'],
                  this.props.site_info.drop_shadows ? styles.drop_shadow : null
                )}
              >
                <input
                  name={'invide-code'}
                  type="text"
                  onChange={this.handleChange}
                />
                <div className={styles['input-icon']} style={{marginTop: '-3px'}}>
                  <i className="fas fa-envelope"></i>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div onClick={this.handleClick.bind(this)} style={{marginTop: '43px'}}>
                <Button
                  type="submit"
                  className={`${
                    this.props.site_info.drop_shadows ? 'drop-shadow' : null
                  }`}
                  style={{height: '54px', lineHeight: '20px'}}>
                  {this.props.site_info.use_icons ? <span dangerouslySetInnerHTML={this.createMarkup(
                    this.props.site_info.icon_continue
                  )} style={{paddingRight: '10px'}}/> : null}{intl.formatMessage({id: 'enter_invite_code.verify'})}
                </Button>
              </div>
            </div>
          </div>
        </div>

      </Fragment>
    );
  }
}

// Map`s the state to props, to get access to specific data from the store.
function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    session_info: state.session_info
  };
}

// Export and connection to the store.
export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(EnterInviteCode)
);
