import {
  USER_UPDATE_PERSONS,
  USER_SET_PERSONS_NULL,
  USER_RESET_SUBTASK_PERSONS,
  USER_ONLY_ONE_SUBTASK,
  USER_OVERWRITE_PERSONS
} from '../actions/types';

export default function(state = [], action) {
  switch (action.type) {
    case USER_UPDATE_PERSONS:
      let index = state.findIndex(
        el => el.subtask_id === action.payload.subtask_id
      );
      if (index === -1 && action.payload.number > 0) {
        return [...state, action.payload];
      } else {
        let newState = state.filter(
          el => el.subtask_id !== action.payload.subtask_id
        );

        if (action.payload.number !== 0) {
          return [...newState, action.payload];
        } else {
          return [...newState];
        }
      }
    case USER_OVERWRITE_PERSONS:
      return action.payload
    case USER_SET_PERSONS_NULL:
      return state.filter(el => el.subtask_id !== action.payload.subtask_id);
    case USER_RESET_SUBTASK_PERSONS:
      return [];
    default:
      return state;
  }
}
