import { SET_NO_ISSUES } from '../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case SET_NO_ISSUES:
      // Take the existing state and concatenate to the new list of days
      return action.payload;

    default:
      return state;
  }
}
