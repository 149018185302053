import { LOCALES } from './../i18n/locales'
const localeWrapper = (locale) => {
  switch (locale) {
    case 'de':
      return LOCALES.GERMAN
    case 'en':
      return LOCALES.ENGLISH
    case 'da':
      return LOCALES.DUTCH
    case 'fr':
      return LOCALES.FRENCH
    case 'ru':
      return LOCALES.RUSSIAN
    case 'el':
      return LOCALES.GREEK
    case 'lb-LU':
      return LOCALES.LUXEMBURG
    case 'no':
      return LOCALES.NORWEGIAN
    case 'sv':
      return LOCALES.SWEDISH
    case 'uk':
      return LOCALES.UKRAINIAN
    case 'fa':
      return LOCALES.PERSIAN
    case 'ar':
      return LOCALES.ARABIC
    case 'tr':
      return LOCALES.TURKISH
    default:
      return LOCALES.GERMAN
  }
}

export default localeWrapper