import React from 'react'
import styled from 'styled-components'

const Default = ({ site_info, text, position }) => {
  const Button = styled.button`
    background: ${site_info.confirm_button_background};
    color: ${site_info.confirm_button_text};
    border: ${site_info.border_on_items ? '1px solid black' : '1px solid #CBCBCBFF'};
    text-align: left;
    font-size: 1.1em;
    padding: 13px;
    font-weight: 600;
    margin-right: -14px;
    float: ${position ? position : 'left'};
    &:hover {
      background-color: ${site_info.button_hover} !important;
      color: ${site_info.button_text_hover} !important;
      transition: 500ms;
    }
  `;
  return (
    <Button
      className={`${
        site_info.drop_shadows ? 'drop-shadow' : null
      }`}>
      {!position ? site_info.use_icons ? <span dangerouslySetInnerHTML={createMarkup(
        site_info.icon_continue
      )} style={{paddingRight: '10px'}}/> : null : null}<span>{text}</span>
    </Button>
  )
}

function createMarkup(markup) {
  return { __html: markup };
}

export {
  Default
}