import {
  HIDE_SERVICE_SELECTOR,
  HIDE_SERVICE_GROUP_SELECTOR,
  HIDE_SUBTASK_GROUP_SELECTOR,
  HIDE_SUBTASK_SELECTOR,
  SHOW_SUBTASK_SELECTOR,
  SERVICE_EDIT,
  SERVICE_GROUP_EDIT,
  SUBTASK_GROUP_EDIT,
  SUBTASK_EDIT,
  DAY_EDIT,
  SLOT_EDIT,
  DATA_EDIT,
  SHOW_POLICY,
  HIDE_POLICY,
  UPDATE_TIME_FRAME,
  VALIDATE_INVITE_URL_TOKEN,
  VALIDATE_INVITE_URL_TOKEN_ERROR,
  CONFIRM_GREETINGS,
  RESET_DAY_EDIT,
  FETCH_APPOINTMENT_DAYS_ERROR,
  HIDE_DAY_AND_SLOT_PICKER,
  HIDE_DATA_EDIT,
  STORE_BOOKING_SESSION_KEY,
  TOUCHSCREEN_MODE
} from '../actions/types';

let initialState = {
  service_selector_hide: false,
  service_group_selector_hide: false,
  subtask_group_selector_hide: false,
  subtask_selector_hide: false,
  service_edit: false,
  service_group_edit: false,
  subtask_group_edit: false,
  subtask_edit: false,
  day_edit: false,
  slot_edit: false,
  data_edit: false,
  show_policy: false,
  time_frame: 0,
  validated: {},
  confirm_greetings: false,
  fetching_error: false,
  booking_session_key: null,
  touchscreen_mode: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TOUCHSCREEN_MODE:
      // Take the existing state and concatenate to the new list of days
      return {
        ...state,
        touchscreen_mode: action.payload
      }
    case FETCH_APPOINTMENT_DAYS_ERROR:
      return {
        ...state,
        fetching_error: true
      }
    case HIDE_SERVICE_SELECTOR:
      return {
        ...state,
        service_selector_hide: true
      }
    case HIDE_SERVICE_GROUP_SELECTOR:
      return {
        ...state,
        service_group_selector_hide: true
      }
    case HIDE_SUBTASK_GROUP_SELECTOR:
      return {
        ...state,
        subtask_group_selector_hide: true
      }
    case HIDE_SUBTASK_SELECTOR:
      return {
        ...state,
        subtask_selector_hide: true
      }
    case SHOW_SUBTASK_SELECTOR:
      return {
        ...state,
        subtask_selector_hide: false
      }
    case SERVICE_EDIT:
      // Take the existing state and concatenate to the new list of days
      return {
        ...state,
        service_edit: true,
        service_group_edit: false,
        subtask_group_edit: false,
        subtask_edit: false,
        day_edit: false,
        slot_edit: false,
        data_edit: false,
        show_policy: false,
        time_frame: 0
      };
    case SERVICE_GROUP_EDIT:
      // Take the existing state and concatenate to the new list of days
      return {
        ...state,
        service_edit: false,
        service_group_edit: true,
        subtask_group_edit: false,
        subtask_edit: false,
        day_edit: false,
        slot_edit: false,
        data_edit: false,
        show_policy: false,
        time_frame: 0
      };
    case SUBTASK_GROUP_EDIT:
      // Take the existing state and concatenate to the new list of days
      return {
        ...state,
        service_edit: false,
        service_group_edit: false,
        subtask_group_edit: true,
        subtask_edit: false,
        day_edit: false,
        slot_edit: false,
        data_edit: false,
        show_policy: false,
        time_frame: 0
      };
    case SUBTASK_EDIT:
      // Take the existing state and concatenate to the new list of days
      return {
        ...state,
        service_edit: false,
        service_group_edit: false,
        subtask_group_edit: false,
        subtask_edit: true,
        day_edit: false,
        slot_edit: false,
        data_edit: false,
        show_policy: false,
        time_frame: 0
      };
    case DAY_EDIT:
      // Take the existing state and concatenate to the new list of days
      return {
        ...state,
        service_edit: false,
        service_group_edit: false,
        subtask_group_edit: false,
        subtask_edit: false,
        day_edit: true,
        slot_edit: false,
        data_edit: false,
        show_policy: false
      };
    case RESET_DAY_EDIT:
      // Take the existing state and concatenate to the new list of days
      return {
        ...state,
        service_edit: false,
        service_group_edit: false,
        subtask_group_edit: false,
        subtask_edit: false,
        day_edit: false,
        slot_edit: false,
        data_edit: false,
        show_policy: false
      };
    case SLOT_EDIT:
      // Take the existing state and concatenate to the new list of days
      return {
        ...state,
        service_edit: false,
        service_group_edit: false,
        subtask_group_edit: false,
        subtask_edit: false,
        day_edit: true,
        slot_edit: true,
        data_edit: false,
        show_policy: false
      };
    case DATA_EDIT:
      // Take the existing state and concatenate to the new list of days
      return {
        ...state,
        service_edit: false,
        service_group_edit: false,
        subtask_group_edit: false,
        subtask_edit: false,
        day_edit: false,
        slot_edit: false,
        data_edit: true,
        show_policy: false
      };
    case SHOW_POLICY:
      return {
        ...state,
        service_edit: false,
        service_group_edit: false,
        subtask_group_edit: false,
        subtask_edit: false,
        day_edit: false,
        slot_edit: false,
        data_edit: true,
        show_policy: true
      };
    case HIDE_POLICY:
      return {
        ...state,
        service_edit: false,
        service_group_edit: false,
        subtask_group_edit: false,
        subtask_edit: false,
        day_edit: false,
        slot_edit: false,
        data_edit: true,
        show_policy: false
      };
    case UPDATE_TIME_FRAME:
      // Take the existing state and concatenate to the new list of days
      return {
        ...state,
        time_frame: action.payload
      };
    case VALIDATE_INVITE_URL_TOKEN:
      return {
        ...state,
        validated: action.payload.data
      }
    case VALIDATE_INVITE_URL_TOKEN_ERROR:
      return {
        ...state,
        validated: action.payload.data
      }
    case CONFIRM_GREETINGS:
      return {
        ...state,
        confirm_greetings: true
      }
    case HIDE_DAY_AND_SLOT_PICKER:
      return {
        ...state,
        day_edit: false,
        slot_edit: false
      }
    case HIDE_DATA_EDIT:
      return {
        ...state,
        data_edit: false
      }
    case STORE_BOOKING_SESSION_KEY:
      return {
        ...state,
        booking_session_key: action.payload
      }
    default:
      return state;
  }
}
