import {
  USER_SELECTED_SLOT,
  USER_SELECTED_DAY,
  USER_RESET_SLOT,
  USER_SELECTED_WEEK_CHANGE
} from '../actions/types';

export default function(state = 'hidden', action) {
  switch (action.type) {
    case USER_SELECTED_SLOT:
      // Take the existing state and concatenate to the new list of days
      return action.payload;
    // case USER_SELECTED_DAY:
    //   return 'hidden';
    case USER_RESET_SLOT:
      return 'hidden';
    case USER_SELECTED_WEEK_CHANGE:
      return 'hidden';
    default:
      return state;
  }
}
