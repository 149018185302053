import "./ie11CustomProperties";
import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxPromise from 'redux-promise';
import reducers from './reducers';
import Helmet from 'react-helmet';
import { I18nProvider } from './i18n'
import localeWrapper from "./helpers/localeWrapper";
import validateLocale from "./helpers/validateLocale";

window.onload = function() {
  window.parent.postMessage({
    component: 'general',
    command: 'iframe-successfully-loaded',
    action: {
      timestamp: new Date(),
      loaded: true
    }
  }, '*');
};

var ua = window.navigator.userAgent;
var isIE = /MSIE|Trident/.test(ua);

if ( isIE ) {
  //IE specific code goes here
} else {
  require("./styles/animate.css");
}

import App from './containers/App';

// Store and Middleware
const middlewares = [];

if (process.env.NODE_ENV === `development`) {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({
    // ...options
    collapsed: true,
    timestamp: true
  });

  middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialState = {};
export const store = createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(reduxPromise, ...middlewares))
);

const query = new URLSearchParams(window.location.search);
const touchscreen_mode =
  query.get('touchscreen_mode') === 'true' ? true : false;
const samsung_mode =
  query.get('samsung_mode') === 'true' ? true : false;

let already_clicked = false

if (window.api) {
  window.api.onResponse((args) => {
    already_clicked = true
    let back_to_home_button = document.getElementById('back_to_home_button')
    back_to_home_button.style.left = args.back_to_home_appointments_position.x + 'px'
    back_to_home_button.style.top = args.back_to_home_appointments_position.y + 'px'
    back_to_home_button.style.height = args.back_to_home_size + 'px'
    back_to_home_button.style.width = args.back_to_home_size + 'px'
    back_to_home_button.style.visibility = 'visible'
  })
}

export default function WebAppointments(props) {
  // creates the web_appointments
  // check valid locale
  let language = query.get('lang') && query.get('lang').length > 1  ? query.get('lang') : props.default_locale

  if (language && language.length > 0) {
    if (!validateLocale(language, props.locales)) {
      language = props.default_locale
    }
  }

  if (samsung_mode) {
    document.body.style.fontSize = '200%';

    let backToTouchscreenTimer;

    // Back to content url timer, this timer will be started again when a user touches the screen.
    let backToTouchscreenResetTimer = () => {
      clearTimeout(backToTouchscreenTimer);

      backToTouchscreenTimer = setTimeout(() => {
        window.parent.postMessage({
          component: 'general',
          command: 'back-to-touchscreen'
        }, '*');
      }, 60000);
    };

    // Set the initial timer
    backToTouchscreenResetTimer()

    // Add event listener for touch events
    window.addEventListener('touchstart', backToTouchscreenResetTimer);
  }
  return (
      <I18nProvider locale={localeWrapper(language)}>
        <Provider store={store}>
          <Fragment>
            {touchscreen_mode === true ?
                <Helmet>
                  <style>{`
                html {
                  -webkit-touch-callout: none; /* iOS Safari */
                  -webkit-user-select: none; /* Safari */
                  -khtml-user-select: none; /* Konqueror HTML */
                  -moz-user-select: none; /* Old versions of Firefox */
                  -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none; /* Non-prefixed version, currently
                                                  supported by Chrome, Edge, Opera and Firefox */
                }
              `}</style>
                </Helmet> : null}
            <div id="outside">
              {window.api && window.api.request && !already_clicked ?
                  <div style={{position: 'fixed', top: 0, left: 0, width: '100px', height: '100px', background: 'white', border: '2px solid black', visibility: 'hidden'}} id="back_to_home_button" onClick={() => window.api.request('back_to_home')}>
                    <img
                        src="/images/back_to_home.png"
                        style={{ height: '100%', width: '100%'}}
                    />
                  </div> : null}
              <App
                  default_locale={props.default_locale}
                  locales={props.locales}
                  user_infos={props.user_infos}
                  current_session={props.current_session}
                  store={store}
                  recaptcha_site_key={props.recaptcha_site_key}
                  booking_session_key={props.booking_session_key}
                  on_site_confirmation={props.on_site_confirmation}
              />
            </div>
          </Fragment>
        </Provider>
      </I18nProvider>
  );
}
