import {
  USER_SELECTED_SUBTASK,
  USER_REMOVED_SUBTASK,
  USER_RESET_SUBTASKS,
  USER_ONLY_ONE_SUBTASK
} from '../actions/types';

export default function(state = [], action) {
  switch (action.type) {
    case USER_SELECTED_SUBTASK:
      // Take the existing state and concatenate to the new list of days
      const newState = state.filter(
        subtask => subtask.id !== action.payload.id
      );
      return [action.payload, ...newState];

    case USER_REMOVED_SUBTASK:
      // Take the existing state and concatenate to the new list of days
      const removedState = state.filter(
        subtask => subtask.id !== action.payload.id
      );
      return removedState;
    case USER_ONLY_ONE_SUBTASK:
      // Take the existing state and concatenate to the new list of days
      return [action.payload];

    case USER_RESET_SUBTASKS:
      return [];
    default:
      return state;
  }
}
