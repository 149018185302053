import { FETCH_SITE_INFO, FETCH_SITE_URL_NAME } from '../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_SITE_INFO:
      // Take the existing state and concatenate to the new list of days
      return action.payload.data;
    case FETCH_SITE_URL_NAME:
      // Take the existing state and concatenate to the new list of days
      return action.payload.data;
    default:
      return state;
  }
}
