import {
  USER_SELECTED_SERVICE_DATA,
  USER_RESET_SERVICE,
  VALIDATE_INVITE_URL_TOKEN
} from '../actions/types';

export default function(state = 'hidden', action) {
  switch (action.type) {
    case USER_SELECTED_SERVICE_DATA:
      // Take the existing state and concatenate to the new list of days
      return action.payload;
    case USER_RESET_SERVICE:
      // Take the existing state and concatenate to the new list of days
      return 'hidden';
    case VALIDATE_INVITE_URL_TOKEN:
      if (action.payload.data.servie_data) {
        return action.payload.data.service_data
      } else {
        return state
      }
    default:
      return state;
  }
}
