import {FETCH_SITE_INFO, VALIDATE_INVITE_URL_TOKEN} from '../actions/types';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_SITE_INFO:
      // Take the existing state and concatenate to the new list of days
      return action.payload.data.services;
    case VALIDATE_INVITE_URL_TOKEN:
      if (action.payload.data.service_data) {
        return [...state, action.payload.data.service_data]
      } else {
        return state
      }
    default:
      return state;
  }
}
