import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Tabs, Tab, TabContainer } from 'react-bootstrap';
import { ScaleLoader } from 'react-spinners';
import moment from 'moment';
import { StyleSheet, css } from 'aphrodite';
import Helmet from 'react-helmet';
import DataFormsWaitingListEntry from "./DataFormsWaitingListEntry";
import { scrollToTarget } from './elements/ScrollToTarget'
import {FormattedMessage, injectIntl } from "react-intl";

const query = new URLSearchParams(window.location.search);
const queryEmbeddedMode = query.get('embedded_mode')
const embedded_mode = queryEmbeddedMode === "true" ? true : false
const samsung_mode =
  query.get('samsung_mode') === 'true' ? true : false;

class DatePicker extends Component {
  state = {
    showNoDatesAvailable: false,
    disabled: false
  };
  handleClick(selected_day, e) {
    e.preventDefault();
    if (!this.state.disabled && selected_day !== this.props.selected_day) {
      this.setState({ disabled: true })
      this.props.fetchAppointmentSlots(
        this.props.site_info.id,
        this.props.selected_service,
        selected_day,
        this.props.no_issues === false
          ? this.props.user_person_count
          : 'no_issues'
      ).then(() => {
        this.setState({ disabled: false })
        this.props.selectedDay(selected_day);
        this.props.selectedSlot('hidden');
        this.props.selectedSlotTimeOfSlot('hidden');
        this.props.setSlotEdit();

        scrollToTarget("scroll_target_slot_picker")
      });
    }
  }
  handleSelect() {
    this.props.weekChange();
  }
  componentDidMount() {
    setTimeout(() => {
      if (!this.props.days.length) {
        this.setState({ showNoDatesAvailable: true });
      }
    }, 3000);
  }
  render() {
    const styles = StyleSheet.create({
      day_header: {
        fontSize: '1.1em',
        textAlign: 'center',
        fontWeight: '600',
        marginLeft: '-5px'
      }
    });
    const {intl} = this.props
    return (
      <Fragment>
        <Helmet>
          <style>{`
            .nav {
              border: 1px solid #CBCBCBFF;
              border-radius: 5px 5px 0px 0px;
            }
            .nav-tabs {
              border-bottom: 0px;
              background-color: ${this.props.site_info.heading_background};
              color: ${this.props.site_info.heading_text};
              font-size: 1.1em;
              font-weight: 600;
              padding: 5px;
            }
            
            .nav-tabs > li {
              float: left;
              background-color: ${
                this.props.site_info.week_background
              } !important;
              margin-right: 3px;
              border: 1px solid #CBCBCBFF;
              margin-bottom: 5px;
            }
            
            .nav-tabs > li > a{
              padding: 10px;
              background-color: ${this.props.site_info.week_background};
              color: ${this.props.site_info.week_text} !important;
              margin-right: 0px;
              border-radius: 0px;
              border: 1px solid #CBCBCBFF;
            }
            
            .nav-tabs > li > a:hover{
              background-color: ${this.props.site_info.week_hover};
              color: ${this.props.site_info.week_text_hover} !important;
            }
            
            .nav-tabs > li.active > a{
              background-color: ${this.props.site_info.week_active};
              color: ${this.props.site_info.week_text_active} !important;
            }
            
            .nav-tabs > li.active > a:focus{
              background-color: ${this.props.site_info.week_active};
              color: ${this.props.site_info.week_text_active} !important;
            }
            
            .nav-tabs > li.active > a:hover{
              background-color: ${this.props.site_info.week_hover};
              color: ${this.props.site_info.week_text_hover} !important;
            }
            
            .tab-content {
              background-color: ${this.props.site_info.content_background};
              color: ${this.props.site_info.content_text};
              padding: 5px;
              border: 1px solid #CBCBCBFF;
              border-top: none;
            }
            
            .day_box {
              color: ${this.props.site_info.day_text};
              text-align: center;
              padding-top: ${samsung_mode ? '0' : '4px'};
              height: 50px;
              width: 66px;
              margin-right: 5px;
              font-size: 1.8em;
              border: 1px solid #8A8B8B;
              border-radius: 0;
              cursor: pointer;
            }
            
            .day_box:hover {
              -webkit-filter: brightness(90%);
              -webkit-transition: all 0.5s ease;
              -moz-transition: all 0.5s ease;
              -o-transition: all 0.5s ease;
              -ms-transition: all 0.5s ease;
              transition: all 0.5s ease;
            }
            
            @media only screen and (max-width: 600px) {
              .day_box {
                height: 45px;
                width: 45px;
                font-size: 1.8em;
                padding: 2px;
              }
            }
          `}</style>
        </Helmet>
        {!this.state.showNoDatesAvailable ?
          <div
            className={`container animated ${embedded_mode ? 'fadeIn' : 'fadeInLeftBig'} ${
              this.props.site_info.drop_shadows ? 'drop-shadow' : null
            }`}>
            <div className="row" style={{backgroundColor: this.props.site_info
                .content_background}}>
              <TabContainer id={1}>
                {this.props.days && this.props.days.length ? (
                  <Tabs
                    defaultActiveKey={1}
                    id="uncontrolled-tab-example"
                    onSelect={this.handleSelect.bind(this)}>
                    {this.props.days.length > 0 ? (
                      this.props.days.map((tab, i) => (
                        <Tab
                          key={i}
                          eventKey={i + 1}
                          title={tab.range}
                          style={{ height: '130px' }}>
                          <h4
                            className="pull-right no_tab"
                            style={{ marginRight: '20px' }}>
                            {intl.formatMessage({id: "datepicker.header"})}
                          </h4>
                          <div className="left-spacer">
                            <div className="twelve columns">
                              {typeof tab.days === 'object' ? (
                                <table>
                                  <thead>
                                  <tr>
                                    {tab.days.map((day, k) => (
                                      <th key={day.day}>
                                        <div
                                          translate="no"
                                          className={css(styles.day_header)}>
                                          <span>{getWeekdayName(k, intl)}</span>
                                        </div>
                                      </th>
                                    ))}
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    {tab.days.map((day, k) => (
                                      <td key={k}>
                                        <div
                                          onClick={e => day.present ? this.handleClick(day.day, e) : null}
                                          className={`day_box inline-shadow ${day.present ? 'tab_in' : 'no_tab'} ${
                                            this.props.site_info.drop_shadows
                                              ? 'drop-shadow'
                                              : null
                                          }`}
                                          style={{
                                            border: this.props.site_info.border_on_items ? '1px solid black' : null,
                                            textDecoration:
                                              this.props.selected_day === day.day
                                                ? 'underline'
                                                : 'none',
                                            color:
                                              this.props.selected_day === day.day
                                                ? this.props.site_info.day_text_active
                                                : day.present
                                                  ? this.props.site_info.day_text
                                                  : '#969696',
                                            backgroundColor: day.present
                                              ? this.props.selected_day === day.day
                                                ? this.props.site_info.day_active
                                                : this.props.site_info.day_background
                                              : '#DDDDDD'
                                          }}>
                                          <span className="no_tab">{moment(day.day).format('DD')}</span>
                                        </div>
                                      </td>
                                    ))}
                                  </tr>
                                  </tbody>
                                </table>
                              ) : null}
                            </div>
                          </div>
                          <h2
                            className="pull-right no_tab"
                            style={{ marginRight: '20px', marginTop: '-10px' }}>
                            {this.props.selected_day !== 'hidden'
                              ? moment(this.props.selected_day).format(
                                'MMMM YYYY'
                              )
                              : moment(tab.days[0].day).format('MMMM YYYY')}
                          </h2>
                        </Tab>
                      ))
                    ) : (
                      <h4 style={{ textAlign: 'center' }}>
                        {this.props.site_info.custom_no_free_slots_left_text && this.props.site_info.custom_no_free_slots_left_text.length > 0 ? this.props.site_info.custom_no_free_slots_left_text : intl.formatMessage({id: "datepicker.no_appointments"})}
                      </h4>
                    )}
                  </Tabs>
                ) : (
                  !this.props.ui.fetching_error ?
                    <div>
                      {this.state.showNoDatesAvailable ? (
                        <div className="container animated fadeIn">
                          <div
                            className="row inline-shadow"
                            style={{
                              backgroundColor: this.props.site_info
                                .content_background,
                              color: this.props.site_info.content_text
                            }}>
                            <h4 style={{ textAlign: 'center' }}>
                              {this.props.selected_service_data.waiting_list_active ? <DataFormsWaitingListEntry /> : this.props.site_info.custom_no_free_slots_left_text && this.props.site_info.custom_no_free_slots_left_text.length > 0 ? this.props.site_info.custom_no_free_slots_left_text : intl.formatMessage({id: "datepicker.no_appointments"})}
                            </h4>
                          </div>
                        </div>
                      ) : (
                        <ScaleLoader
                          sizeUnit={'px'}
                          size={300}
                          color={this.props.site_info.loading_indicator}
                          loading={true}
                        />
                      )}
                    </div> : <h4 style={{ textAlign: 'center', color: 'red' }}>
                        {intl.formatMessage({id: "datepicker.error"})}
                    </h4>
                )}
              </TabContainer>
            </div>
          </div> :
          <div>
            {this.props.selected_service_data.waiting_list_active ? <DataFormsWaitingListEntry default_locale={this.props.default_locale} recaptcha_site_key={this.props.recaptcha_site_key} /> :
              <div className={`container ${
                this.props.site_info.drop_shadows ? 'drop-shadow' : null
              }`}>
                <div className="row">
                  <div className="col-md-12 issues_header_left">
                    {this.props.site_info.custom_no_free_slots_left_text && this.props.site_info.custom_no_free_slots_left_text.length > 0 ? this.props.site_info.custom_no_free_slots_left_text : intl.formatMessage({id: "datepicker.no_appointments"})}
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    site_info: state.site_info,
    days: state.days,
    selected_service: state.user_selected_service,
    selected_service_data: state.user_selected_service_data,
    selected_day: state.user_selected_day,
    selected_subtask: state.user_selected_subtask,
    user_person_count: state.user_count_persons,
    no_issues: state.no_issues,
    ui: state.ui
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(DatePicker)
);

function getWeekdayName(index, intl) {
  switch (index) {
    case 0:
      return intl.formatMessage({id: "datepicker.mon"})
    case 1:
      return intl.formatMessage({id: "datepicker.tue"})
    case 2:
      return intl.formatMessage({id: "datepicker.wed"})
    case 3:
      return intl.formatMessage({id: "datepicker.thu"})
    case 4:
      return intl.formatMessage({id: "datepicker.fri"})
    case 5:
      return intl.formatMessage({id: "datepicker.sat"})
    case 6:
      return intl.formatMessage({id: "datepicker.sun"})
  }
}
