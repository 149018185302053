import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  resetUser,
  resetSubtasks,
  resetSubtaskPersons,
  resetDay,
  resetSlot,
  resetPersons,
  resetTimeOfSlot,
  setServiceEdit,
  setSubtaskEdit,
  resetAppointment
} from '../../actions';
import { Modal } from 'react-bootstrap';
import { injectIntl } from "react-intl";

class ErrorBooking extends Component {
  state = {
    show: true
  };
  handleClose() {
    this.setState({ show: true });
  }
  handleShow() {
    this.setState({ show: true });
  }
  backToCustomer(url) {
    //window.location.replace(window.location.href);
    this.props.dispatch(resetUser());
    this.props.dispatch(resetSubtasks());
    this.props.dispatch(resetSubtaskPersons());
    this.props.dispatch(resetDay());
    this.props.dispatch(resetSlot());
    this.props.dispatch(resetPersons());
    this.props.dispatch(resetTimeOfSlot());
    this.props.dispatch(resetAppointment());

    if (this.props.site_info.show_services) {
      this.props.dispatch(setServiceEdit());
    } else {
      this.props.afterPersonsSelected(false);
      this.props.dispatch(setSubtaskEdit());
    }
  }
  createMarkup(markup) {
    return { __html: markup };
  }
  render() {
    const {intl} = this.props
    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose.bind(this)}
          bsSize="large"
          aria-labelledby="contained-modal-title-lg">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-lg">
              {intl.formatMessage({id: 'appointment_info.slot_already_booked_title'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-8">
                <p style={{ color: 'red', fontSize: '1.6rem' }}>
                  {intl.formatMessage({id: 'appointment_info.slot_already_booked_text'})}
                </p>
              </div>
            </div>
            <hr />

            {this.props.site_info.show_app_links ? (
                <div>
                  <div dangerouslySetInnerHTML={this.createMarkup(intl.formatMessage({id: 'appointment_info.advertising'}))}/>
                  <img
                    src={intl.formatMessage({id: 'appointment_info.advertising_image'})}
                    alt="smartphone"
                  />
                </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            {this.props.site_info.show_app_link ? (
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=de.com.bic.gowait"
                  className="href">
                  <img
                    src="/images/cleverq-appstore.png"
                    alt="AppleStore Store"
                    style={{
                      width: '100px',
                      marginRight: '10px',
                      marginTop: '-5px'
                    }}
                  />
                </a>
                <a
                  href="https://itunes.apple.com/de/app/cleverq/id1111920758?mt=8"
                  className="href">
                  <img
                    src="/images/cleverq-fuer-android.png"
                    alt="Android Store"
                    style={{
                      width: '100px',
                      marginRight: '10px',
                      marginTop: '-5px'
                    }}
                  />
                </a>
              </div>
            ) : null}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    booking: state.booking,
    site_info: state.site_info,
    selected_service: state.user_selected_service,
    selected_service_name: state.user_selected_service_name,
    selected_day: state.user_selected_day,
    selected_slot: state.user_selected_slot,
    user_person_count: state.user_count_persons
  };
}

export default injectIntl(connect(mapStateToProps)(ErrorBooking));